import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { generateShortString } from '../../utils/string-utils';
import ClientService from '../../services/clients/clientService';
import moment from 'moment';

class CurrentLoggedInProfileCard extends Component {
    constructor() {
        super();
        this.syncClient = this.syncClient.bind(this);
        this.state = {
            fullName: '',
            city: '',
            country: null,
            isLoadingData: true
        };
    }

    componentDidMount() {
        this.syncClient();
    }

    syncClient() {
        this.setState({ isLoadingData: true });
        ClientService.GetCurrentLoggedInClient().then(res => {
            var client = res.data.result;
            this.setState({
                fullName: client.fullName,
                city: client.city,
                country: client.country,
                isActive: client.isActive
            });
            this.setState({ isLoadingData: false });
        });
    }

    render() {
        if (this.state.isLoadingData) {
            return (
                <div className="flex-table is-compact has-loader has-loader-active min-height" style={{ height: '20vh' }}>
                    <div className="h-loader-wrapper">
                        <div className="loader is-large is-loading"></div>
                    </div>
                </div>
            );
        }

        return (
            <div className="profile-header has-text-centered">
                <div className="h-avatar is-xl">
                    <span className={"avatar is-fake is-primary is-squared h-avatar has-dot " + (this.state.isActive ? '' : 'dot-danger')}>
                        <span>{generateShortString(this.state.fullName)}</span>
                    </span>
                </div>
                <h3 className="title is-4 is-narrow is-thin">{this.state.fullName}</h3>
                <p className="light-text">Halo, saya berasal dari {this.state.city || '-'} {this.state.country || '-'} dan telah bergabung sejak {moment(this.state.creationTime).local().format("DD MMM YYYY") || '-'}.</p>
            </div>
        );
    }
}

export default withRouter(CurrentLoggedInProfileCard);