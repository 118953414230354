import React, { Component } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import SubscriptionService from '../../../services/subscription/subscriptionService';
import DialogEditSubscription from './dialogEditSubscription';
import './detailSubscription.scss';
import AppConsts from '../../../lib/appconst';

class DetailSubscription extends Component {
    constructor() {
        super();
        this.state = {
            id: '',
            subscription: null,
            isLoading: true
        };

        this.syncSubscription = this.syncSubscription.bind(this);
        this.handleNavigateBack = this.handleNavigateBack.bind(this);
        this.handleRedirectToOrder = this.handleRedirectToOrder.bind(this);
        this.handleDownloadFile = this.handleDownloadFile.bind(this);
        this.handleShowDialogEditSubscription = this.handleShowDialogEditSubscription.bind(this);
        this.closeDialogEditSubscription = this.closeDialogEditSubscription.bind(this);
    }

    componentDidMount() {
        var id = this.props.match.params.id;
        this.setState({ id: id }, () => {
            this.syncSubscription();
        });
    }

    syncSubscription() {
        SubscriptionService.Get(this.state.id).then(res => {
            this.setState({ subscription: res.data.result });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    handleNavigateBack() {
        this.props.history.goBack();
    }

    handleRedirectToOrder() {
        this.props.history.push('/orders/' + this.state.subscription.order.id);
    }

    handleDownloadFile(ev) {
        ev.preventDefault();
        setTimeout(() => {
            const response = {
                file: "/products/downloadfile/" + this.state.subscription.order.product.id,
            };
            window.open(response.file);
        }, 100);
    }

    handleShowDialogEditSubscription() {
        this.setState({ isShowEditSubscription: true });
    }

    closeDialogEditSubscription() {
        this.setState({ isShowEditSubscription: false });
    }

    render() {
        const { subscription, isLoading } = this.state;
        return (
            !isLoading ?
                <div className="invoice-wrapper is-user-subscription-detail">
                    <div className="invoice-header">
                        <div className="left">
                            <h3>{subscription.productType.name} {subscription.platformName} - {subscription.account}</h3>
                        </div>
                    </div>
                    <div className="invoice-body">
                        <div className="invoice-card">
                            <div className="invoice-section is-flex is-bordered">
                                <div className="h-avatar is-customer is-large">
                                    <img className="avatar" src={subscription.order.product.firstProductImage.url} alt="" />
                                </div>
                                <div className="meta">
                                    <h3>{subscription.order.product.name}</h3>
                                    <span>{subscription.order.productSubscriptionPlan.typeName}</span>
                                </div>
                                <div className="end is-left">
                                    <h3>Account: {subscription.account || "Belum ada akun"}</h3>
                                    <p>Platform: {subscription.platformName} {subscription.isMustAccountHotForex ? "Hotforex" : (subscription.isMustAccountHotForexPremium) ? "Hotforex Premium" : (subscription.isMustAccountHotForexCent) ? "Hotforex Cent" : ""}</p>
                                    <p>Berlaku sejak: {moment(subscription.startDate).local().format('DD MMM yyyy')}</p>
                                    <p>Berlaku sampai: {subscription.isLifetime ? 'Lifetime' : moment(subscription.endDate).local().format('DD MMM yyyy')}</p>
                                    <p>Status: {subscription.statusName}</p>
                                </div>
                            </div>
                            <div className="invoice-section">
                                {
                                    !subscription.account || (subscription.platform !== 20) ?
                                        <div className="mb-5 dark-inverted">
                                            <div className="dark-inverted">{!subscription.account ? "Aktivasi" : "Perbaharui"} Akun {subscription.platformName} {subscription.isMustAccountHotForex ? "Hotforex" : (subscription.isMustAccountHotForexPremium) ? "Hotforex Premium" : (subscription.isMustAccountHotForexCent) ? "Hotforex Cent" : ""}</div>
                                            {
                                                subscription.isMustAccountHotForex ?
                                                    <>
                                                        <br />
                                                        <div className="dark-inverted">Tool ini hanya bisa beroperasi di broker HotForex.</div>
                                                        <div className="dark-inverted">Kode IB   : <b>{subscription.order.paidAmount == 0 ? AppConsts.HFM.IB : AppConsts.HFM.IB_2}</b></div>
                                                        <div className="dark-inverted">Belum punya akun Hotforex? <a href={subscription.order.paidAmount == 0 ? AppConsts.HFM.URL : AppConsts.HFM.URL_2}>klik sini untuk Daftar</a></div>
                                                        <br />
                                                        <div className="dark-inverted">Masukan IB Ruang Trader seperti dibawah ini:</div>
                                                        <img src="/images/hotforex-ib.png" />
                                                        <br />
                                                        <div className="dark-inverted">Setelah Anda melakukan Aktivasi atau Pembaharuan Akun dibawah ini, dalam waktu 15 menit akan kami lakukan Aktivasi dan akan kami informasikan melalui Email Anda.</div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                            {
                                                subscription.isMustAccountHotForexPremium ?
                                                    <>
                                                        <br />
                                                        <div className="dark-inverted">Tool ini hanya bisa beroperasi di broker HotForex dan under IB Ruang Trader dengan tipe akun Premium.</div>
                                                        <div className="dark-inverted">Kode IB   : <b>{subscription.order.paidAmount == 0 ? AppConsts.HFM.IB : AppConsts.HFM.IB_2}</b></div>
                                                        <div className="dark-inverted">Tipe Akun : <b>Premium</b></div>
                                                        <div className="dark-inverted">Belum punya akun Hotforex? <a href={subscription.order.paidAmount == 0 ? AppConsts.HFM.URL : AppConsts.HFM.URL_2}>klik sini untuk Daftar</a></div>
                                                        <br />
                                                        <div className="dark-inverted">Masukan IB Ruang Trader seperti dibawah ini:</div>
                                                        <img src="/images/hotforex-premium-ib.png" />
                                                        <br />
                                                        <div className="dark-inverted">Setelah Anda melakukan Aktivasi atau Pembaharuan Akun dibawah ini, dalam waktu 15 menit akan kami lakukan Aktivasi dan akan kami informasikan melalui Email Anda.</div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                            {
                                                subscription.isMustAccountHotForexCent ?
                                                    <>
                                                        <br />
                                                        <div className="dark-inverted">Tool ini hanya bisa beroperasi di broker HotForex dan under IB Ruang Trader dengan tipe akun Cent.</div>
                                                        <div className="dark-inverted">Kode IB   : <b>{subscription.order.paidAmount == 0 ? AppConsts.HFM.IB : AppConsts.HFM.IB_2}</b></div>
                                                        <div className="dark-inverted">Tipe Akun : <b>Cent</b></div>
                                                        <div className="dark-inverted">Belum punya akun Hotforex? <a href={subscription.order.paidAmount == 0 ? AppConsts.HFM.URL : AppConsts.HFM.URL_2}>klik sini untuk Daftar</a></div>
                                                        <br />
                                                        <div className="dark-inverted">Masukan IB Ruang Trader seperti dibawah ini:</div>
                                                        <img src="/images/hotforex-ib.png" />
                                                        <br />
                                                        <div className="dark-inverted">Setelah Anda melakukan Aktivasi atau Pembaharuan Akun dibawah ini, dalam waktu 15 menit akan kami lakukan Aktivasi dan akan kami informasikan melalui Email Anda.</div>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                            {
                                                subscription.platformName == 20 ?
                                                    <div className="mt-3 dark-inverted">Pastikan informasi akun Anda benar, ini hanya bisa diinput sekali saja, jika ingin Update maka perlu hubungi team support Ruang Trader.</div>
                                                    :
                                                    <></>
                                            }
                                            <div className="mt-3">
                                                <button className="button h-button is-primary is-xl is-raised is-elevated" onClick={this.handleShowDialogEditSubscription}>
                                                    {!subscription.account ? "Aktivasi" : "Perbaharui"} Akun {subscription.platformName} {subscription.isMustAccountHotForex ? "Hotforex" : (subscription.isMustAccountHotForexPremium) ? "Hotforex Premium" : (subscription.isMustAccountHotForexCent) ? "Hotforex Cent" : ""}
                                                </button>
                                            </div>
                                            <div className="mt-5 dark-inverted">Butuh bantuan? Hubungi <a href="http://www.nfc.co.id/ec">team support Ruang Trader (klik sini)</a></div>
                                        </div>
                                        :
                                        subscription.platform == 20 ?
                                            <div className="mb-5 dark-inverted">
                                                Jika ingin mengubah License Akun {subscription.platformName} {subscription.isMustAccountHotForex ? "Hotforex" : (subscription.isMustAccountHotForexPremium) ? "Hotforex Premium" : ""} Anda, silahkan hubungi <a href="http://www.nfc.co.id/ec" target="_blank">Team Support (klik sini)</a>
                                            </div>
                                            :
                                            <></>
                                }

                                {
                                    subscription.order.product.fileName ?
                                        <div className="mb-5">
                                            <div className="dark-inverted">Download file {subscription.productType.name} dibawah ini:</div>
                                            <div className="mt-1 dark-inverted">
                                                <a href={subscription.order.product.urlDownloadFile} download className="button h-button is-medium is-success is-xl is-raised" >Download {subscription.productType.name}</a>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }

                                {
                                    subscription.order.product.urlGuideBook && subscription.order.product.urlGuideBook != "null" ?
                                        <div className="mt-5">
                                            <div className="dark-inverted">Dapatkan panduan {subscription.productType.name}:</div>
                                            <div className="mt-1">
                                                <a className="button h-button is-info is-outlined" href={subscription.order.product.urlGuideBook} target="_blank">
                                                    Klik Disini untuk mendapatkan panduan
                                                </a>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }

                                {
                                    subscription.order.product.urlVideoInstruction && subscription.order.product.urlVideoInstruction != "null" ?
                                        <div className="mt-5">
                                            <div className="dark-inverted">Tonton video dibawah ini untuk mengetahui cara instalasi dan penggunaan tool {subscription.productType.name}:</div>
                                            <div className="mt-3">
                                                <iframe width="100%" height="315"
                                                    src={subscription.order.product.urlVideoInstruction}>
                                                </iframe>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }

                                {
                                    this.state.isShowEditSubscription ?
                                        (
                                            <DialogEditSubscription handleClose={this.closeDialogEditSubscription} handleRefreshData={this.syncSubscription} subscription={this.state.subscription} />
                                        )
                                        :
                                        (<></>)
                                }
                            </div>
                        </div>
                    </div>

                    <div className="navigation-buttons mt-2">
                        <div className="buttons is-pulled-left">
                            <button className="button h-button is-xl" onClick={this.handleNavigateBack}>Kembali</button>
                        </div>
                        <div className="buttons is-right">
                            <button className="button h-button is-xl" onClick={this.handleRedirectToOrder}>Lihat Order</button>
                        </div>
                    </div>
                </div>
                :
                <div className="h-loader-wrapper">
                    <div className="loader is-large is-loading"></div>
                </div>
        );
    }
}

export default withRouter(DetailSubscription);