import $ from 'jquery';

class ImageFunction {
    Initialize() {
        $('*[data-src]').each(function () {
            var newSrc = $(this).attr('data-demo-src');
            $(this).attr('src', newSrc);
        });
        $('*[data-background]').each(function () {
            var newBg = $(this).attr('data-demo-background');
            $(this).attr('data-background', newBg);
        });
        $('*[data-href]').each(function () {
            var newHref = $(this).attr('data-demo-href');
            $(this).attr('href', newHref);
        });
    }
}

export default new ImageFunction();