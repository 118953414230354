import AppConst from "../lib/appconst";
import { getStorage } from '../utils/storage-utils';

const ROLE_ADMIN = "ADMIN";
const ROLE_AFFILIATE = "AFFILIATE";

class AuthUtil {
    GetCurrentUser() {
        var userJson = getStorage("user");
        var user = JSON.parse(userJson);
        return userJson ? user : null;
    }

    CurrentUserIsAdmin() {
        var user = this.GetCurrentUser();
        return user ? user.roles.toUpperCase() === ROLE_ADMIN : false
    }

    IsCurrentUserCanAccessClientManagement() {
        var user = this.GetCurrentUser();
        if (user) {
            return user.roles.toUpperCase() === ROLE_ADMIN || user.roles.toUpperCase() === ROLE_AFFILIATE;
        }
        return false;
    }

    IsAuthenticated() {
        var user = this.GetCurrentUser();
        if (user)
            return true;

        return false;
    }

    IsCurrentUserEditionCanAccessModule(moduleName) {
        var user = this.GetCurrentUser();
        return user ? user.roles.toUpperCase() === ROLE_ADMIN : false
        return false;
    }
};

export default new AuthUtil();