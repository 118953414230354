import http from "../httpService";

class SubscriptionService {
    Edit(subscriptionId, account, isActive) {
        let result = http.put('/subscriptions', { id: subscriptionId, account: account, isActive: isActive });
        return result;
    }

    UpdateAccount(subscriptionId, account) {
        let result = http.put('/subscriptions/updateaccount', { id: subscriptionId, account: account });
        return result;
    }

    GetSubscriptionCurrentUser(status, searchKey, skipCount, maxResultCount) {
        let result = http.get('/subscriptions/currentuser?status=' + status + '&searchKey=' + searchKey + '&skipCount=' + skipCount + '&maxResultCount=' + maxResultCount);
        return result;
    }

    GetAll(status, searchKey, skipCount, maxResultCount) {
        let result = http.get('/subscriptions?status=' + status + '&searchKey=' + searchKey + '&skipCount=' + skipCount + '&maxResultCount=' + maxResultCount);
        return result;
    }

    GetAllByUser(userId) {
        let result = http.get('/subscriptions/user/' + userId);
        return result;
    }

    Get(id) {
        let result = http.get('/subscriptions/' + id);
        return result;
    }
}

export default new SubscriptionService();