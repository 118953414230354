import { API_URL, MAIN_API_URL, APP_URL, MAIN_APP_URL, MIDTRANS_CLIENT_KEY, MIDTRANS_SCRIPT_URL, GOOGLE_ANALYTICS_TRACKING_CODE, URL_SUPPORT_TEAM, HFM_IB, HFM_URL, HFM_IB_2, HFM_URL_2 } from '../configuration';

const AppConsts = {
    RuangTrader_APIURL: API_URL,
    RuangTrader_APPURL: APP_URL,
    RuangTrader_MAINAPPURL: MAIN_APP_URL,
    RuangTrader_MAINAPIURL: MAIN_API_URL,
    Midtrans: {
        ClientKey: MIDTRANS_CLIENT_KEY,
        ScriptUrl: MIDTRANS_SCRIPT_URL
    },
    GoogleAnalyticTrackingCode: GOOGLE_ANALYTICS_TRACKING_CODE,
    Modules: {
        Others: "Others"
    },
    UrlSupportTeam: URL_SUPPORT_TEAM,
    HFM: {
        IB: HFM_IB,
        URL: HFM_URL,
        IB_2: HFM_IB_2,
        URL_2: HFM_URL_2
    }
};

export default AppConsts;