import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class NotAuthorizeModule extends Component {
    constructor() {
        super();
        this.handleRedirectToUpgradePlan = this.handleRedirectToUpgradePlan.bind(this);
    }

    handleRedirectToUpgradePlan() {
        this.props.history.push('/upgradeplans');
    }

    render() {
        return (
            <div className="confirm-account-wrapper" >
                <div className="wrapper-inner">
                    <div className="action-box">
                        <div className="box-content">
                            <img className="light-image" src="images/illustrations/placeholders/launch.svg" alt="" />
                            <img className="dark-image" src="images/illustrations/placeholders/launch-dark.svg" alt="" />
                            <h3 className="dark-inverted">Upgrade untuk mengakses fitur ini</h3>
                            <p>Memang fitur ini terbukti membantu banyak trader untuk mencapai konsistensi yang lebih baik, maka fitur ini juga jelas Anda butuhkan.</p>
                            <div className="buttons">
                                <button className="button h-button is-primary is-elevated is-raised" onClick={this.handleRedirectToUpgradePlan}>Upgrade Sekarang</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(NotAuthorizeModule);