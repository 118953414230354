import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import OrderService from '../../../../services/orders/orderService';
import DialogDownloadReport from './downloadReport';
import './listOrder.scss';

class ListInvoice extends Component {
    constructor() {
        super();
        this.state = {
            status: 10,
            orders: [],
            isLoading: true,
            searchKey: '',
            skipCount: 0,
            maxResultCount: 20,
            totalOrder: 0,
            pageNo: 1,
            isShowDownloadReportDialog: false
        };
        this.syncOrders = this.syncOrders.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleClickPrev = this.handleClickPrev.bind(this);
        this.handleClickNext = this.handleClickNext.bind(this);
        this.handleClickRow = this.handleClickRow.bind(this);
        this.showDialogDownloadReport = this.showDialogDownloadReport.bind(this);
        this.closeDialogDownloadReport = this.closeDialogDownloadReport.bind(this);
    }

    componentDidMount() {
        this.syncOrders();
    }

    syncOrders() {
        this.setState({ isLoading: true });
        OrderService.GetAll(this.state.status, this.state.searchKey, this.state.skipCount, this.state.maxResultCount).then(res => {
            this.setState({
                orders: res.data.result.items,
                totalOrder: res.data.result.totalCount,
                totalPage: Math.ceil(res.data.result.totalCount / this.state.maxResultCount)
            });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    handleChangeStatus(status) {
        this.setState({ status: status }, () => {
            this.syncOrders();
        });
    }

    handleClickPrev() {
        if (this.state.pageNo > 1) {
            var pageNo = this.state.pageNo - 1;
            this.setState({
                pageNo: pageNo,
                skipCount: (pageNo - 1) * this.state.maxResultCount
            }, () => {
                this.syncOrders();
            })
        }
    }

    handleClickNext() {
        if (this.state.pageNo < this.state.totalPage) {
            var pageNo = this.state.pageNo + 1;
            this.setState({
                pageNo: pageNo,
                skipCount: (pageNo - 1) * this.state.maxResultCount
            }, () => {
                this.syncOrders();
            });
        }
    }

    handleClickRow(invoice) {
        this.props.history.push("/admin/orders/" + invoice.id);
    }

    showDialogDownloadReport() {
        this.setState({ isShowDownloadReportDialog: true });
    }

    closeDialogDownloadReport() {
        this.setState({ isShowDownloadReportDialog: false });
    }

    render() {
        const { isLoading, status, orders, pageNo, totalPage, totalOrder } = this.state;
        return (
            <div className="search-results-wrapper is-admin-order-table banking-dashboard banking-dashboard-v3">
                <div className="search-results-body">
                    <div className="columns">
                        <div className="column">
                            <div className="tabs-wrapper">
                                <div className="tabs-inner">
                                    <div className="tabs-inner">
                                        <h3 className='title is-thin is-5 is-pulled-left p-2 is-hidden-mobile'>Order</h3>
                                        <h3 className='title is-thin is-5 p-2 is-hidden-desktop'>Order</h3>
                                        <div className="tabs is-toggle is-right">
                                            <ul>
                                                <li className={(status === 10 ? "is-active" : "")} onClick={() => this.handleChangeStatus(10)}><a>Belum Bayar</a></li>
                                                <li className={(status === 20 ? "is-active" : "")} onClick={() => this.handleChangeStatus(20)}><a>Belum Lunas</a></li>
                                                <li className={(status === 30 ? "is-active" : "")} onClick={() => this.handleChangeStatus(30)}><a>Lunas</a></li>
                                                <li className={(status === 40 ? "is-active" : "")} onClick={() => this.handleChangeStatus(40)}><a>Batal</a></li>
                                                <li className={(status === 0 ? "is-active" : "")} onClick={() => this.handleChangeStatus(0)}><a>Semua</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={"flex-table is-compact has-loader " + (isLoading ? "has-loader-active min-height" : "")}>
                                        <div className="flex-table-header">
                                            <span className="flex-small">Invoice #</span>
                                            <span className="flex-medium">Client</span>
                                            <span className="flex-medium">Tanggal</span>
                                            <span className="">Produk</span>
                                            <span className="flex-small">Paket</span>
                                            <span className="flex-medium">Harga</span>
                                            <span className="flex-medium">Total Dibayar</span>
                                            <span className="flex-medium">Metode Pem.</span>
                                            <span className="flex-small">Status</span>
                                            <span className="flex-small"></span>
                                        </div>
                                        {
                                            !isLoading ?
                                                (
                                                    (orders.length > 0) ?
                                                        orders.map((order, index) => (
                                                            <div className='flex-table-item' key={index}>
                                                                <div className="flex-table-cell flex-small" data-th="Invoice">
                                                                    <span className="light-text">
                                                                        <a href={"/admin/orders/" + order.id}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.handleClickRow(order)
                                                                            }}>
                                                                            <span className="dark-text has-text-weight-medium">
                                                                                {order.invoiceNo}
                                                                            </span>
                                                                        </a>
                                                                    </span>
                                                                </div>
                                                                <div className="flex-table-cell flex-medium" data-th="Client">
                                                                    <span className="light-text">{order.customerName}</span>
                                                                </div>
                                                                <div className="flex-table-cell flex-medium" data-th="Tanggal">
                                                                    <span className="light-text">{moment(order.creationTime).local().format('LLL')}</span>
                                                                </div>
                                                                <div className="flex-table-cell" data-th="Produk">
                                                                    <span className="light-text">{order.product.name}</span>
                                                                </div>
                                                                <div className="flex-table-cell flex-small" data-th="Paket">
                                                                    <span className="light-text">{order.productSubscriptionPlan.typeName}</span>
                                                                </div>
                                                                <div className="flex-table-cell flex-medium" data-th="Harga">
                                                                    <span className="light-text">
                                                                        {
                                                                            order.amount === 0 ?
                                                                                <span>FREE</span>
                                                                                :
                                                                                <NumberFormat value={order.amount} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className="flex-table-cell flex-medium" data-th="Total Bayar">
                                                                    <span className="light-text">
                                                                        {
                                                                            order.paidAmount === 0?
                                                                                <span>-</span>
                                                                                :
                                                                                <NumberFormat value={order.paidAmount} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <div className="flex-table-cell flex-medium" data-th="Metode Pembayaran">
                                                                    <span className="light-text">{order.paymentMethodName}</span>
                                                                </div>
                                                                <div className="flex-table-cell flex-small" data-th="Status">
                                                                    <span className="light-text">
                                                                        <span className={"tag is-rounded " + (order.status === 10 ? "is-danger" : order.status === 20 ? "is-warning" : order.status === 30 ? "is-success" : "")}>{order.statusName}</span>
                                                                    </span>
                                                                </div>
                                                                <div className="flex-table-cell flex-small" data-th="">
                                                                    <a href={"/admin/orders/" + order.id} target="_blank" onClick={(e) => { e.preventDefault(); this.handleClickRow(order) }} rel="noreferrer" className="button is-dark-outlined">Details</a>
                                                                </div>
                                                            </div>
                                                        )
                                                        ) :
                                                        <div className="section-placeholder">
                                                            <div className="placeholder-content">
                                                                <img className="light-image" src="images/illustrations/placeholders/search-4.svg" alt="" />
                                                                <img className="dark-image" src="images/illustrations/placeholders/search-4-dark.svg" alt="" />
                                                                <h3 className="dark-inverted">Tidak ada Orderan</h3>
                                                                <p>Saat ini belum ada Orderan yang bisa ditampilkan.</p>
                                                            </div>
                                                        </div>
                                                )
                                                :
                                                <div className="h-loader-wrapper">
                                                    <div className="loader is-large is-loading"></div>
                                                </div>
                                        }
                                    </div>
                                </div>
                                {
                                    totalPage > 0 ?
                                        <div className="is-clearfix">
                                            <div className="buttons has-addons is-scrollable mt-2 mb-0 is-pulled-left">
                                                {
                                                    pageNo === 1 ? <></> : <button className={"button h-button is-raised"} onClick={this.handleClickPrev}>Prev</button>
                                                }
                                                {
                                                    pageNo === totalPage ? <></> : <button className={"button is-primary h-button is-raised"} onClick={this.handleClickNext}>Next</button>
                                                }
                                            </div>
                                            <div className="is-pulled-left mt-3">
                                                <span className="ml-3 dark-inverted">Total Order: {totalOrder}, page {pageNo} of {totalPage}</span>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                                <div className="is-clearfix mt-3">
                                    <div className="buttons">
                                        <a className="button h-button is-primary is-raised" onClick={this.showDialogDownloadReport}>
                                            <span className="icon">
                                                <i className="fas fa-file-excel"></i>
                                            </span>
                                            <span>Download Report</span>
                                        </a>
                                    </div>
                                </div>
                                {
                                    this.state.isShowDownloadReportDialog ?
                                        (
                                            <DialogDownloadReport handleClose={this.closeDialogDownloadReport} status={this.state.status} />
                                        )
                                        :
                                        (<></>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ListInvoice);