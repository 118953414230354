import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import SubscriptionService from '../../../../services/subscription/subscriptionService';
import './listSubscription.scss';

class ListInvoice extends Component {
    constructor() {
        super();
        this.state = {
            status: 10,
            subscriptions: [],
            isLoading: true,
            searchKey: '',
            skipCount: 0,
            maxResultCount: 20,
            totalOrder: 0,
            pageNo: 1
        };
        this.syncSubscriptions = this.syncSubscriptions.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleClickPrev = this.handleClickPrev.bind(this);
        this.handleClickNext = this.handleClickNext.bind(this);
        this.handleClickRow = this.handleClickRow.bind(this);
        this.handleNavigate = this.handleNavigate.bind(this);
    }

    componentDidMount() {
        this.syncSubscriptions();
    }

    syncSubscriptions() {
        this.setState({ isLoading: true });
        SubscriptionService.GetAll(this.state.status, this.state.searchKey, this.state.skipCount, this.state.maxResultCount).then(res => {
            this.setState({
                subscriptions: res.data.result.items,
                totalSubscription: res.data.result.totalCount,
                totalPage: Math.ceil(res.data.result.totalCount / this.state.maxResultCount)
            });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    handleChangeStatus(status) {
        this.setState({ status: status }, () => {
            this.syncSubscriptions();
        });
    }

    handleClickPrev() {
        if (this.state.pageNo > 1) {
            var pageNo = this.state.pageNo - 1;
            this.setState({
                pageNo: pageNo,
                skipCount: (pageNo - 1) * this.state.maxResultCount
            }, () => {
                this.syncSubscriptions();
            })
        }
    }

    handleClickNext() {
        if (this.state.pageNo < this.state.totalPage) {
            var pageNo = this.state.pageNo + 1;
            this.setState({
                pageNo: pageNo,
                skipCount: (pageNo - 1) * this.state.maxResultCount
            }, () => {
                this.syncSubscriptions();
            });
        }
    }

    handleClickRow(invoice) {
        this.props.history.push("/admin/orders/" + invoice.id);
    }

    handleNavigate(ev) {
        ev.preventDefault();
        this.props.history.push(ev.currentTarget.getAttribute('href'));
    }

    render() {
        const { isLoading, status, subscriptions, pageNo, totalPage, totalSubscription } = this.state;
        return (
            <div className="search-results-wrapper is-admin-subscription-table banking-dashboard banking-dashboard-v3">
                <div className="search-results-body">
                    <div className="columns">
                        <div className="column">
                            <div className="tabs-wrapper">
                                <div className="tabs-inner">
                                    <div className="tabs-inner">
                                        <h3 className='title is-thin is-5 is-pulled-left p-2 is-hidden-mobile'>Subscription</h3>
                                        <h3 className='title is-thin is-5 p-2 is-hidden-desktop'>Subscription</h3>
                                        <div className="tabs is-toggle is-right">
                                            <ul>
                                                <li className={(status === 10 ? "is-active" : "")} onClick={() => this.handleChangeStatus(10)}><a>Belum Ada Akun</a></li>
                                                <li className={(status === 20 ? "is-active" : "")} onClick={() => this.handleChangeStatus(20)}><a>Approval</a></li>
                                                <li className={(status === 30 ? "is-active" : "")} onClick={() => this.handleChangeStatus(30)}><a>Error</a></li>
                                                <li className={(status === 40 ? "is-active" : "")} onClick={() => this.handleChangeStatus(40)}><a>Aktif</a></li>
                                                <li className={(status === 60 ? "is-active" : "")} onClick={() => this.handleChangeStatus(60)}><a>Tidak Aktif</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={"flex-table is-compact has-loader " + (isLoading ? "has-loader-active min-height" : "")}>
                                        {
                                            !isLoading ?
                                                subscriptions.length > 0 ?
                                                    (
                                                        <div id="active-items-tab" className="tab-content is-active">
                                                            <div className="flex-table">
                                                                <div className="flex-table-header" data-filter-hide>
                                                                    <span className="is-grow">Produk</span>
                                                                    <span className="">Client</span>
                                                                    <span>Platform</span>
                                                                    <span>Account</span>
                                                                    <span>Berlaku Sejak</span>
                                                                    <span>Berlaku Sampai</span>
                                                                    <span>Status</span>
                                                                    <span className="cell-end"></span>
                                                                </div>

                                                                {
                                                                    subscriptions.map(subscription =>
                                                                        <div className="flex-list-inner" key={subscription.id}>
                                                                            <div className="flex-table-item">
                                                                                <div className="flex-table-cell is-media is-grow">
                                                                                    <img className="media" src={subscription.order.product.firstProductImage.url} alt="" />
                                                                                    <div>
                                                                                        <span className="item-name dark-inverted" data-filter-match>{subscription.order.product.name}</span>
                                                                                        <div className="item-meta">
                                                                                            <div className="flex-media">
                                                                                                <div className="meta ml-0">
                                                                                                    <span>{subscription.order.productSubscriptionPlan.typeName}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex-table-cell" data-th="Client">
                                                                                    <span className="light-text" data-filter-match>{subscription.order.customerName}</span>
                                                                                </div>
                                                                                <div className="flex-table-cell" data-th="Platform">
                                                                                    <span className="light-text" data-filter-match>{subscription.platformName} {subscription.isMustAccountHotForex ? "Hotforex" : (subscription.isMustAccountHotForexPremium) ? "Hotforex Premium" : (subscription.isMustAccountHotForexCent) ? "Hotforex Cent": ""}</span>
                                                                                </div>
                                                                                <div className="flex-table-cell" data-th="Account">
                                                                                    <i className="lnil lnil-html5 cell-icon is-pushed-mobile"></i>
                                                                                    <span className="light-text" data-filter-match>{subscription.account}</span>
                                                                                </div>
                                                                                <div className="flex-table-cell" data-th="Berlaku Sejak">
                                                                                    <span className="light-text" data-filter-match>{moment(subscription.startDate).local().format('DD MMM YYYY')}</span>
                                                                                </div>
                                                                                <div className="flex-table-cell" data-th="Berlaku Sampai">
                                                                                    <span className="light-text" data-filter-match>{subscription.isLifetime ? "Lifetime" : moment(subscription.endDate).local().format('DD MMM YYYY')}</span>
                                                                                </div>
                                                                                <div className="flex-table-cell" data-th="Status">
                                                                                    <span className={"light-text tag is-rounded " + (subscription.status === 10 ? "is-danger" : subscription.status === 20 ? "is-warning" : subscription.status === 30 ? "is-danger" : subscription.status === 40 ? "is-success" : "is-danger")}>{subscription.statusName}</span>
                                                                                    {
                                                                                        subscription.accountWarningMessage ?
                                                                                            <span class="ml-2 light-text tag is-rounded hint--top is-danger" data-hint={subscription.accountWarningMessage + ", silahkan hubungi team support"}>Error</span>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                </div>
                                                                                <div className="flex-table-cell cell-end" data-th="Actions">
                                                                                    <a className="button h-button has-dot dark-outlined is-pushed-mobile" href={"/clients/information/" + subscription.order.userId} onClick={this.handleNavigate}>Detail</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    <div className="page-placeholder custom-text-filter-placeholder">
                                                        <div className="placeholder-content">
                                                            <img className="light-image" src="images/illustrations/placeholders/search-4.svg" alt="" />
                                                            <img className="dark-image" src="images/illustrations/placeholders/search-4-dark.svg" alt="" />
                                                            <h3>Tidak ada subscription yang ditemukan.</h3>
                                                            <p className="is-larger">Tidak ada data Subscription yang ditemukan.</p>
                                                        </div>
                                                    </div>
                                                :
                                                <div className="h-loader-wrapper">
                                                    <div className="loader is-large is-loading"></div>
                                                </div>
                                        }
                                    </div>
                                </div>
                                {
                                    totalPage > 0 ?
                                        <div className="is-clearfix">
                                            <div className="buttons has-addons is-scrollable mt-2 mb-0 is-pulled-left">
                                                {
                                                    pageNo === 1 ? <></> : <button className={"button h-button is-raised"} onClick={this.handleClickPrev}>Prev</button>
                                                }
                                                {
                                                    pageNo === totalPage ? <></> : <button className={"button is-primary h-button is-raised"} onClick={this.handleClickNext}>Next</button>
                                                }
                                            </div>
                                            <div className="is-pulled-left mt-3">
                                                <span className="ml-3 dark-inverted">Total Subscription: {totalSubscription}, page {pageNo} of {totalPage}</span>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ListInvoice);