import React, { Component } from 'react';
import { Search } from 'react-feather';
import { withRouter } from 'react-router-dom';
import AppConst from '../../../../lib/appconst';
import ProductService from '../../../../services/products/productService';

class ListProduct extends Component {
    constructor() {
        super();
        this.syncProducts = this.syncProducts.bind(this);
        this.handleNavigate = this.handleNavigate.bind(this);
        this.redirectToAddProduct = this.redirectToAddProduct.bind(this);
        this.redirectToProductType = this.redirectToProductType.bind(this);

        this.state = {
            products: [],
            isLoading: true
        };
    }

    componentDidMount() {
        this.syncProducts();
    }

    handleNavigate(ev) {
        ev.preventDefault();
        var href = ev.currentTarget.getAttribute('href');
        this.props.history.push(href);
    }

    redirectToAddProduct() {
        this.props.history.push('/admin/products/add');
    }

    redirectToProductType() {
        this.props.history.push('/admin/producttypes');
    }

    syncProducts() {
        this.setState({ isLoading: true });
        ProductService.GetAll().then(res => {
            this.setState({ products: res.data.result, isLoading: false });
        });
    }

    render() {
        const { products, isLoading } = this.state;
        return (
            <>
                <div className="card-grid-toolbar">
                    <div className="control has-icon">
                        <input className="input custom-text-filter" placeholder="Search..." data-filter-target=".column" />
                        <div className="form-icon">
                            <Search />
                        </div>
                    </div>

                    <div className="buttons">
                        <button className="button h-button is-primary is-raised" onClick={this.redirectToAddProduct}>
                            <span className="icon">
                                <i className="fas fa-plus"></i>
                            </span>
                            <span>Tambah Produk</span>
                        </button>
                        <button className="button h-button is-primary is-raised" onClick={this.redirectToProductType}>
                            <span className="icon">
                                <i className="fas fa-list"></i>
                            </span>
                            <span>Tipe Produk</span>
                        </button>
                    </div>
                </div>

                <div className="page-content-inner is-webapp">
                    <div className={"card-grid card-grid-v2 " + (isLoading ? "has-loader-active min-height" : "")}>
                        {
                            !isLoading ?
                                products.length > 0 ?
                                    <div className="columns is-multiline">
                                        {
                                            products.map((product) => (
                                                <div className="column is-3" key={product.id}>
                                                    <div className="card-grid-item">
                                                        <div className="card">
                                                            <header className="card-header">
                                                                <div className="card-header-title">
                                                                    <div className="meta">
                                                                        <span className="dark-inverted" data-filter-match>{product.name}</span>
                                                                        <span>{product.statusName}</span>
                                                                    </div>
                                                                </div>
                                                            </header>
                                                            <div className="card-image">
                                                                <figure className="image is-4by3">
                                                                    <img src={AppConst.RuangTrader_APIURL + "/products/image/" + product.id + "/" + product.images[0].id} alt="" />
                                                                </figure>
                                                            </div>
                                                            <footer className="card-footer">
                                                                <a href={"/products/" + product.id} className="card-footer-item">View</a>
                                                                <a href={'/admin/products/' + product.id + '/edit'} className="card-footer-item" onClick={this.handleNavigate}>Edit</a>
                                                            </footer>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className="page-placeholder custom-text-filter-placeholder">
                                        <div className="placeholder-content">
                                            <img className="light-image" src="assets/img/illustrations/placeholders/search-3.svg" alt="" />
                                            <img className="dark-image" src="assets/img/illustrations/placeholders/search-3-dark.svg" alt="" />
                                            <h3>Produk tidak ditemukan.</h3>
                                            <p className="is-larger">Silahkan tambah produk baru.</p>
                                        </div>
                                    </div>
                                :
                                <div className="h-loader-wrapper">
                                    <div className="loader is-large is-loading"></div>
                                </div>
                        }


                        <div className="infinite-scroll-loader" data-filter-hide>
                            <div className="infinite-scroll-loader-inner">
                                <div className="loader is-loading"></div>
                                <div className="loader-end is-hidden">
                                    <span>No more items to load</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>);
    }
};

export default withRouter(ListProduct);