import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import BankAccountService from '../../../../services/bankaccounts/bankAccountService';
import OrderService from '../../../../services/orders/orderService';

class DialogPayManual extends Component {
    constructor() {
        super();
        this.state = {
            order: null,
            isLoading: false,
            bankName: '',
            bankAccountNumber: '',
            bankAccountName: '',
            ruangTraderBankAccountId: '',
            screenshot: null,
            amount: 0,
            isFullPayment: true,
            bankAccounts: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeBankName = this.handleChangeBankName.bind(this);
        this.handleChangeBankAccountNumber = this.handleChangeBankAccountNumber.bind(this);
        this.handleChangeBankAccountName = this.handleChangeBankAccountName.bind(this);
        this.handleChangeScreenshot = this.handleChangeScreenshot.bind(this);
        this.handleSyncBankAccount = this.handleSyncBankAccount.bind(this);
        this.handleChangeRuangTraderBankAccount = this.handleChangeRuangTraderBankAccount.bind(this);
        this.handleChangeAmount = this.handleChangeAmount.bind(this);
        this.handleChangeIsFullPayment = this.handleChangeIsFullPayment.bind(this);
    }

    componentDidMount() {
        this.setState({
            order: this.props.order
        }, () => {
            this.handleSyncBankAccount();
        });
    }

    handleSyncBankAccount() {
        BankAccountService.GetAll().then(res => {
            this.setState({ bankAccounts: res.data.result });
        });
    }

    handleChangeBankName(ev) {
        this.setState({ bankName: ev.currentTarget.value });
    }

    handleChangeBankAccountNumber(ev) {
        this.setState({ bankAccountNumber: ev.currentTarget.value });
    }

    handleChangeBankAccountName(ev) {
        this.setState({ bankAccountName: ev.currentTarget.value });
    }

    handleChangeRuangTraderBankAccount(ev) {
        this.setState({ ruangTraderBankAccountId: ev.currentTarget.value });
    }

    handleChangeScreenshot(ev) {
        this.setState({ screenshot: ev.currentTarget.files[0] });
    }

    handleChangeAmount(ev) {
        this.setState({ amount: ev.currentTarget.value });
    }

    handleChangeIsFullPayment() {
        this.setState({ isFullPayment: !this.state.isFullPayment });
    }

    handleSubmit(ev) {
        ev.preventDefault();
        this.setState({ isLoading: true });
        OrderService.PayManual(this.state.order.id, this.state.bankName, this.state.bankAccountNumber, this.state.bankAccountName, this.state.ruangTraderBankAccountId, this.state.screenshot, this.state.amount, this.state.isFullPayment).then(res => {
            this.props.handleClose();
            this.setState({ isLoading: false });
            window.location.reload();
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        const { bankAccounts } = this.state;
        return (
            <div className="modal h-modal is-small is-active">
                <div className="modal-background h-modal-close"></div>
                <div className="modal-content">
                    <div className="modal-card">
                        <form className="modal-form" onSubmit={this.handleSubmit}>
                            <header className="modal-card-head">
                                <h3>Pembayaran</h3>
                                <button className="h-modal-close ml-auto" aria-label="close">
                                    <i data-feather="x"></i>
                                </button>
                            </header>
                            <div className="modal-card-body">
                                <div className="inner-content">
                                    <div className="field">
                                        <label>Nama bank</label>
                                        <div className="control">
                                            <div className="field has-addons">
                                                <div className="control is-expanded">
                                                    <input className="input" type="text" value={this.state.bankName} onChange={this.handleChangeBankName} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label>Nomor rekening</label>
                                        <div className="control">
                                            <div className="field has-addons">
                                                <div className="control is-expanded">
                                                    <input className="input" type="text" value={this.state.bankAccountNumber} onChange={this.handleChangeBankAccountNumber} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label>Nama Rekening</label>
                                        <div className="control">
                                            <div className="field has-addons">
                                                <div className="control is-expanded">
                                                    <input className="input" type="text" value={this.state.bankAccountName} onChange={this.handleChangeBankAccountName} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label>Tujuan transfer</label>
                                        <div className="control">
                                            <div className="select is-fullwidth">
                                                <select onChange={this.handleChangeRuangTraderBankAccount}>
                                                    <option disabled selected value>Select Bank Account</option>
                                                    {
                                                        bankAccounts.length > 0 ?
                                                            bankAccounts.map(bankAccount =>
                                                                <option value={bankAccount.id} key={bankAccount.id}>{bankAccount.bankAccountNumber + " - " + bankAccount.bankAccountName + " (" + bankAccount.bankName + ")"}</option>
                                                            )
                                                            :
                                                            <option value='0'>No Data</option>
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label>Bukti pembayaran</label>
                                        <div className="control">
                                            <div className="field has-addons">
                                                <div className="control is-expanded">
                                                    <input className="input" type="file" accept="image/*" onChange={this.handleChangeScreenshot} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label>Total yang dibayar</label>
                                        <div className="control">
                                            <div className="field has-addons">
                                                <div className="control is-expanded">
                                                    <input className="input" type="number" value={this.state.amount} onChange={this.handleChangeAmount} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field">
                                        <label>Apakah sudah Lunas?</label>
                                        <div class="control">
                                            <div class="control">
                                                <label class="form-switch is-primary">
                                                    <input type="checkbox" class="is-switch" checked={this.state.isFullPayment} onChange={this.handleChangeIsFullPayment} />
                                                    <i></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-card-foot is-end">
                                <button className="button h-button is-rounded h-modal-close" onClick={this.props.handleClose}>Batal</button>
                                <button type={"submit"} className={"button h-button is-primary is-raised is-rounded " + (this.state.isLoading ? "is-loading" : "")}>Bayar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        );
    }
}

export default withRouter(DialogPayManual);