import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import ProtectedModuleRoute from '../../Router/ProtectedModuleRouter';
import List from './List';

class SubscriptionPage extends Component {
    render() {
        return (
            <Switch>
                <ProtectedModuleRoute exact path="/admin/subscriptions" component={List} />
            </Switch>
        )
    }
};

export default SubscriptionPage;