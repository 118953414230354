import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class MidtransFinished extends Component {
    constructor() {
        super();
        this.handleRedirectToOrder = this.handleRedirectToOrder.bind(this);
    }

    handleRedirectToOrder() {
        this.props.history.push('/orders');
    }
    render() {
        return (
            <div className="confirm-account-wrapper" >
                <div className="wrapper-inner">
                    <div className="action-box">
                        <div className="box-content">
                            <img className="light-image" src="images/illustrations/placeholders/thinking-canvas.svg" alt="" />
                            <img className="dark-image" src="images/illustrations/placeholders/thinking-canvas-dark.svg" alt="" />
                            <h3 className="dark-inverted">Anda telah Membatalkannya</h3>
                            <p>Sayang sekali Anda telah membatalkan transaksi Anda. Apakah Anda menemui kesulitan?</p>
                            <div className="buttons">
                                <button className="button h-button is-primary is-elevated is-raised" onClick={this.handleRedirectToOrder}>Lihat Order</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
    }
}

export default withRouter(MidtransFinished);