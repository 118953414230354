import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthenticatedRouter from '../Router/AuthenticatedRouter';
import ListOrder from './List';
import DetailOrder from './Detail';
import MidtransFinished from './Detail/MidtransFinished';
import MidtransUnFinished from './Detail/MidtransUnFinished';
import MidtransError from './Detail/MidtransError';

class OrderPage extends Component {
    render() {
        return (
            <Switch>
                <AuthenticatedRouter exact path="/orders" component={ListOrder} />
                <AuthenticatedRouter exact path="/orders/:id" component={DetailOrder} />
                <AuthenticatedRouter exact path='/orders/midtrans/finished' component={MidtransFinished} />
                <AuthenticatedRouter exact path='/orders/midtrans/unfinished' component={MidtransUnFinished} />
                <AuthenticatedRouter exact path='/orders/midtrans/error' component={MidtransError} />
            </Switch>
        )
    }
};

export default OrderPage;