import http from "../httpService";

class ClientService {
    GetSimpleClient(id) {
        let result = http.get('/accounts/' + id);
        return result;
    }

    GetCurrentLoggedInClient() {
        let result = http.get('/accounts/loggedin');
        return result;
    }

    GetSimpleClients(status, skipCount, maxResultCount, searchKey) {
        let result = http.get('/accounts?searchKey=' + searchKey + '&status=' + status + '&skipCount=' + skipCount + '&maxResultCount=' + maxResultCount);
        return result;
    }

    CreateByAdmin(firstName, surname, email, phoneNumber, street, city, country, editionId) {
        var data = {
            firstName: firstName,
            surname: surname,
            email: email,
            phoneNumber: phoneNumber,
            street: street,
            city: city,
            country: country,
            editionId: editionId
        };
        let result = http.post('/accounts/CreateUserByAdmin', data, { customErrorHandler: true });
        return result;
    }

    UpdatePersonalInformationByAdmin(userId, firstName, surname, email, phoneNumber, street, city, country) {
        var data = {
            userId: userId,
            firstName: firstName,
            surname: surname,
            email: email,
            phoneNumber: phoneNumber,
            street: street,
            city: city,
            country: country
        };
        let result = http.put('/accounts/UpdatePersonalInformationByAdmin', data, { customErrorHandler: true });
        return result;
    }

    UpdatePersonalInformation(firstName, surname, phoneNumber, street, city) {
        var data = {
            firstName: firstName,
            surname: surname,
            phoneNumber: phoneNumber,
            street: street,
            city: city
        };
        let result = http.put('/accounts/UpdatePersonalInformation', data, { customErrorHandler: true });
        return result;
    }
}

export default new ClientService();