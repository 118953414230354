import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import ProductService from '../../../services/products/productService';
import OrderService from '../../../services/orders/orderService';
import InlineErrorMessage from '../../../components/InlineErrorMessage';
import { withRouter } from 'react-router';
import NotifyUtil from '../../../utils/notyf-utils';
import AuthUtil from '../../../utils/auth-utils';
import AuthService from '../../../services/auth/authService';
import "./detail.scss";

class DetailCheckout extends Component {
    constructor() {
        super();

        this.syncProduct = this.syncProduct.bind(this);
        this.handleChangePlan = this.handleChangePlan.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleChangeFirstName = this.handleChangeFirstName.bind(this);
        this.handleChangeLastName = this.handleChangeLastName.bind(this);
        this.handleChangeEmailAddress = this.handleChangeEmailAddress.bind(this);
        this.handleChangePhoneNumber = this.handleChangePhoneNumber.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.handleChangeStreet = this.handleChangeStreet.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(this);
        this.handleRedirectToLoginPage = this.handleRedirectToLoginPage.bind(this);

        this.state = {
            id: '',
            name: '',
            description: '',
            detailDescription: '',
            price: '',
            firstProductImage: null,
            isLoading: false,
            isNeedMetatrader4Account: false,
            isNeedMetatrader4HFAccount: false,
            isNeedMetatrader4HFPremiumAccount: false,
            isNeedTradingViewAccount: false,
            selectedPlan: 999,
            isEnableMonthlyPlan: false,
            priceMonthlyPlan: 0,
            isEnableYearlyPlan: false,
            priceYearlyPlan: 0,
            isEnableLifetimePlan: false,
            priceLifetimePlan: 0,
            author: '',

            firstName: '',
            errorFirstName: '',
            lastName: '',
            errorLastName: '',
            emailAddress: '',
            errorEmailAddress: '',
            phoneNumber: '',
            errorPhoneNumber: '',
            city: '',
            errorCity: '',
            street: '',
            errorStreet: '',
            password: '',
            errorPassword: '',
            confirmPassword: '',
            errorConfirmPassword: '',
            errorPasswordMessages: []
        };
    }

    componentDidMount() {
        var user = AuthUtil.GetCurrentUser();
        if (user) {
            window.location = "/products/" + this.props.match.params.id;
        }

        this.setState({ id: this.props.match.params.id }, () => {
            this.syncProduct();
        });
    }

    handleChangePlan(plan) {
        var price = 0;
        switch (plan) {
            case 30: price = this.state.priceMonthlyPlan; break;
            case 365: price = this.state.priceYearlyPlan; break;
            case 999: price = this.state.priceLifetimePlan; break;
        }
        this.setState({ selectedPlan: plan, price: price });
    }

    handleRedirectToLoginPage() {
        window.location = "/products/" + this.state.id;
    }

    handleSubmitForm(ev) {
        ev.preventDefault();

        var errors = {};
        if (!this.state.firstName)
            errors.errorFirstName = 'Nama Depan wajib diisi';
        if (this.state.firstName && this.state.firstName.length < 2)
            errors.errorFirstName = 'Nama Depan harus lebih dari 1 karakter';
        if (!this.state.lastName)
            errors.errorLastName = 'Nama Belakang wajib diisi';
        if (this.state.lastName && this.state.lastName.length < 2)
            errors.errorLastName = 'Nama Belakang harus lebih dari 1 karakter';
        if (!this.state.emailAddress)
            errors.errorEmailAddress = 'Email Address wajib diisi';
        if (!this.state.phoneNumber)
            errors.errorPhoneNumber = 'Kami membutuhkan nomor handphone atau whastapp Anda';
        if (!this.state.city)
            errors.errorCity = 'Kami membutuhkan informasi Kota asal Anda';
        if (this.state.city && this.state.city.length > 255)
            errors.errorCity = 'Informasi Kota tidak boleh lebih besar dari  255 karakter';
        if (!this.state.street)
            errors.errorStreet = 'Kami membuthkan informasi Alamat Anda';
        if (this.state.street && this.state.street.length > 255)
            errors.errorStreet = 'Informasi Alamat tidak boleh lebih besar dari  255 karakter';
        if (!this.state.password)
            errors.errorPassword = 'Password sangat diperlukan';
        if (!this.state.confirmPassword)
            errors.errorConfirmPassword = 'Password sangat diperlukan';
        if (this.state.password && this.state.password !== this.state.confirmPassword)
            errors.errorConfirmPassword = 'Password tidak sama dengan sebelumnya, silahkan ulangi lagi';


        this.setState(errors, () => {
            if (!this.state.errorFirstName && !this.state.errorLastName && !this.state.errorEmailAddress &&
                !this.state.errorPhoneNumber && !this.state.errorCity && !this.state.errorStreet &&
                !this.state.errorPassword && !this.state.errorConfirmPassword) {

                this.setState({ isLoading: true });
                AuthService.CheckAvailableEmail(this.state.emailAddress).then(res => {
                    if (res.data.result) {
                        //register
                        AuthService.Register(this.state.firstName, this.state.lastName, this.state.emailAddress, this.state.phoneNumber, this.state.street, this.state.city, this.state.password, this.state.confirmPassword).then(res => {
                            window.location = "/products/" + this.state.id;
                        }).catch((res) => {
                            if (res.response.data.error && res.response.data.error.message && res.response.data.error.message.indexOf("Passwords") >= 0) {
                                var errorMessages = res.response.data.error.message.split(',');
                                var errorContent = [];
                                errorMessages.forEach((error) => {
                                    errorContent.push(error);
                                });
                                this.setState({ errorPasswordMessages: errorContent, isLoading: false });
                            } else {
                                this.setState({ errorPasswordMessages: "Terjadi sesuatu yang tidak terduga sehingga tidak bisa menyelesaikan permintaan Anda. Kami mohon maaf.", isLoading: false });
                            }
                        })


                    } else {
                        this.setState({ errorEmailAddress: 'Email Address ini sudah terdaftar, silahkan login.', isLoading: false });
                    }
                }).catch(() => {
                    this.setState({ isLoading: false });
                });
            } else {
                this.setState({ isLoading: false });
            }
        });
    }

    syncProduct() {
        ProductService.Get(this.state.id)
            .then(res => {
                this.setState({
                    name: res.data.result.name,
                    description: res.data.result.description,
                    detailDescription: res.data.result.detailDescription,
                    price: res.data.result.price,
                    isNeedMetatrader4Account: res.data.result.isNeedMetatrader4Account,
                    isNeedMetatrader4HFAccount: res.data.result.isNeedMetatrader4HFAccount,
                    isNeedMetatrader4HFPremiumAccount: res.data.result.isNeedMetatrader4HFPremiumAccount,
                    isNeedTradingViewAccount: res.data.result.isNeedTradingViewAccount,
                    firstProductImage: res.data.result.firstProductImage,
                    isEnableMonthlyPlan: res.data.result.plans.find(i => i.type == 30).isActive,
                    priceMonthlyPlan: res.data.result.plans.find(i => i.type == 30).price,
                    isEnableYearlyPlan: res.data.result.plans.find(i => i.type == 365).isActive,
                    priceYearlyPlan: res.data.result.plans.find(i => i.type == 365).price,
                    isEnableLifetimePlan: res.data.result.plans.find(i => i.type == 999).isActive,
                    priceLifetimePlan: res.data.result.plans.find(i => i.type == 999).price,
                    author: res.data.result.author
                }, () => {
                    if (this.state.isEnableLifetimePlan)
                        this.setState({ selectedPlan: 999, price: this.state.priceLifetimePlan });
                    else if (this.state.isEnableYearlyPlan)
                        this.setState({ selectedPlan: 365, price: this.state.priceYearlyPlan });
                    else if (this.state.isEnableMonthlyPlan)
                        this.setState({ selectedPlan: 30, price: this.state.priceMonthlyPlan });
                });
                var imagesUrls = [];
                for (const image of res.data.result.images) {
                    imagesUrls.push({ priority: image.priority, url: image.url });
                }
                this.setState({
                    imagesUrls: imagesUrls
                });
            });
    }

    handleChangeFirstName(ev) {
        this.setState({ firstName: ev.currentTarget.value, errorFirstName: '' });
    }

    handleChangeLastName(ev) {
        this.setState({ lastName: ev.currentTarget.value, errorLastName: '' });
    }

    handleChangeEmailAddress(ev) {
        this.setState({ emailAddress: ev.currentTarget.value, errorEmailAddress: '' });
    }

    handleChangePhoneNumber(ev) {
        this.setState({ phoneNumber: ev.currentTarget.value, errorPhoneNumber: '' });
    }

    handleChangeCity(ev) {
        this.setState({ city: ev.currentTarget.value, errorCity: '' });
    }

    handleChangeStreet(ev) {
        this.setState({ street: ev.currentTarget.value, errorStreet: '' });
    }

    handleChangePassword(ev) {
        this.setState({ password: ev.currentTarget.value, errorPassword: '', errorPasswordMessages: [] });
    }

    handleChangeConfirmPassword(ev) {
        this.setState({ confirmPassword: ev.currentTarget.value, errorConfirmPassword: '', errorPasswordMessages: [] });
    }

    render() {
        if (!this.state.name)
            return <></>;

        const { name, description, detailDescription, author, price, firstProductImage, isNeedMetatrader4Account, isNeedMetatrader4HFAccount, isNeedMetatrader4HFPremiumAccount, isNeedTradingViewAccount } = this.state;
        return (
            <>
                <div className="promotion-page-wrapper detail-checkout">
                    <div className="wrapper-outer">
                        <div className="wrapper-inner">
                            <div className="action-box">
                                <div className="box-content">
                                    <img className="light-image is-larger" src={firstProductImage.url} alt="" />
                                    <img className="dark-image is-larger" src={firstProductImage.url} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="side-wrapper">
                            <div className="side-inner">
                                <div className="side-inner">
                                    <form onSubmit={this.handleSubmitForm}>
                                        <div className="side-title">
                                            <h3 className="dark-inverted">{name}</h3>
                                            <p>{description}</p>
                                            <p>Dikembangkan oleh {author}</p>
                                        </div>

                                        <div className="side-title mb-1 dark-inverted">
                                            <span style={{ fontSize: "0.9em" }}>Pilih Paket:</span>
                                        </div>
                                        <div className="field">
                                            <div className="control">
                                                <div className="radio-pills">
                                                    {
                                                        this.state.isEnableMonthlyPlan ?
                                                            <div className="radio-pill">
                                                                <input type="radio" name="plan" checked={this.state.selectedPlan === 30} onChange={() => this.handleChangePlan(30)} />
                                                                <div className="radio-pill-inner">
                                                                    <span>1 Bulan</span>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        this.state.isEnableYearlyPlan ?
                                                            <div className="radio-pill">
                                                                <input type="radio" name="plan" checked={this.state.selectedPlan === 365} onChange={() => this.handleChangePlan(365)} />
                                                                <div className="radio-pill-inner">
                                                                    <span>1 Tahun</span>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        this.state.isEnableLifetimePlan ?
                                                            <div className="radio-pill">
                                                                <input type="radio" name="plan" checked={this.state.selectedPlan === 999} onChange={() => this.handleChangePlan(999)} />
                                                                <div className="radio-pill-inner">
                                                                    <span>Lifetime</span>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="side-title mt-5 mb-1 dark-inverted">
                                            <span style={{ fontSize: "1em" }}>Anda akan mendapatkan License: </span>
                                        </div>
                                        <div className="action-list">
                                            <div className="form-body">
                                                <div className="fieldset">
                                                    <div className="columns is-multiline" style={{ maxWidth: '400px' }}>
                                                        <div className="content">
                                                            <ul>
                                                                {
                                                                    isNeedMetatrader4Account ? <li>Metatrader 4</li> : <></>
                                                                }
                                                                {
                                                                    isNeedMetatrader4HFAccount ? <li>Metatrader 4 broker Hotforex</li> : <></>
                                                                }
                                                                {
                                                                    isNeedMetatrader4HFPremiumAccount ? <li>Metatrader 4 broker Hotforex Premium</li> : <></>
                                                                }
                                                                {
                                                                    isNeedTradingViewAccount ? <li>Tradingview</li> : <></>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="side-title dark-inverted mt-5 mb-5" style={{ fontSize: "1em" }}>
                                            <span>Biaya: </span>
                                            {
                                                price === 0 ?
                                                    <span className="is-bold">FREE</span>
                                                    :
                                                    <NumberFormat style={{ fontSize: '1.2em' }} value={price} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                            }
                                        </div>
                                        {/*<button type="submit" className={"button h-button is-fullwidth is-primary is-big is-raised is-bold " + (this.state.isLoading ? 'is-loading' : '')}>Beli Sekarang</button>*/}
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {
                    detailDescription ?
                        <div className="promotion-page-wrapper description-product mt-3">
                            <div className="wrapper-outer">
                                <div className="side-wrapper">
                                    <div className="side-inner">
                                        <div className="side-inner">
                                            <div className="side-title">
                                                <h3 className="dark-inverted">Penjelasan detail {name}</h3>
                                            </div>

                                            <span dangerouslySetInnerHTML={{ __html: detailDescription }}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
                <div className="promotion-page-wrapper description-product mt-3 checkout-register">
                    <div className="wrapper-outer">
                        <div className="side-wrapper">
                            <div className="side-inner">
                                <div className="side-inner">
                                    <div className="side-title">
                                        <h3 className="dark-inverted">Sudah mempunyai akun Ruang Trader?</h3>
                                    </div>
                                    <span>
                                        <button type="button" className={"button h-button is-primary is-big is-raised is-bold "} onClick={this.handleRedirectToLoginPage}>Login Sekarang</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="promotion-page-wrapper description-product mt-3 checkout-register">
                    <div className="wrapper-outer">
                        <div className="side-wrapper">
                            <div className="side-inner">
                                <div className="side-inner">
                                    <div className="side-title">
                                        <h3 className="dark-inverted">Bergabung Sekarang</h3>
                                    </div>
                                    <span>
                                        Isi informasi pribadi Anda di bawah.
                                        Data dibawah ini akan digunakan untuk kepentingan mengakses halaman member serta informasi terkait pembelian.
                                    </span>
                                    <div className="signup-wrapper">
                                        <div className="hero">
                                            <div className="hero-body">
                                                <div className="columns signup-columns">
                                                    <div className="column is-12">
                                                        <div className="signup-card">
                                                            <form className="signup-form is-mobile-spaced" onSubmit={this.handleSubmitForm}>
                                                                <div className="columns is-multiline">
                                                                    <div className="column is-6">
                                                                        <div className="control">
                                                                            <input type="text" className="input" onChange={this.handleChangeFirstName} />
                                                                            <small className="error-text">{this.state.errorFirstName}</small>
                                                                            <div className="auth-label">Nama Depan</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="column is-6">
                                                                        <div className="control has-validation">
                                                                            <input type="text" className="input" onChange={this.handleChangeLastName} />
                                                                            <small className="error-text">{this.state.errorLastName}</small>
                                                                            <div className="auth-label">Nama Belakang</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="column is-6">
                                                                        <div className="control has-validation">
                                                                            <input type="text" className="input" onChange={this.handleChangeEmailAddress} />
                                                                            <small className="error-text">{this.state.errorEmailAddress}</small>
                                                                            <div className="auth-label">Email Address</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="column is-6">
                                                                        <div className="control has-validation">
                                                                            <input type="text" className="input" onChange={this.handleChangePhoneNumber} />
                                                                            <small className="error-text">{this.state.errorPhoneNumber}</small>
                                                                            <div className="auth-label">Berapa nomor handphone atau whatsapp Anda?</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="column is-6">
                                                                        <div className="control has-validation">
                                                                            <input type="text" className="input" onChange={this.handleChangeCity} />
                                                                            <small className="error-text">{this.state.errorCity}</small>
                                                                            <div className="auth-label">Anda tinggal di kota apa?</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="column is-6">
                                                                        <div className="control has-validation">
                                                                            <input type="text" className="input" onChange={this.handleChangeStreet} />
                                                                            <small className="error-text">{this.state.errorStreet}</small>
                                                                            <div className="auth-label">Alamatnya?</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="column is-6">
                                                                        <div className="control has-validation">
                                                                            <input type="password" className="input" onChange={this.handleChangePassword} />
                                                                            <small className="error-text">{this.state.errorPassword}</small>
                                                                            <div className="auth-label">Password</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="column is-6">
                                                                        <div className="control has-validation">
                                                                            <input type="password" className="input" onChange={this.handleChangeConfirmPassword} />
                                                                            <small className="error-text">{this.state.errorConfirmPassword}</small>
                                                                            <div className="auth-label">Ulangi Password Anda</div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <InlineErrorMessage errorMessages={this.state.errorPasswordMessages} />
                                                                <button type="submit" className={"mt-5 button h-button is-fullwidth is-primary is-big is-raised is-bold " + (this.state.isLoading ? 'is-loading' : '')}>Daftar Sekarang</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(DetailCheckout);