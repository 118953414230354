import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class MidtransFinished extends Component {
    constructor() {
        super();
        this.handleRedirectToOrder = this.handleRedirectToOrder.bind(this);
    }

    handleRedirectToOrder() {
        this.props.history.push('/order');
    }
    render() {
        return (
            <div className="confirm-account-wrapper" >
                <div className="wrapper-inner">
                    <div className="action-box">
                        <div className="box-content">
                            <img className="light-image" src="images/illustrations/placeholders/error-5.svg" alt="" />
                            <img className="dark-image" src="images/illustrations/placeholders/error-5-dark.svg" alt="" />
                            <h3 className="dark-inverted">Oops, Terjadi hal yang tidak kita inginkan</h3>
                            <p>Transaksi yang Anda lakukan gagal, silahkan coba kembali. atau hubungi team support Ruang Trader</p>
                            <div className="buttons">
                                <button className="button h-button is-primary is-elevated is-raised" onClick={this.handleRedirectToOrder}>Lihat Order</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
    }
}

export default withRouter(MidtransFinished);