import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/app.scss';
import './styles/main.css';
//import registerServiceWorker from './registerServiceWorker';

//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <App />
  ,
  rootElement);

//registerServiceWorker();

