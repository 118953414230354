import React, { Component } from 'react';
import OrderService from '../../../services/orders/orderService';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { generateShortString } from '../../../utils/string-utils';
import './detailOrder.scss';
import 'alertifyjs/build/css/alertify.css';
import orderService from '../../../services/orders/orderService';
import Midtrans from '../../../lib/midtrans';

class DetailOrder extends Component {
    constructor() {
        super();
        this.state = {
            id: '',
            order: null,
            isLoading: true,
            isLoadingPayMidtrans: false
        };

        this.syncOrder = this.syncOrder.bind(this);
        this.handlePayWithMidtrans = this.handlePayWithMidtrans.bind(this);
        this.handleBackToListOrder = this.handleBackToListOrder.bind(this);
    }

    componentDidMount() {
        var orderId = this.props.match.params.id;
        this.setState({ id: orderId }, () => {
            this.syncOrder();
        });
    }

    syncOrder() {
        this.setState({ isLoading: true });
        OrderService.Get(this.state.id).then(res => {
            this.setState({ order: res.data.result });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    handleBackToListOrder() {
        this.props.history.goBack()
    }

    handlePayWithMidtrans() {
        this.setState({ isLoadingPayMidtrans: true });
        orderService.GenerateTokenMidtrans(this.state.id).then(res => {
            var token = res.data.result.midtransToken;
            window.snap.pay(token);
        }).finally(() => {
            this.setState({ isLoadingPayMidtrans: false });
        });
    }

    render() {
        const { order, isLoading } = this.state;
        return (
            !isLoading ?
                <div className="invoice-wrapper is-order-detail">
                    <Midtrans />
                    <div className="invoice-header">
                        <div className="left">
                            <h3>Invoice {order.invoiceNo} - {order.statusName}</h3>
                        </div>
                    </div>
                    <div className="invoice-body">
                        <div className="invoice-card">
                            <div className="invoice-section is-flex is-bordered">
                                <div className="h-avatar is-large">
                                    <span className={"avatar is-fake is-primary h-avatar"}>
                                        <span> {generateShortString(order.customerName)}</span>
                                    </span>
                                </div>
                                <div className="meta">
                                    <h3>{order.customerName}</h3>
                                    <span>{order.emailAddress}</span>
                                    <span>{order.phoneNumber || '-'}</span>
                                </div>
                                <div className="end">
                                    <h3>Invoice {order.invoiceNo}</h3>
                                    <span>Tanggal: {moment(order.creationTime).local().format('LLL')}</span>
                                </div>
                            </div>
                            <div className="invoice-section is-flex is-bordered">
                                <div className="h-avatar is-customer is-large">
                                    <img className="avatar" src={order.product.firstProductImage.url} alt="" />
                                </div>
                                <div className="meta">
                                    <h3>{order.product.name}</h3>
                                    <span>{order.productSubscriptionPlan.typeName}</span>
                                </div>
                            </div>
                            <div className="invoice-section">
                                <div className="flex-table">

                                    <div className="flex-table-header">
                                        <span className="is-grow">Produk</span>
                                        <span className="cell-end">Paket</span>
                                        <span>Total</span>
                                    </div>

                                    <div className="flex-table-item">
                                        <div className="flex-table-cell is-grow" data-th="">
                                            <span className="dark-text">{order.product.name}</span>
                                        </div>
                                        <div className="flex-table-cell cell-end" data-th="Paket">
                                            <span className="light-text">{order.productSubscriptionPlan.typeName}</span>
                                        </div>
                                        <div className="flex-table-cell has-text-right" data-th="Total">
                                            <span className="dark-inverted">
                                                {
                                                    order.amount === 0 ?
                                                        <span>FREE</span>
                                                        :
                                                        <NumberFormat value={order.amount} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-table sub-table">
                                    <div className="flex-table-item">
                                        <div className="flex-table-cell is-grow is-vhidden" data-th="">
                                            <span className="dark-text"></span>
                                        </div>
                                        <div className="flex-table-cell" data-th="">
                                            <span className="table-label">Total</span>
                                        </div>
                                        <div className="flex-table-cell has-text-right" data-th="">
                                            <span className="table-total is-bigger dark-inverted">
                                                {
                                                    order.amount === 0 ?
                                                        <span>FREE</span>
                                                        :
                                                        <NumberFormat value={order.amount} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-table sub-table">
                                    <div className="flex-table-item">
                                        <div className="flex-table-cell is-grow is-vhidden" data-th="">
                                            <span className="dark-text"></span>
                                        </div>
                                        <div className="flex-table-cell" data-th="">
                                            <span className="table-label">Total yang dibayar</span>
                                        </div>
                                        <div className="flex-table-cell has-text-right" data-th="">
                                            <span className="table-total is-bigger dark-inverted">
                                                {
                                                    order.paidAmount === 0 ?
                                                        <span>-</span>
                                                        :
                                                        <NumberFormat value={order.paidAmount} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {
                                    order.status == 20 ?
                                        <>
                                            <div className="flex-table sub-table">
                                                <div className="flex-table-item">
                                                    <div className="flex-table-cell is-grow is-vhidden" data-th="">
                                                        <span className="dark-text"></span>
                                                    </div>
                                                    <div className="flex-table-cell" data-th="">
                                                        <span className="table-label">Sudah bayar</span>
                                                    </div>
                                                    <div className="flex-table-cell has-text-right" data-th="">
                                                        <span className="table-total is-bigger dark-inverted">
                                                            <NumberFormat value={order.amount - order.outstanding} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-table sub-table">
                                                <div className="flex-table-item">
                                                    <div className="flex-table-cell is-grow is-vhidden" data-th="">
                                                        <span className="dark-text"></span>
                                                    </div>
                                                    <div className="flex-table-cell" data-th="">
                                                        <span className="table-label">Sisa</span>
                                                    </div>
                                                    <div className="flex-table-cell has-text-right" data-th="">
                                                        <span className="table-total is-bigger dark-inverted">
                                                            <NumberFormat value={order.outstanding} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="navigation-buttons mt-2">
                        <div className="buttons is-pulled-left">
                            <button className="button h-button is-large" onClick={this.handleBackToListOrder}>Kembali</button>
                        </div>
                        {
                            order.status === 10 ?
                                <div className="buttons is-right">
                                    <button className={"button h-button is-large is-primary is-raised is-elevated" + (this.state.isLoadingPayMidtrans ? "is-loading" : "")} onClick={this.handlePayWithMidtrans}>Bayar Sekarang</button>
                                </div>
                                :
                                <></>
                        }
                    </div>
                </div>
                :
                <div className="h-loader-wrapper">
                    <div className="loader is-large is-loading"></div>
                </div>
        );
    }
}

export default DetailOrder;