import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthenticatedRouter from '../Router/AuthenticatedRouter';
import ListProduct from './List';
import DetailProduct from './Detail';

class ProductPage extends Component {
    render() {
        return (
            <Switch>
                <AuthenticatedRouter exact path="/products" component={ListProduct} />
                <AuthenticatedRouter exact path="/products/:id" component={DetailProduct} />
            </Switch>
        )
    }
};

export default ProductPage;