import React from 'react';
import ProtectedRoute from '../../Router/ProtectedRouter';
import List from './List';
import Detail from './Detail';

const BillingScene = () => {
    return (
        <>
            <ProtectedRoute exact path="/admin/orders" component={List} />
            <ProtectedRoute exact path="/admin/orders/:id" component={Detail} />
        </>
    );
}

export default BillingScene;