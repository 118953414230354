import React, { Component } from 'react';
import OrderService from '../../../../services/orders/orderService';
import moment from 'moment';
import DialogPayManual from './dialogPayManual';
import AppConsts from '../../../../lib/appconst';
import NumberFormat from 'react-number-format';
import { generateShortString } from '../../../../utils/string-utils';
import RTNotyf from '../../../../utils/notyf-utils';
import './detailOrder.scss';
import 'alertifyjs/build/css/alertify.css';

class DetailOrder extends Component {
    constructor() {
        super();
        this.state = {
            id: '',
            order: null,
            isLoading: true,
            isLoadingCancelOrder: false,
            isShowPayManualDialog: false
        };

        this.syncOrder = this.syncOrder.bind(this);
        this.handleBackToListOrder = this.handleBackToListOrder.bind(this);
        this.showDialogPayManualOrder = this.showDialogPayManualOrder.bind(this);
        this.hideDialogPayManualOrder = this.hideDialogPayManualOrder.bind(this);
        this.handleRedirectToClientPage = this.handleRedirectToClientPage.bind(this);
        this.handleCancelOrder = this.handleCancelOrder.bind(this);
    }

    componentDidMount() {
        var orderId = this.props.match.params.id;
        this.setState({ id: orderId }, () => {
            this.syncOrder();
        });
    }

    syncOrder() {
        this.setState({ isLoading: true });
        OrderService.Get(this.state.id).then(res => {
            this.setState({ order: res.data.result });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    handleRedirectToClientPage() {
        this.props.history.push('/clients/information/' + this.state.order.userId);
    }

    showDialogPayManualOrder() {
        this.setState({ isShowPayManualDialog: true });
    }

    hideDialogPayManualOrder() {
        this.setState({ isShowPayManualDialog: false });
    }

    handleBackToListOrder() {
        this.props.history.goBack();
    }

    handleShowConfirmationApproval() {
        //var self = this;
        //Alertify.confirm('Approve Invoice', 'Are you sure the bank account name and number match with account mutation Ruang Trader?',
        //    function () {
        //        SubscriptionService.Approve(self.state.id).then(res => {
        //            window.location.reload();
        //        });
        //    },
        //    function () { }).set('labels', { ok: 'Yes, Approve', cancel: 'No' });
    }

    handleCancelOrder() {
        this.setState({ isLoadingCancelOrder: true });
        OrderService.CancelOrder(this.state.id).then(res => {
            window.location.reload();
        }).catch(res => {
            this.setState({ isLoadingCancelOrder: false });
            RTNotyf.NotifyError(res.response.data.error.message);
        });
    }

    render() {
        const { order, isLoading } = this.state;
        return (
            !isLoading ?
                <div className="invoice-wrapper is-detail-admin-order">
                    <div className="invoice-header">
                        <div className="left">
                            <h3>Invoice {order.invoiceNo} - {order.statusName}</h3>
                        </div>
                    </div>
                    <div className="invoice-body">
                        <div className="invoice-card">
                            <div className="invoice-section is-flex is-bordered">
                                <div className="h-avatar is-large">
                                    <span className={"avatar is-fake is-primary h-avatar"}>
                                        <span> {generateShortString(order.customerName)}</span>
                                    </span>
                                </div>
                                <div className="meta is-pointer" onClick={this.handleRedirectToClientPage}>
                                    <h3>{order.customerName}</h3>
                                    <span>{order.emailAddress}</span>
                                    <span>{order.phoneNumber || '-'}</span>
                                </div>
                                <div className="end">
                                    <h3>Invoice {order.invoiceNo}</h3>
                                    <span>Tanggal: {moment(order.creationTime).local().format('LLL')}</span>
                                </div>
                            </div>
                            <div className="invoice-section is-flex is-bordered">
                                <div className="h-avatar is-customer is-large">
                                    <img className="avatar" src={order.product.firstProductImage.url} alt="" />
                                </div>
                                <div className="meta">
                                    <h3>{order.product.name}</h3>
                                    <span>{order.productSubscriptionPlan.typeName}</span>
                                </div>
                            </div>
                            <div className="invoice-section">
                                <div className="flex-table">

                                    <div className="flex-table-header">
                                        <span className="is-grow">Produk</span>
                                        <span className="cell-end">Paket</span>
                                        <span>Total</span>
                                    </div>

                                    <div className="flex-table-item">
                                        <div className="flex-table-cell is-grow" data-th="">
                                            <span className="dark-text">{order.product.name}</span>
                                        </div>
                                        <div className="flex-table-cell cell-end" data-th="Paket">
                                            <span className="light-text">{order.productSubscriptionPlan.typeName}</span>
                                        </div>
                                        <div className="flex-table-cell has-text-right" data-th="Total">
                                            <span className="dark-inverted">
                                                {
                                                    order.amount === 0 ?
                                                        <span>FREE</span>
                                                        :
                                                        <NumberFormat value={order.amount} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-table sub-table">
                                    <div className="flex-table-item">
                                        <div className="flex-table-cell is-grow is-vhidden" data-th="">
                                            <span className="dark-text"></span>
                                        </div>
                                        <div className="flex-table-cell" data-th="">
                                            <span className="table-label">Total</span>
                                        </div>
                                        <div className="flex-table-cell has-text-right" data-th="">
                                            <span className="table-total is-bigger dark-inverted">
                                                {
                                                    order.amount === 0 ?
                                                        <span>FREE</span>
                                                        :
                                                        <NumberFormat value={order.amount} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-table sub-table">
                                    <div className="flex-table-item">
                                        <div className="flex-table-cell is-grow is-vhidden" data-th="">
                                            <span className="dark-text"></span>
                                        </div>
                                        <div className="flex-table-cell" data-th="">
                                            <span className="table-label">Total yang dibayar</span>
                                        </div>
                                        <div className="flex-table-cell has-text-right" data-th="">
                                            <span className="table-total is-bigger dark-inverted">
                                                {
                                                    order.paidAmount === 0 ?
                                                        <span>-</span>
                                                        :
                                                        <NumberFormat value={order.paidAmount} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {
                                    order.status == 20 ?
                                        <>
                                            <div className="flex-table sub-table">
                                                <div className="flex-table-item">
                                                    <div className="flex-table-cell is-grow is-vhidden" data-th="">
                                                        <span className="dark-text"></span>
                                                    </div>
                                                    <div className="flex-table-cell" data-th="">
                                                        <span className="table-label">Sudah bayar</span>
                                                    </div>
                                                    <div className="flex-table-cell has-text-right" data-th="">
                                                        <span className="table-total is-bigger dark-inverted">
                                                            <NumberFormat value={order.amount - order.outstanding} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex-table sub-table">
                                                <div className="flex-table-item">
                                                    <div className="flex-table-cell is-grow is-vhidden" data-th="">
                                                        <span className="dark-text"></span>
                                                    </div>
                                                    <div className="flex-table-cell" data-th="">
                                                        <span className="table-label">Sisa</span>
                                                    </div>
                                                    <div className="flex-table-cell has-text-right" data-th="">
                                                        <span className="table-total is-bigger dark-inverted">
                                                            <NumberFormat value={order.outstanding} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>

                    {
                        order.paymentMethod === 10 ?
                            (order.paymentManuals.length > 0) ?
                                order.paymentManuals.map((payment, index) => (
                                    <div className="invoice-body">
                                        <div className="invoice-card">
                                            <div className="invoice-section">
                                                <div className="flex-table sub-table">
                                                    <div className="flex-table-item">
                                                        <div className="flex-table-cell" data-th="">
                                                            <span className="table-label">Tanggal Pembayaran</span>
                                                        </div>
                                                        <div className="flex-table-cell has-text-right" data-th="">
                                                            <span className="table-total is-small dark-inverted">
                                                                {moment(payment.paymentDate).local().format('DD MMM yyyy HH:mm')}
                                                            </span>
                                                        </div>
                                                        <div className="flex-table-cell" data-th="">
                                                            <span className="table-label"></span>
                                                        </div>
                                                        <div className="flex-table-cell has-text-right" data-th="">
                                                            <span className="table-total is-bigger dark-inverted">
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="flex-table-item">
                                                        <div className="flex-table-cell" data-th="">
                                                            <span className="table-label">Bayar Dari</span>
                                                        </div>
                                                        <div className="flex-table-cell has-text-right" data-th="">
                                                            <span className="table-total is-small dark-inverted">
                                                                {payment.bankName}
                                                            </span>
                                                        </div>
                                                        <div className="flex-table-cell" data-th="">
                                                            <span className="table-label">Nomor Rekening </span>
                                                        </div>
                                                        <div className="flex-table-cell has-text-right" data-th="">
                                                            <span className="table-total is-small dark-inverted">
                                                                {payment.bankAccountNumber}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="flex-table-item">
                                                        <div className="flex-table-cell" data-th="">
                                                            <span className="table-label">Nama Rekening</span>
                                                        </div>
                                                        <div className="flex-table-cell has-text-right" data-th="">
                                                            <span className="table-total is-small dark-inverted">
                                                                {payment.bankAccountName}
                                                            </span>
                                                        </div>
                                                        <div className="flex-table-cell" data-th="">
                                                            <span className="table-label">Bayar ke</span>
                                                        </div>
                                                        <div className="flex-table-cell has-text-right" data-th="">
                                                            <span className="table-total is-small dark-inverted">
                                                                {payment.ruangTraderBankAccount.bankName + " - " + payment.ruangTraderBankAccount.bankAccountNumber}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="flex-table-item">
                                                        <div className="flex-table-cell" data-th="">
                                                            <span className="table-label">Total yang dibayar</span>
                                                        </div>
                                                        <div className="flex-table-cell has-text-right" data-th="">
                                                            <span className="table-total is-small dark-inverted">
                                                                <NumberFormat value={payment.amount} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                                            </span>
                                                        </div>
                                                        <div className="flex-table-cell" data-th="">
                                                            <span className="table-label"></span>
                                                        </div>
                                                        <div className="flex-table-cell has-text-right" data-th="">
                                                            <span className="table-total is-small dark-inverted">
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <img src={AppConsts.RuangTrader_APIURL + "/orders/" + order.id + "/paymentmanual/" + payment.id} alt={payment.id} />
                                        </div>
                                    </div>
                                ))
                                :
                                <></>
                            :
                            <></>
                    }


                    <div className="navigation-buttons mt-2">
                        <div className="buttons is-pulled-left">
                            <button className="button h-button is-large" onClick={this.handleBackToListOrder}>Kembali</button>
                        </div>
                        <div className="buttons is-right">
                            {
                                order.status === 10 || order.status === 20 ?

                                    <>
                                        <button className={"button h-button is-large is-danger " + (this.state.isLoadingCancelOrder ? 'is-loading' : '')} onClick={this.handleCancelOrder}>Cancel</button>
                                        <button className={"button h-button is-large is-primary is-raised is-elevated"} onClick={this.showDialogPayManualOrder}>Bayar Manual</button>
                                    </>
                                    :
                                    <></>
                            }

                        </div>
                    </div>
                    {
                        this.state.isShowPayManualDialog ?
                            (
                                <DialogPayManual handleClose={this.hideDialogPayManualOrder} order={order} />
                            )
                            :
                            (<></>)
                    }
                </div>
                :
                <div className="h-loader-wrapper">
                    <div className="loader is-large is-loading"></div>
                </div>
        );
    }
}

export default DetailOrder;