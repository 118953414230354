import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import AppConst from '../../../lib/appconst';
import ProtectedModuleRoute from '../../Router/ProtectedModuleRouter';
import MainReport from './MainReport';

class ReportPage extends Component {
    render() {
        return (
            <Switch>
                <ProtectedModuleRoute exact path="/admin/reports" component={MainReport} />
            </Switch>
        )
    }
};

export default ReportPage;