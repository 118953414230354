import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { SignInOidc } from "../Authentications/SignInOidc";
import { Logout } from "../Authentications/LogoutOidc";
import { LogoutCallback } from "../Authentications/logoutCallback";
import { SilentRenew } from "../Authentications/silentRenew";
import Product from '../Products/List';
import ClientManagementScene from '../ClientManagement/ClientManagementScene';
import AdminOrderPage from '../Admin/Orders/AdminOrderPage';
import AdminSubscriptionPage from '../Admin/Subscriptions/SubscriptionPage';
import AdminReportPage from '../Admin/Reports/ReportPage';
import ProductPage from '../Admin/Products/ProductPage';
import ProductTypePage from '../Admin/ProductTypes/ProductTypePage';
import SettingPage from '../Settings/SettingPage';
import SubscriptionPage from '../Subscriptions/SubscriptionPage';
import UserProductPage from '../Products/ProductPage';
import OrderPage from '../Orders/OrderPage';
import CheckoutPage from '../Checkout/CheckoutPage';
import AuthenticatedRouter from './AuthenticatedRouter';

export const Router = withRouter(({ Layout, history }) => {
    //const lastLocation = useLastLocation();
    //routerHelpers.saveLastLocation(lastLocation);
    //const { isAuthorized, userLastLocation } = useSelector(
    //    ({ auth, urls }) => ({
    //        isAuthorized: auth.authToken != null && auth.user != null && auth.user.isActive === true,
    //        userLastLocation: routerHelpers.getLastLocation()
    //    }),
    //    shallowEqual
    //);

    return (
        <Switch>
            <Layout>
                <Route exact path="/signin-oidc" component={SignInOidc} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/logout/callback" component={LogoutCallback} />
                <Route exact path="/silentrenew" component={SilentRenew} />

                <AuthenticatedRouter exact path='/' component={Product} />
                <ClientManagementScene />
                <AdminOrderPage />
                <AdminReportPage />
                <AdminSubscriptionPage />
                <SettingPage />
                <SubscriptionPage />
                <ProductPage />
                <ProductTypePage />
                <UserProductPage />
                <OrderPage />
                <CheckoutPage />
            </Layout>
        </Switch>
    );
});