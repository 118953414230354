import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import LineIcon from 'react-lineicons';
import AuthUtil from '../../../utils/auth-utils';
import ClientService from '../../../services/clients/clientService';
import SubscriptionService from '../../../services/subscription/subscriptionService';
import { generateShortString } from '../../../utils/string-utils';
import DialogEditSubscription from './dialogEditSubscription';
import './InformationClientManagement.scss';

class InformationClient extends Component {
    constructor() {
        super();
        this.syncData = this.syncData.bind(this);
        this.handleNavigate = this.handleNavigate.bind(this);
        this.handleRedirectToOrder = this.handleRedirectToOrder.bind(this);
        this.handleShowDialogEditSubscription = this.handleShowDialogEditSubscription.bind(this);
        this.closeDialogEditSubscription = this.closeDialogEditSubscription.bind(this);
        this.state = {
            id: null,
            isLoading: true,
            client: null,
            subscriptions: [],
            isShowEditSubscription: false,
            editSubscription: null
        };
    }

    componentDidMount() {
        var clientId = this.props.match.params.id;
        this.setState({ id: clientId }, () => {
            this.syncData();
        });
    }

    syncData() {
        const clientId = this.state.id;
        this.setState({ isLoading: true });
        ClientService.GetSimpleClient(clientId).then(res => {
            this.setState({ client: res.data.result }, () => {
                SubscriptionService.GetAllByUser(this.state.id).then(res => {
                    this.setState({ subscriptions: res.data.result, isLoading: false });
                });
            });
        });
    }

    handleNavigate(ev) {
        ev.preventDefault();
        this.props.history.push(ev.currentTarget.getAttribute('href'));
    }

    handleRedirectToOrder(orderId) {
        this.props.history.push('/admin/orders/' + orderId);
    }

    handleShowDialogEditSubscription(subscription) {
        this.setState({ editSubscription: subscription, isShowEditSubscription: true });
    }

    closeDialogEditSubscription() {
        this.setState({ isShowEditSubscription: false });
    }

    render() {
        const { client, subscriptions } = this.state;
        if (this.state.isLoading)
            return (<div>Loading...</div>);

        return (
            <div className="profile-wrapper is-client">
                <div className="profile-header has-text-centered">
                    <div className="h-avatar is-xl">
                        <span className={"avatar is-fake is-primary is-squared h-avatar has-dot " + (client.isActive ? '' : 'dot-danger')}>
                            <span>{generateShortString(client.fullName)}</span>
                        </span>
                    </div>
                    <h3 className="title is-4 is-narrow is-thin">{client.fullName}</h3>
                </div>

                <div className="profile-body">
                    <div className="columns">
                        <div className="column is-8">
                            <div className="profile-card">
                                <div className="profile-card-section">
                                    <div className="section-title">
                                        <h4>Informasi Personal</h4>
                                    </div>
                                    <div className="section-content">
                                        <div className="languages-wrapper">
                                            <div className="languages-item">
                                                <div className="meta">
                                                    <span className="dark-inverted">{client.fullName}</span>
                                                    <span>Nama</span>
                                                </div>
                                            </div>
                                            <div className="languages-item">
                                                <div className="meta">
                                                    <span className="dark-inverted">{client.userName}</span>
                                                    <span>Ruang Trader Store ID</span>
                                                </div>
                                            </div>
                                            <div className="languages-item">
                                                <div className="meta">
                                                    <span className="dark-inverted">{client.emailAddress || '-'}</span>
                                                    <span>Email</span>
                                                </div>
                                            </div>
                                            <div className="languages-item">
                                                <div className="meta">
                                                    <span className="dark-inverted">{client.phoneNumber || '-'}</span>
                                                    <span>Nomor Whatsapp / handphone</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="profile-card">
                                <div className="profile-card-section no-padding">
                                    <div className="section-title">
                                        <h4>Subscriptions</h4>
                                    </div>
                                    <div className="section-content">
                                        <div className="recommendations-wrapper">
                                            {
                                                subscriptions && subscriptions.length > 0 ?
                                                    subscriptions.map((subscription) =>
                                                    (
                                                        <div className="recommendations-item" key={subscription.id}>
                                                            <div className="h-avatar is-large">
                                                                <img className="avatar" src={subscription.order.product.firstProductImage.url} alt="" />
                                                            </div>
                                                            <h3 className="dark-inverted mb-0">{subscription.order.product.name}</h3>
                                                            <p className="dark-inverted mt-0">{subscription.order.productSubscriptionPlan.typeName}</p>

                                                            <div className="meta dark-inverted">
                                                                <span>Platform: {subscription.platformName} {subscription.isMustAccountHotForex ? "Hotforex" : ""}</span>
                                                            </div>
                                                            <div className="meta dark-inverted">
                                                                <span>Account: {subscription.account}</span>
                                                            </div>

                                                            <div className="dark-inverted mt-5">
                                                                <div className="mb-2">
                                                                    <span className={"light-text tag is-rounded " + (subscription.status === 10 ? "is-danger" : subscription.status === 20 ? "is-warning" : subscription.status === 30 ? "is-danger" : subscription.status === 40 ? "is-success" : "is-danger")}>{subscription.statusName}</span>
                                                                    {
                                                                        subscription.accountWarningMessage ?
                                                                            <span className="ml-2 light-text tag is-rounded hint--top is-danger" data-hint={subscription.accountWarningMessage}>Error</span>
                                                                            :
                                                                            <></>
                                                                    }
                                                                </div>
                                                                <span className="is-size-6">{moment(subscription.startDate).local().format('DD MMM YYYY') + ' - ' + (subscription.isLifetime ? "Lifetime" : moment(subscription.endDate).local().format('DD MMM YYYY'))}</span>
                                                            </div>

                                                            <div className="mt-3">
                                                                <div className="buttons">
                                                                    <button className="button h-button is-dark-outlined" onClick={() => this.handleShowDialogEditSubscription(subscription)}>
                                                                        <span>Edit</span>
                                                                    </button>
                                                                    <button className="button h-button is-dark-outlined" onClick={() => this.handleRedirectToOrder(subscription.order.id)}>
                                                                        <span>Order</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column is-4">

                            <div className="profile-card">
                                <div className="profile-card-section no-padding">
                                    <div className="section-title">
                                        <h4>Recent Logins</h4>
                                    </div>
                                    <div className="section-content">
                                        <div className="people-wrapper">
                                            <a href="#" className="people-item">
                                                <div className="meta">
                                                    <span className="dark-inverted"></span>
                                                    <span>Under Construction</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isShowEditSubscription ?
                        (
                            <DialogEditSubscription handleClose={this.closeDialogEditSubscription} subscription={this.state.editSubscription} />
                        )
                        :
                        (<></>)
                }
            </div>
        );
    }
}

export default withRouter(InformationClient);