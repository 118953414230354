import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import DetailCheckout from './Detail';

class ProductPage extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/checkout/:id" component={DetailCheckout} />
            </Switch>
        )
    }
};

export default ProductPage;