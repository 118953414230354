import React from 'react';
import ProtectedRoute from '../Router/ProtectedRouter';
import ListClientManagement from './List';
import InformationClient from './Information';

const ClientManagementScene = () => {
    return (
        <>
            <ProtectedRoute exact path="/clients" component={ListClientManagement} />
            <ProtectedRoute exact path="/clients/information/:id" component={InformationClient} />
        </>
    );
}

export default ClientManagementScene;