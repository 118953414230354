import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import AuthenticatedRouter from '../Router/AuthenticatedRouter';
import List from './List';
import Detail from './Detail';

class SubscriptionPage extends Component {
    render() {
        return (
            <Switch>
                <AuthenticatedRouter exact path="/subscriptions" component={List} />
                <AuthenticatedRouter exact path="/subscriptions/:id" component={Detail} />
            </Switch>
        )
    }
};

export default SubscriptionPage;