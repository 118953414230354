export const API_URL = window.API_URL;
export const APP_URL = window.APP_URL;
export const MAIN_API_URL = window.MAIN_API_URL;
export const MAIN_APP_URL = window.MAIN_APP_URL;
export const MIDTRANS_CLIENT_KEY = window.MIDTRANS_CLIENT_KEY;
export const MIDTRANS_SCRIPT_URL = window.MIDTRANS_SCRIPT_URL;
export const GOOGLE_ANALYTICS_TRACKING_CODE = window.GOOGLE_ANALYTICS_TRACKING_CODE;
export const URL_SUPPORT_TEAM = window.URL_SUPPORT_TEAM;

export const OIDC_AUTH_URL = window.OIDC_AUTH_URL;
export const OIDC_CLIENT_ID = window.OIDC_CLIENT_ID;
export const OIDC_REDIRECT_URL = window.OIDC_REDIRECT_URL;
export const OIDC_SILENT_REDIRECT_URL = window.OIDC_SILENT_REDIRECT_URL;
export const OIDC_LOGOFF_REDIRECT_URL = window.OIDC_LOGOFF_REDIRECT_URL;

export const HFM_IB = window.HFM_IB;
export const HFM_URL = window.HFM_URL;
export const HFM_IB_2 = window.HFM_IB_2;
export const HFM_URL_2 = window.HFM_URL_2;