import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Search } from 'react-feather';
import ClientService from '../../../services/clients/clientService';
import { generateShortString } from '../../../utils/string-utils';
import './ListClientManagement.scss';

class ListClientManagement extends Component {
    constructor() {
        super();
        this.state = {
            clients: [],
            status: 0,
            skipCount: 0,
            maxResultCount: 24,
            isLoading: false,
            searchKey: '',
            pageNo: 1,
            totalClient: 0,
            totalPage: 0
        };
        this.syncClients = this.syncClients.bind(this);
        this.handleChangeSearchKey = this.handleChangeSearchKey.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleClickPrev = this.handleClickPrev.bind(this);
        this.handleClickNext = this.handleClickNext.bind(this);
        this.handleClickCreateNewClient = this.handleClickCreateNewClient.bind(this);
        this.handleClickRow = this.handleClickRow.bind(this);
    }

    componentDidMount() {
        this.syncClients();
    }

    handleChangeSearchKey(ev) {
        var searchKey = ev.target.value;
        this.setState({ searchKey: searchKey });
    }

    handleSearch(ev) {
        ev.preventDefault();
        var searchKey = this.state.searchKey;
        this.setState({ searchKey: searchKey }, () => {
            this.syncClients();
        });
    }

    handleNagivate(ev) {
        this.props.history.push(ev.currentTarget.getAttribute('href'));
    }

    handleClickPrev() {
        if (this.state.pageNo > 1) {
            var pageNo = this.state.pageNo - 1;
            this.setState({
                pageNo: pageNo,
                skipCount: (pageNo - 1) * this.state.maxResultCount
            }, () => {
                this.syncClients();
            })
        }
    }

    handleClickNext() {
        if (this.state.pageNo < this.state.totalPage) {
            var pageNo = this.state.pageNo + 1;
            this.setState({
                pageNo: pageNo,
                skipCount: (pageNo - 1) * this.state.maxResultCount
            }, () => {
                this.syncClients();
            });
        }
    }

    syncClients() {
        this.setState({ isLoading: true });
        ClientService.GetSimpleClients(this.state.status, this.state.skipCount, this.state.maxResultCount, this.state.searchKey).then(res => {
            this.setState({
                clients: res.data.result.items,
                totalClient: res.data.result.totalCount,
                totalPage: Math.ceil(res.data.result.totalCount / this.state.maxResultCount)
            });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    handleClickCreateNewClient() {
        this.props.history.push("/clients/new");
    }

    handleClickRow(user) {
        this.props.history.push("/clients/information/" + user.id);
    }

    render() {
        const { isLoading, clients, totalPage, totalClient, pageNo } = this.state;
        return (
            <div className="tile-grid tile-grid-v2 is-usermanagement-list">
                <div className="tile-grid-toolbar">
                    <div className="control has-icon">
                        <form onSubmit={this.handleSearch}>
                            <input className="input custom-text-filter" placeholder="Search..." onChange={this.handleChangeSearchKey} />
                            <div className="form-icon">
                                <Search />
                            </div>
                        </form>
                    </div>
                </div>

                <div className={"columns is-multiline has-loader " + (isLoading ? "has-loader-active min-height" : "")}>
                    {
                        !isLoading ?
                            (
                                clients.length > 0 ?
                                    clients.map((client, index) => (
                                        <div className="column is-3" key={client.id} onClick={() => { this.handleClickRow(client) }}>
                                            <div className="tile-grid-item">
                                                <div className="tile-grid-item-inner">
                                                    <div className="h-avatar is-medium">
                                                        <span className={"avatar is-fake is-primary h-avatar has-dot " + (client.isActive ? '' : 'dot-danger')}>
                                                            <span>{generateShortString(client.fullName)}</span>
                                                        </span>
                                                    </div>
                                                    <div className="meta">
                                                        <span className="dark-inverted">{client.fullName}</span>
                                                        <span>
                                                            <span>{client.userName}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    :
                                    <div className="page-placeholder custom-text-filter-placeholder">
                                        <div className="placeholder-content">
                                            <img className="light-image" src="images/illustrations/placeholders/search-6.svg" alt="" />
                                            <img className="dark-image" src="images/illustrations/placeholders/search-6-dark.svg" alt="" />
                                            <h3>We couldn't find any matching results.</h3>
                                            <p className="is-larger">Too bad. Looks like we couldn't find any matching results for the
                                        search terms you've entered. Please try different search terms or criteria.</p>
                                        </div>
                                    </div>
                            )
                            :
                            <div className="h-loader-wrapper">
                                <div className="loader is-large is-loading"></div>
                            </div>
                    }
                </div>

                {
                    totalPage > 0 ?
                        <div className="is-clearfix">
                            <div className="buttons has-addons is-scrollable mt-2 mb-0 is-pulled-left">
                                {
                                    pageNo === 1 ? <></> : <button className={"button h-button is-raised"} onClick={this.handleClickPrev}>Prev</button>
                                }
                                {
                                    pageNo === totalPage ? <></> : <button className={"button is-primary h-button is-raised"} onClick={this.handleClickNext}>Next</button>
                                }
                            </div>
                            <div className="is-pulled-left mt-3">
                                <span className="ml-3 dark-inverted">Total Client: {totalClient}, page {pageNo} of {totalPage}</span>
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>
        );
    }
};

export default withRouter(ListClientManagement);