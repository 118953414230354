import http from "../httpService";

class ReportService {
    GetKPI() {
        let result = http.get('/reports/kpi');
        return result;
    }

    DownloadExcelOrders(status, monthYear) {
        var downloadLink = "/reports/excelorders?status=" + status + "&monthYear=" + monthYear;
        let result = http.get(downloadLink, { responseType: 'blob', timeout: 300000 });
        return result;
    }
}

export default new ReportService();