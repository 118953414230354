import http from "../httpService";

class OrderService {
    Create(productId, subscriptionPlanType) {
        var data = { productId: productId, subscriptionPlanType: subscriptionPlanType };
        let result = http.post('/orders', data, { customErrorHandler: true });
        return result;
    }

    CancelOrder(orderId) {
        let result = http.put('/orders/cancel', { orderId: orderId }, { customErrorHandler: true });
        return result;
    }

    Get(orderId) {
        let result = http.get('/orders/' + orderId);
        return result;
    }

    GetAll(status, searchKey, skipCount, maxResultCount) {
        let result = http.get('/orders?status=' + status + '&searchKey=' + searchKey + '&skipCount=' + skipCount + '&maxResultCount=' + maxResultCount);
        return result;
    }

    GetCurrentUserOrders(status, searchKey, skipCount, maxResultCount) {
        let result = http.get('/orders/currentuser?status=' + status + '&searchKey=' + searchKey + '&skipCount=' + skipCount + '&maxResultCount=' + maxResultCount);
        return result;
    }

    GenerateTokenMidtrans(orderId) {
        let result = http.post('/orders/TokenMidtrans', { id: orderId });
        return result;
    }

    PayManual(orderId, bankName, bankAccountNumber, bankAccountName, ruangTraderBankAccountId, screenshot, amount, isFullPayment) {
        let formData = new FormData();
        formData.append("id", orderId);
        formData.append("ruangTraderBankAccountId", ruangTraderBankAccountId);
        formData.append("bankName", bankName);
        formData.append("bankAccountName", bankAccountName);
        formData.append("bankAccountNumber", bankAccountNumber);
        formData.append("screenshot", screenshot);
        formData.append("amount", amount);
        formData.append("isFullPayment", isFullPayment);

        var result = http.post("/orders/paymanual", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        return result;
    }
}

export default new OrderService();