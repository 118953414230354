import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CurrentLoggedInProfileCard from '../../../components/CurrentLoggedInProfileCard';
import LineIcon from 'react-lineicons';

class SettingMainPage extends Component {
    constructor() {
        super();
        this.handleNagivate = this.handleNagivate.bind(this);
    }

    handleNagivate(ev) {
        ev.preventDefault();
        this.props.history.push(ev.currentTarget.getAttribute('href'));
    }

    render() {
        return (
            <div className="profile-wrapper">
                <CurrentLoggedInProfileCard />
                <div className="profile-body">
                    <div className="settings-section">
                        <a className="settings-box" href="/traderaccounts" onClick={this.handleNagivate}>
                            <div className="edit-icon">
                                <LineIcon name='pencil' />
                            </div>
                            <div className="icon-wrap">
                                <LineIcon name='tag' />
                            </div>
                            <span>Trader Account</span>
                            <h3>Pengaturan Trader Account</h3>
                        </a>
                        <a className="settings-box" href="/subscriptions" onClick={this.handleNagivate}>
                            <div className="edit-icon">
                                <LineIcon name='pencil' />
                            </div>
                            <div className="icon-wrap">
                                <LineIcon name='credit-cards' />
                            </div>
                            <span>Pembayaran</span>
                            <h3>Berlangganan dan Pembayaran</h3>
                        </a>
                        <a className="settings-box" href="/accountsettings/telegramnotification" onClick={this.handleNagivate}>
                            <div className="edit-icon">
                                <LineIcon name='pencil' />
                            </div>
                            <div className="icon-wrap">
                                <LineIcon name='telegram' />
                            </div>
                            <span>Notifikasi</span>
                            <h3>Pengaturan Notifikasi</h3>
                        </a>
                        <a className="settings-box" href="/accountsettings/personal" onClick={this.handleNagivate}>
                            <div className="edit-icon">
                                <LineIcon name='pencil' />
                            </div>
                            <div className="icon-wrap">
                                <LineIcon name='cog' />
                            </div>
                            <span>Akun</span>
                            <h3>Pengaturan Akun</h3>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SettingMainPage);