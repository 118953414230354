import React, { Component } from 'react';

class InlineErrorMessage extends Component {
    render() {
        return (
            <>
                {
                    typeof this.props.errorMessages === "string" ?
                        (
                            <div><small className="error-text">{this.props.errorMessages}</small></div>
                        )
                        :
                        this.props.errorMessages.map((message, index) => (
                            <div><small className="error-text" key={index}>{message}</small></div>
                        ))
                }
            </>
        );
    }
}

export default InlineErrorMessage;