import http from "../httpService";

class ProductService {
    Create(data) {
        let result = http.post('/products', data, { headers: { 'content-type': 'multipart/form-data' } });
        return result;
    }

    Update(data) {
        let result = http.put('/products', data, { headers: { 'content-type': 'multipart/form-data' } });
        return result;
    }

    DeActivate(productId) {
        let result = http.put('/products/' + productId + '/deactivate');
        return result;
    }

    Activate(productId) {
        let result = http.put('/products/' + productId + '/activate');
        return result;
    }

    GetAllActive() {
        let result = http.get('/products/active');
        return result;
    }

    GetAll() {
        let result = http.get('/products/all');
        return result;
    }

    Get(productId) {
        let result = http.get('/products/' + productId);
        return result;
    }

    GetType(id) {
        let result = http.get('/products/types/' + id);
        return result;
    }

    GetTypes() {
        let result = http.get('/products/types');
        return result;
    }

    GetPipelines() {
        let result = http.get('/products/pipelines');
        return result;
    }

    AddProductType(name, pipelineId) {
        let result = http.post('/products/types', { name: name, pipelineId: pipelineId });
        return result;
    }

    EditProductType(id, name, pipelineId) {
        let result = http.put('/products/types', { id: id, name: name, pipelineId: pipelineId });
        return result;
    }
}

export default new ProductService();