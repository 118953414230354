import React, { Component } from 'react';
import { withRouter } from 'react-router';
import SubscriptionService from '../../../services/subscription/subscriptionService';
import moment from 'moment';
import "./listSubscription.scss";

class ListSubscription extends Component {
    constructor() {
        super();
        this.state = {
            subscriptions: [],
            isLoading: true,
            status: 0,
            searchKey: '',
            skipCount: 0,
            maxResultCount: 1000
        }
        this.syncSubscription = this.syncSubscription.bind(this);
        this.handleNavigate = this.handleNavigate.bind(this);
    }

    componentDidMount() {
        this.syncSubscription();
    }

    syncSubscription() {
        this.setState({ isLoading: true });
        SubscriptionService.GetSubscriptionCurrentUser(this.state.status, this.state.searchKey, this.state.skipCount, this.state.maxResultCount).then(res => {
            this.setState({ subscriptions: res.data.result.items });
        }).finally(res => {
            this.setState({ isLoading: false });
        });
    }

    handleNavigate(ev) {
        ev.preventDefault();
        this.props.history.push(ev.currentTarget.getAttribute('href'));
    }

    render() {
        const { isLoading, subscriptions } = this.state;

        return (
            <div className="page-content-inner is-subscription">
                <div className={"flex-list-wrapper flex-list-v3 has-loader " + (isLoading ? "has-loader-active min-height" : "")}>
                    {
                        !isLoading ?
                            subscriptions.length > 0 ?
                                (
                                    <div id="active-items-tab" className="tab-content is-active">
                                        <div className="flex-table">
                                            <div className="flex-table-header" data-filter-hide>
                                                <span className="is-grow-lg">Produk</span>
                                                <span>Platform</span>
                                                <span>Account</span>
                                                <span>Berlaku Sejak</span>
                                                <span>Berlaku Sampai</span>
                                                <span>Status</span>
                                                <span className="cell-end"></span>
                                            </div>

                                            {
                                                subscriptions.map(subscription =>
                                                    <div className="flex-list-inner" key={subscription.id}>
                                                        <div className="flex-table-item">
                                                            <div className="flex-table-cell is-media is-grow-lg">
                                                                <img className="media" src={subscription.order.product.firstProductImage.url} alt="" />
                                                                <div>
                                                                    <span className="item-name dark-inverted" data-filter-match>{subscription.order.product.name}</span>
                                                                    <div className="item-meta">
                                                                        <div className="flex-media">
                                                                            <div className="meta ml-0">
                                                                                <span>{subscription.order.productSubscriptionPlan.typeName}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-table-cell" data-th="Platform">
                                                                <span className="light-text" data-filter-match>{subscription.platformName} {subscription.isMustAccountHotForex ? "Hotforex" : (subscription.isMustAccountHotForexPremium) ? "Hotforex Premium" : (subscription.isMustAccountHotForexCent) ? "Hotforex Cent" : ""}</span>
                                                            </div>
                                                            <div className="flex-table-cell" data-th="Account">
                                                                <i className="lnil lnil-html5 cell-icon is-pushed-mobile"></i>
                                                                <span className="light-text" data-filter-match>{subscription.account}</span>
                                                            </div>
                                                            <div className="flex-table-cell" data-th="Berlaku Sejak">
                                                                <span className="light-text" data-filter-match>{moment(subscription.startDate).local().format('DD MMM YYYY')}</span>
                                                            </div>
                                                            <div className="flex-table-cell" data-th="Berlaku Sampai">
                                                                <span className="light-text" data-filter-match>{subscription.isLifetime ? "Lifetime" : moment(subscription.endDate).local().format('DD MMM YYYY')}</span>
                                                            </div>
                                                            <div className="flex-table-cell" data-th="Status">
                                                                <span className={"light-text tag is-rounded " + (subscription.status === 10 ? "is-danger" : subscription.status === 20 ? "is-warning" : subscription.status === 30 ? "is-danger" : subscription.status === 40 ? "is-success" : "is-danger")}>{subscription.statusName}</span>
                                                                {
                                                                    subscription.accountWarningMessage ?
                                                                        <span className="ml-2 light-text tag is-rounded hint--top is-danger" data-hint={subscription.accountWarningMessage + ", silahkan hubungi team support"}>Pesan Error</span>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>
                                                            <div className="flex-table-cell cell-end" data-th="Actions">
                                                                <a className="button h-button has-dot dark-outlined is-pushed-mobile" href={"/subscriptions/" + subscription.id} onClick={this.handleNavigate}>Detail</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                                :
                                <div className="page-placeholder custom-text-filter-placeholder">
                                    <div className="placeholder-content">
                                        <img className="light-image" src="images/illustrations/placeholders/search-4.svg" alt="" />
                                        <img className="dark-image" src="images/illustrations/placeholders/search-4-dark.svg" alt="" />
                                        <h3>Anda belum mempunyai paket berlangganan.</h3>
                                        <p className="is-larger">Maaf, Anda belum mempunyai paket berlangganan apapun, silahkan berlangganan produk-produk dari ruang trader.</p>
                                    </div>
                                </div>
                            :
                            <div className="h-loader-wrapper">
                                <div className="loader is-large is-loading"></div>
                            </div>
                    }

                </div>

            </div>

        );
    }
}

export default withRouter(ListSubscription);