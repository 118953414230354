import React from 'react';
import { Route } from 'react-router-dom';
import AuthUtil from '../../utils/auth-utils';
import NotAuthorize from './NotAuthorize';
import { AuthConsumer } from "../../providers/authProvider";

const ProtectedRoute = ({ path, component, exact, ...props }) => {
    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({ isAuthenticated }) => {
                if (!!Component && isAuthenticated() && AuthUtil.IsCurrentUserCanAccessClientManagement()) {
                    return <Route exact={exact} path={path} component={component} {...props} />;
                } else {
                    return <Route exact={exact} path={path} component={NotAuthorize} {...props} />;
                }
            }}
        </AuthConsumer>
    );

    return <Route {...props} exact={exact} path={path} render={renderFn(component)} />;
};

export default ProtectedRoute;