import React, { Component } from 'react';
import { User, DollarSign, File, Link, Globe } from 'react-feather';
import InlineErrorMessage from '../../../../components/InlineErrorMessage';
import Dropzone from "../../../../components/dropzone";
import productService from "../../../../services/products/productService";
import subscriptionService from "../../../../services/subscription/subscriptionService";
import { withRouter } from 'react-router';
import AppConsts from '../../../../lib/appconst';

class EditProduct extends Component {
    constructor() {
        super();
        this.state = {
            id: '',
            name: '',
            nameErrorMessage: '',
            author: '',
            authorErrorMessage: '',
            description: '',
            descriptionErrorMessage: '',
            detailDescription: '',
            productType: '',
            status: '',
            statusName: '',
            isNeedMetatrader4Account: false,
            isNeedMetatrader4HFAccount: false,
            isNeedMetatrader4HFPremiumAccount: false,
            isNeedMetatrader4HFCentAccount: false,
            isNeedTradingViewAccount: false,
            tradingViewIndicatorName: '',
            tradingViewIndicatorNameErrorMessage: '',
            metatraderIndicatorName: '',
            metatraderIndicatorNameErrorMessage: '',
            isEnableMonthlyPlan: false,
            priceMonthlyPlan: 0,
            isEnableYearlyPlan: false,
            priceYearlyPlan: 0,
            isEnableLifetimePlan: false,
            priceLifetimePlan: 0,
            file: null,
            fileName: '',
            urlVideoInstruction: '',
            urlGuideBook: '',
            version: '',
            images: [],
            imagesData: [],
            imagesUrls: [],
            productTypes: [],
            deletedImages: [],
            requiredProductTypes: [],
            isLoading: false,
            isLoadingDeActivate: false,
            isLoadingActivate: false
        };

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeAuthor = this.handleChangeAuthor.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.handleChangeDetailDescription = this.handleChangeDetailDescription.bind(this);
        this.handleChangeProductType = this.handleChangeProductType.bind(this);
        this.handleAddImage = this.handleAddImage.bind(this);
        this.handleDeleteImage = this.handleDeleteImage.bind(this);
        this.removeOriginImage = this.removeOriginImage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitDeactivate = this.handleSubmitDeactivate.bind(this);
        this.handleSubmitActivate = this.handleSubmitActivate.bind(this);
        this.handleChangeIsNeedMetatrader4Account = this.handleChangeIsNeedMetatrader4Account.bind(this);
        this.handleChangeIsNeedMetatrader4HFAccount = this.handleChangeIsNeedMetatrader4HFAccount.bind(this);
        this.handleChangeIsNeedMetatrader4HFPremiumAccount = this.handleChangeIsNeedMetatrader4HFPremiumAccount.bind(this);
        this.handleChangeIsNeedMetatrader4HFCentAccount = this.handleChangeIsNeedMetatrader4HFCentAccount.bind(this);
        this.handleChangeIsNeedTradingViewAccount = this.handleChangeIsNeedTradingViewAccount.bind(this);
        this.handleChangeTradingViewIndicatorName = this.handleChangeTradingViewIndicatorName.bind(this);
        this.handleChangeMetatraderIndicatorName = this.handleChangeMetatraderIndicatorName.bind(this);
        this.handleChangeIsEnableMonthlyPlan = this.handleChangeIsEnableMonthlyPlan.bind(this);
        this.handleChangePriceMonthlyPlan = this.handleChangePriceMonthlyPlan.bind(this);
        this.handleChangeIsEnableYearlyPlan = this.handleChangeIsEnableYearlyPlan.bind(this);
        this.handleChangePriceYearlyPlan = this.handleChangePriceYearlyPlan.bind(this);
        this.handleChangeIsEnableLifetimePlan = this.handleChangeIsEnableLifetimePlan.bind(this);
        this.handleChangePriceLifetimePlan = this.handleChangePriceLifetimePlan.bind(this);
        this.syncProductTypes = this.syncProductTypes.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.handleChangeUrlVideoInstruction = this.handleChangeUrlVideoInstruction.bind(this);
        this.handleChangeUrlGuideBook = this.handleChangeUrlGuideBook.bind(this);
        this.handleChangeVersion = this.handleChangeVersion.bind(this);
        this.handleChangeRequiredProductType = this.handleChangeRequiredProductType.bind(this);
    }

    componentDidMount() {
        this.setState({ id: this.props.match.params.id }, () => {
            this.syncProduct();
        });
    }

    syncProduct() {
        productService.Get(this.state.id)
            .then(res => {
                var requiredProductTypes = [];
                for (var i = 0; i < res.data.result.requiredProductTypes.length; i++) {
                    requiredProductTypes.push(res.data.result.requiredProductTypes[i].productType);
                }

                this.setState({
                    name: res.data.result.name,
                    description: res.data.result.description || "",
                    detailDescription: res.data.result.detailDescription || "",
                    author: res.data.result.author,
                    productType: res.data.result.productType.id,
                    price: res.data.result.price,
                    status: res.data.result.status,
                    statusName: res.data.result.statusName,
                    isNeedMetatrader4Account: res.data.result.isNeedMetatrader4Account,
                    isNeedMetatrader4HFAccount: res.data.result.isNeedMetatrader4HFAccount,
                    isNeedMetatrader4HFPremiumAccount: res.data.result.isNeedMetatrader4HFPremiumAccount,
                    isNeedMetatrader4HFCentAccount: res.data.result.isNeedMetatrader4HFCentAccount,
                    isNeedTradingViewAccount: res.data.result.isNeedTradingViewAccount,
                    tradingViewIndicatorName: res.data.result.tradingViewIndicatorName,
                    metatraderIndicatorName: res.data.result.metatraderIndicatorName,
                    isEnableMonthlyPlan: res.data.result.plans.find(i => i.type == 30).isActive,
                    priceMonthlyPlan: res.data.result.plans.find(i => i.type == 30).price,
                    isEnableYearlyPlan: res.data.result.plans.find(i => i.type == 365).isActive,
                    priceYearlyPlan: res.data.result.plans.find(i => i.type == 365).price,
                    isEnableLifetimePlan: res.data.result.plans.find(i => i.type == 999).isActive,
                    priceLifetimePlan: res.data.result.plans.find(i => i.type == 999).price,
                    fileName: res.data.result.fileName,
                    urlVideoInstruction: res.data.result.urlVideoInstruction || "",
                    urlGuideBook: res.data.result.urlGuideBook || "",
                    version: res.data.result.version || "",
                    requiredProductTypes: requiredProductTypes
                }, () => {
                    this.syncProductTypes();
                });

                var imagesUrls = [];
                for (const image of res.data.result.images) {
                    imagesUrls.push({ priority: image.priority, url: image.url });
                }
                this.setState({
                    imagesUrls: imagesUrls
                });
            });
    }

    syncProductTypes() {
        productService.GetTypes().then(res => {
            this.setState({ productTypes: res.data.result });
        });
    }

    removeOriginImage(priority, ev) {
        ev.preventDefault();
        var deletedImages = this.state.deletedImages;
        deletedImages.push(priority);
        this.setState({
            imagesUrls: this.state.imagesUrls.filter(function (imageUrl) {
                return imageUrl.priority !== priority
            }),
            deletedImages: deletedImages
        });
    }

    handleChangeName(ev) {
        var name = ev.currentTarget.value;
        this.setState({ name: name, nameErrorMessage: '' });
    }

    handleChangeAuthor(ev) {
        var name = ev.currentTarget.value;
        this.setState({ author: name, authorErrorMessage: '' });
    }

    handleChangeDescription(ev) {
        var description = ev.currentTarget.value;
        this.setState({ description: description, descriptionErrorMessage: '' });
    }

    handleChangeDetailDescription(ev) {
        var description = ev.currentTarget.value;
        this.setState({ detailDescription: description });
    }

    handleChangeProductType(ev) {
        this.setState({ productType: ev.currentTarget.value });
    }

    handleChangeIsNeedMetatrader4Account() {
        this.setState({
            isNeedMetatrader4Account: !this.state.isNeedMetatrader4Account,
            metatraderIndicatorName: (!this.state.isNeedMetatrader4Account && !this.state.isNeedMetatrader4HFAccount && !this.state.isNeedMetatrader4HFPremiumAccount && !this.state.isNeedMetatrader4HFCentAccount) ? "" : this.state.metatraderIndicatorName
        });
    }

    handleChangeIsNeedMetatrader4HFAccount() {
        this.setState({
            isNeedMetatrader4HFAccount: !this.state.isNeedMetatrader4HFAccount,
            metatraderIndicatorName: (!this.state.isNeedMetatrader4Account && !this.state.isNeedMetatrader4HFAccount && !this.state.isNeedMetatrader4HFPremiumAccount && !this.state.isNeedMetatrader4HFCentAccount) ? "" : this.state.metatraderIndicatorName
        });
    }

    handleChangeIsNeedMetatrader4HFPremiumAccount() {
        this.setState({
            isNeedMetatrader4HFPremiumAccount: !this.state.isNeedMetatrader4HFPremiumAccount,
            metatraderIndicatorName: (!this.state.isNeedMetatrader4Account && !this.state.isNeedMetatrader4HFAccount && !this.state.isNeedMetatrader4HFPremiumAccount && !this.state.isNeedMetatrader4HFCentAccount) ? "" : this.state.metatraderIndicatorName
        });
    }

    handleChangeIsNeedMetatrader4HFCentAccount() {
        this.setState({
            isNeedMetatrader4HFCentAccount: !this.state.isNeedMetatrader4HFCentAccount,
            metatraderIndicatorName: (!this.state.isNeedMetatrader4Account && !this.state.isNeedMetatrader4HFAccount && !this.state.isNeedMetatrader4HFPremiumAccount && !this.state.isNeedMetatrader4HFCentAccount) ? "" : this.state.metatraderIndicatorName
        });
    }

    handleChangeIsNeedTradingViewAccount() {
        this.setState({
            isNeedTradingViewAccount: !this.state.isNeedTradingViewAccount,
            tradingViewIndicatorName: (this.state.isNeedTradingViewAccount) ? "" : this.state.tradingViewIndicatorName
        });
    }

    handleChangeTradingViewIndicatorName(ev) {
        this.setState({ tradingViewIndicatorName: ev.currentTarget.value });
    }

    handleChangeMetatraderIndicatorName(ev) {
        this.setState({ metatraderIndicatorName: ev.currentTarget.value });
    }

    handleChangeUrlVideoInstruction(ev) {
        this.setState({ urlVideoInstruction: ev.currentTarget.value });
    }

    handleChangeUrlGuideBook(ev) {
        this.setState({ urlGuideBook: ev.currentTarget.value });
    }

    handleChangeVersion(ev) {
        debugger;
        this.setState({ version: ev.currentTarget.value });
    }

    handleChangeFile(ev) {
        const files = ev.target.files[0];
        this.setState({ file: files });
    }

    handleAddImage(files, data) {
        var existImages = this.state.images;
        var existImage = existImages.find(i => i.priority === data.priority);
        if (existImage) {
            existImage.image = files[0]
        } else {
            existImages.push({
                priority: data.priority,
                image: files[0]
            });
        }

        this.setState({ images: existImages });

        var self = this;
        var reader = new FileReader();
        var imagesData = this.state.imagesData;
        reader.onload = (function (aImg) {
            imagesData.push({ priority: data.priority, data: aImg.target.result });
            self.setState({
                imagesData: imagesData
            });
        });

        reader.readAsDataURL(files[0]);
    }

    handleDeleteImage(priority, ev) {
        ev.preventDefault();

        this.setState({
            images: this.state.images.filter(function (image) {
                return image.priority !== priority
            }),
            imagesData: this.state.imagesData.filter(function (imageData) {
                return imageData.priority !== priority
            })
        });
    }

    handleChangeRequiredProductType(productType) {
        var requiredProductTypes = this.state.requiredProductTypes;
        var index = requiredProductTypes.findIndex(i => i.id == productType.id);
        if (index >= 0) {
            requiredProductTypes = requiredProductTypes.filter(i => i.id !== productType.id);
        } else {
            requiredProductTypes.push(productType);
        }
        this.setState({ requiredProductTypes: requiredProductTypes });
    }

    handleSubmit(ev) {
        ev.preventDefault();

        var isError = false;
        if (this.state.name.length < 5) {
            this.setState({ nameErrorMessage: 'Nama Produk harus diisi minimal 5 karakter' });
            isError = true;
        }
        if (!this.state.author) {
            this.setState({ authorErrorMessage: 'Author Produk harus diisi' });
            isError = true;
        }
        if (this.state.description.length < 20) {
            this.setState({ descriptionErrorMessage: 'Deskripsi Produk harus diisi minimal 20 karakter' });
            isError = true;
        }
        if (this.state.price <= 0) {
            this.setState({ priceErrorMessage: 'Harga Produk harus lebih besar dari 0' });
            isError = true;
        }
        if (this.state.isNeedTradingViewAccount && !this.state.tradingViewIndicatorName) {
            this.setState({ tradingViewIndicatorNameErrorMessage: 'Nama Indikator Trading View harus diisi.' });
            isError = true;
        }
        if ((this.state.isNeedMetatrader4Account || this.state.isNeedMetatrader4HFAccount || this.state.isNeedMetatrader4HFPremiumAccount || this.state.isNeedMetatrader4HFCentAccount) && !this.state.metatraderIndicatorName) {
            this.setState({ metatraderIndicatorNameErrorMessage: 'Nama Indikator Metatrader harus diisi.' });
            isError = true;
        }

        if (!isError) {
            this.setState({ isLoading: true });
            const formData = new FormData();
            formData.append('id', this.state.id);
            formData.append('name', this.state.name);
            formData.append('author', this.state.author);
            formData.append('description', this.state.description);
            formData.append('detailDescription', this.state.detailDescription);
            formData.append('productTypeId', this.state.productType);
            formData.append('isNeedMetatrader4Account', this.state.isNeedMetatrader4Account);
            formData.append('isNeedMetatrader4HFAccount', this.state.isNeedMetatrader4HFAccount);
            formData.append('isNeedMetatrader4HFPremiumAccount', this.state.isNeedMetatrader4HFPremiumAccount);
            formData.append('isNeedMetatrader4HFCentAccount', this.state.isNeedMetatrader4HFCentAccount);
            formData.append('isNeedTradingViewAccount', this.state.isNeedTradingViewAccount);
            formData.append('tradingViewIndicatorName', this.state.tradingViewIndicatorName);
            formData.append('metatraderIndicatorName', this.state.metatraderIndicatorName);

            formData.append('file', this.state.file);
            formData.append('urlVideoInstruction', this.state.urlVideoInstruction || "");
            formData.append('urlGuideBook', this.state.urlGuideBook);
            formData.append('version', this.state.version);

            formData.append('plans[0].type', 30);
            formData.append('plans[0].price', this.state.priceMonthlyPlan);
            formData.append('plans[0].isActive', this.state.isEnableMonthlyPlan);

            formData.append('plans[1].type', 365);
            formData.append('plans[1].price', this.state.priceYearlyPlan);
            formData.append('plans[1].isActive', this.state.isEnableYearlyPlan);

            formData.append('plans[2].type', 999);
            formData.append('plans[2].price', this.state.priceLifetimePlan);
            formData.append('plans[2].isActive', this.state.isEnableLifetimePlan);


            for (var i = 0; i < this.state.deletedImages.length; i++) {
                formData.append('deletedImagesByPriority', this.state.deletedImages[i]);
            }

            for (var i = 0; i < this.state.images.length; i++) {
                formData.append('images[' + i + '].priority', this.state.images[i].priority);
                formData.append('images[' + i + '].image', this.state.images[i].image);
            }

            for (var i = 0; i < this.state.requiredProductTypes.length; i++) {
                formData.append('requiredProductTypes[' + i + '].productTypeId', this.state.requiredProductTypes[i].id);
            }

            productService.Update(formData).then(res => {
                this.props.history.push('/admin/products');
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        }
    }

    handleSubmitDeactivate(ev) {
        ev.preventDefault();
        this.setState({ isLoadingDeActivate: true });
        productService.DeActivate(this.state.id).then(res => {
            window.location.reload();
        }).finally(() => {
            this.setState({ isLoadingDeActivate: false });
        });
    }

    handleSubmitActivate(ev) {
        ev.preventDefault();
        this.setState({ isLoadingActivate: true });
        productService.Activate(this.state.id).then(res => {
            window.location.reload();
        }).finally(() => {
            this.setState({ isLoadingActivate: false });
        });
    }

    handleChangeIsEnableMonthlyPlan() {
        this.setState({ isEnableMonthlyPlan: !this.state.isEnableMonthlyPlan });
    }

    handleChangePriceMonthlyPlan(ev) {
        this.setState({ priceMonthlyPlan: ev.currentTarget.value });
    }

    handleChangeIsEnableYearlyPlan() {
        this.setState({ isEnableYearlyPlan: !this.state.isEnableYearlyPlan });
    }

    handleChangePriceYearlyPlan(ev) {
        this.setState({ priceYearlyPlan: ev.currentTarget.value });
    }

    handleChangeIsEnableLifetimePlan() {
        this.setState({ isEnableLifetimePlan: !this.state.isEnableLifetimePlan });
    }

    handleChangePriceLifetimePlan(ev) {
        this.setState({ priceLifetimePlan: ev.currentTarget.value });
    }

    render() {
        var dropzoneImages = [];
        for (let i = 1; i <= 2; i++) {
            let content = "";
            let imageData = this.state.imagesData.find(index => index.priority === i);
            let imageUrl = this.state.imagesUrls.find(index => index.priority === i);
            if (imageData) {
                content =
                    <div key={imageData.id} className="column is-6  mb-3">
                        <img src={imageData.data}
                            alt="Upload Gambar Produk"
                            className="margin-center"
                            style={{ width: "auto", height: "auto", maxHeight: "100px", margin: "0 auto", display: "block" }} />
                        <div className="mt-2 hand has-text-centered is-underlined">
                            <a onClick={this.handleDeleteImage.bind(this, imageData.priority)}>Hapus gambar</a>
                        </div>
                    </div>
            }
            else if (imageUrl) {
                content =
                    <div key={imageUrl.priority} className="column is-6 mb-3">
                        <img src={imageUrl.url}
                            alt="Upload Gambar Produk"
                            className="margin-center"
                            style={{ width: "auto", height: "auto", maxHeight: "100px", margin: "0 auto", display: "block" }} />
                        <div className="mt-2 hand has-text-centered is-underlined" onClick={this.removeOriginImage.bind(this, imageUrl.priority)}>
                            <a>Hapus gambar</a>
                        </div>
                    </div>
            } else {
                content =
                    <div key={i} className="column is-6 mb-3">
                        <Dropzone onFilesAdded={this.handleAddImage} data={{ priority: i }} />
                    </div>
            }
            dropzoneImages.push(content);
        }

        return (
            <div className="form-layout is-separate create-product">
                <div className="form-outer">
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-body">
                            <div className="form-section">
                                <div className="form-section-inner">
                                    <h3 className="has-text-centered">Edit Informasi Produk</h3>
                                    <div className="columns is-multiline">
                                        <div className="column is-12">
                                            <div className="field">
                                                <label>Nama produk</label>
                                                <div className="control has-icon">
                                                    <input type="text" className="input" placeholder="" value={this.state.name} onChange={this.handleChangeName} />
                                                    <div className="form-icon">
                                                        <User />
                                                    </div>
                                                    <InlineErrorMessage errorMessages={this.state.nameErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column is-12">
                                            <div className="field">
                                                <label>Deskripsi</label>
                                                <div className="control has-icon">
                                                    <textarea className="textarea" rows="3" placeholder="" value={this.state.description} onChange={this.handleChangeDescription} >
                                                    </textarea>
                                                    <InlineErrorMessage errorMessages={this.state.descriptionErrorMessage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column is-12">
                                            <div className="field">
                                                <label>Detail Deskripsi</label>
                                                <div className="control has-icon">
                                                    <textarea className="textarea" rows="5" placeholder="" value={this.state.detailDescription} onChange={this.handleChangeDetailDescription} >
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column is-12">
                                            <div className="field">
                                                <label>Tipe Produk</label>
                                                <div className="control has-icon">
                                                    <div className="select is-fullwidth">
                                                        <select value={this.state.productType} onChange={this.handleChangeProductType}>
                                                            {
                                                                this.state.productTypes.length > 0 ?
                                                                    this.state.productTypes.map(type =>
                                                                        <option value={type.id} key={type.id} >{type.name}</option>
                                                                    )
                                                                    :
                                                                    <option value='0'>No Data</option>
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column is-12">
                                            <div className="field">
                                                <label>Author</label>
                                                <div className="control has-icon">
                                                    <input type="text" className="input" value={this.state.author} placeholder="" onChange={this.handleChangeAuthor} />
                                                    <div className="form-icon">
                                                        <User />
                                                    </div>
                                                    <InlineErrorMessage errorMessages={this.state.authorErrorMessage} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column is-6">
                                            <div className="field">
                                                <label>MT4 Semua Broker</label>
                                                <div className="control">
                                                    <div className="control">
                                                        <label className="form-switch is-primary">
                                                            <input type="checkbox" className="is-switch" checked={this.state.isNeedMetatrader4Account} onChange={this.handleChangeIsNeedMetatrader4Account} />
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column is-6">
                                            <div className="field">
                                                <label>MT4 Hotforex Semua Tipe</label>
                                                <div className="control">
                                                    <div className="control">
                                                        <label className="form-switch is-primary">
                                                            <input type="checkbox" className="is-switch" checked={this.state.isNeedMetatrader4HFAccount} onChange={this.handleChangeIsNeedMetatrader4HFAccount} />
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column is-6">
                                            <div className="field">
                                                <label>MT4 Hotforex Premium</label>
                                                <div className="control">
                                                    <div className="control">
                                                        <label className="form-switch is-primary">
                                                            <input type="checkbox" className="is-switch" checked={this.state.isNeedMetatrader4HFPremiumAccount} onChange={this.handleChangeIsNeedMetatrader4HFPremiumAccount} />
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column is-6">
                                            <div className="field">
                                                <label>MT4 Hotforex Cent</label>
                                                <div className="control">
                                                    <div className="control">
                                                        <label className="form-switch is-primary">
                                                            <input type="checkbox" className="is-switch" checked={this.state.isNeedMetatrader4HFCentAccount} onChange={this.handleChangeIsNeedMetatrader4HFCentAccount} />
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column is-6">
                                            <div className="field">
                                                <label>Tradingview</label>
                                                <div className="control">
                                                    <div className="control">
                                                        <label className="form-switch is-primary">
                                                            <input type="checkbox" className="is-switch" checked={this.state.isNeedTradingViewAccount} onChange={this.handleChangeIsNeedTradingViewAccount} />
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column is-6">
                                            {
                                                this.state.isNeedMetatrader4Account || this.state.isNeedMetatrader4HFAccount || this.state.isNeedMetatrader4HFPremiumAccount|| this.state.isNeedMetatrader4HFCentAccount ?
                                                    <div className="field">
                                                        <label>Nama Indicator Metatrader</label>
                                                        <div className="control has-icon">
                                                            <input type="text" className="input" value={this.state.metatraderIndicatorName} placeholder="" onChange={this.handleChangeMetatraderIndicatorName} />
                                                            <div className="form-icon">
                                                                <User />
                                                            </div>
                                                            <InlineErrorMessage errorMessages={this.state.metatraderIndicatorNameErrorMessage} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        {
                                            this.state.isNeedTradingViewAccount ?
                                                <div className="column is-6">
                                                    <div className="field">
                                                        <label>Nama Indicator Trading View</label>
                                                        <div className="control has-icon">
                                                            <input type="text" className="input" value={this.state.tradingViewIndicatorName} placeholder="" onChange={this.handleChangeTradingViewIndicatorName} />
                                                            <div className="form-icon">
                                                                <User />
                                                            </div>
                                                            <InlineErrorMessage errorMessages={this.state.tradingViewIndicatorNameErrorMessage} />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <></>
                                        }

                                    </div>
                                </div>
                                <div className="form-section-inner mt-2">
                                    <h3 className="has-text-centered">Pengaturan Harga</h3>
                                    <div className="columns is-multiline">
                                        <div className="column is-3">
                                            <div className="field">
                                                <label>Bulanan</label>
                                                <div className="control">
                                                    <div className="control">
                                                        <label className="form-switch is-primary">
                                                            <input type="checkbox" className="is-switch" checked={this.state.isEnableMonthlyPlan} onChange={this.handleChangeIsEnableMonthlyPlan} />
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column is-9">
                                            <div className="field">
                                                <label>Harga Bulanan</label>
                                                <div className="control has-icon">
                                                    <input type="number" className="input" disabled={!this.state.isEnableMonthlyPlan} value={this.state.priceMonthlyPlan} placeholder="" onChange={this.handleChangePriceMonthlyPlan} />
                                                    <div className="form-icon">
                                                        <DollarSign />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column is-3">
                                            <div className="field">
                                                <label>Tahunan</label>
                                                <div className="control">
                                                    <div className="control">
                                                        <label className="form-switch is-primary">
                                                            <input type="checkbox" className="is-switch" checked={this.state.isEnableYearlyPlan} onChange={this.handleChangeIsEnableYearlyPlan} />
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column is-9">
                                            <div className="field">
                                                <label>Harga Tahunan</label>
                                                <div className="control has-icon">
                                                    <input type="number" className="input" disabled={!this.state.isEnableYearlyPlan} value={this.state.priceYearlyPlan} placeholder="" onChange={this.handleChangePriceYearlyPlan} />
                                                    <div className="form-icon">
                                                        <DollarSign />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column is-3">
                                            <div className="field">
                                                <label>Lifetime</label>
                                                <div className="control">
                                                    <div className="control">
                                                        <label className="form-switch is-primary">
                                                            <input type="checkbox" className="is-switch" checked={this.state.isEnableLifetimePlan} onChange={this.handleChangeIsEnableLifetimePlan} />
                                                            <i></i>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column is-9">
                                            <div className="field">
                                                <label>Harga Lifetime</label>
                                                <div className="control has-icon">
                                                    <input type="number" className="input" disabled={!this.state.isEnableLifetimePlan} value={this.state.priceLifetimePlan} placeholder="" onChange={this.handleChangePriceLifetimePlan} />
                                                    <div className="form-icon">
                                                        <DollarSign />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="form-section-inner mt-2">
                                    <h3 className="has-text-centered">Checkout Page</h3>
                                    <div className="columns is-multiline">
                                        <div className="column is-12">
                                            <div className="field">
                                                <label>Link Checkout</label>
                                                <div className="control has-icon">
                                                    <input type="text" className="input" disabled={true} value={AppConsts.RuangTrader_APPURL + "/checkout/" + this.state.id} />
                                                    <div className="form-icon">
                                                        <Globe />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-section">
                                <div className="form-section-inner">
                                    <h3 className="has-text-centered">Gambar Produk</h3>
                                    <div className="columns is-multiline">
                                        {dropzoneImages}
                                    </div>
                                </div>

                                <div className="form-section-inner mt-2">
                                    <h3 className="has-text-centered">Produk yang harus sudah dimiliki</h3>
                                    <div className="columns is-multiline">
                                        {
                                            this.state.productTypes.map((productType) =>
                                            (
                                                <>
                                                    <div className="column is-2">
                                                        <div className="field mt-2">
                                                            <div className="control">
                                                                <label className="form-switch is-primary">
                                                                    <input type="checkbox" className="is-switch"
                                                                        checked={(this.state.requiredProductTypes.findIndex(i => i.id === productType.id) >= 0) ? true : false}
                                                                        onChange={() => this.handleChangeRequiredProductType(productType)} />
                                                                    <i></i>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="column is-10">
                                                        <div className="field">
                                                            <div className="control">
                                                                <input type="text" className="input" value={productType.name} disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="form-section-inner mt-2">
                                    <h3 className="has-text-centered">File dan Video Instruksi</h3>
                                    <div className="columns is-multiline">
                                        <div className="column is-12">
                                            <div className="field">
                                                <label>File Tool</label>
                                                <div className="control has-icon">
                                                    <input type="file" className="input" accept=".zip" onChange={this.handleChangeFile} />
                                                    <div className="form-icon">
                                                        <File />
                                                    </div>
                                                </div>
                                                <span>Uploaded filename: {this.state.fileName}</span>
                                            </div>
                                        </div>

                                        <div className="column is-12">
                                            <div className="field">
                                                <label>Url Video Instruksi</label>
                                                <div className="control has-icon">
                                                    <input type="input" className="input" placeholder="" value={this.state.urlVideoInstruction || ""} onChange={this.handleChangeUrlVideoInstruction} />
                                                    <div className="form-icon">
                                                        <Link />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column is-12">
                                            <div className="field">
                                                <label>Url Guide Book</label>
                                                <div className="control has-icon">
                                                    <input type="input" className="input" placeholder="" value={this.state.urlGuideBook || ""} onChange={this.handleChangeUrlGuideBook} />
                                                    <div className="form-icon">
                                                        <Link />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column is-12">
                                            <div className="field">
                                                <label>Versi</label>
                                                <div className="control has-icon">
                                                    <input type="input" className="input" placeholder="" value={this.state.version || ""} onChange={this.handleChangeVersion} />
                                                    <div className="form-icon">
                                                        <Link />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="form-section-outer mt-2">
                                    <div className="columns is-multiline">
                                        {
                                            this.state.status == 0 ?
                                                <div className="column is-12">
                                                    <button className={"button h-button is-normal is-danger " + (this.state.isLoadingDeActivate ? 'is-loading' : '')} onClick={this.handleSubmitDeactivate}>Non Aktifkan</button>
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            this.state.status == 1 ?
                                                <div className="column is-12">
                                                    <button className={"button h-button is-normal is-primary " + (this.state.isLoadingActivate ? 'is-loading' : '')} onClick={this.handleSubmitActivate}>Aktifkan</button>
                                                </div>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                                <div className="form-section-outer mt-2">
                                    <div className="button-wrap">
                                        <button type="submit" className={"button h-button is-primary is-bold is-raised is-fullwidth " + (this.state.isLoading ? 'is-loading' : '')}>Edit Sekarang</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        );
    }
};

export default withRouter(EditProduct);