import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SubscriptionService from '../../../services/subscription/subscriptionService';

class EditSubscription extends Component {
    constructor() {
        super();
        this.state = {
            id: '',
            account: '',
            accountErrorMessage: '',
            isActive: true,
            isLoading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeAccount = this.handleChangeAccount.bind(this);
        this.handleChangeIsActive = this.handleChangeIsActive.bind(this);
    }

    componentDidMount() {
        this.setState({
            id: this.props.subscription.id,
            account: this.props.subscription.account,
            isActive: this.props.subscription.status === 40 ? true : false
        })
    }

    handleChangeAccount(ev) {
        this.setState({ account: ev.currentTarget.value, accountErrorMessage: '' });
    }

    handleChangeIsActive() {
        this.setState({ isActive: !this.state.isActive })
    }

    handleSubmit(ev) {
        ev.preventDefault();
        this.setState({ isLoading: true });
        SubscriptionService.Edit(this.state.id, this.state.account, this.state.isActive).then(res => {
            //this.props.handleClose();
            //this.setState({ isLoading: false });
            window.location.reload();
        });
    }

    render() {
        const { id } = this.state;
        if (!id)
            return (<></>);
        return (
            <div class="modal h-modal is-small is-active">
                <div class="modal-background h-modal-close"></div>
                <div class="modal-content">
                    <div class="modal-card">
                        <form class="modal-form" onSubmit={this.handleSubmit}>
                            <header class="modal-card-head">
                                <h3>Edit Subscription</h3>
                                <button class="h-modal-close ml-auto" aria-label="close">
                                    <i data-feather="x"></i>
                                </button>
                            </header>
                            <div class="modal-card-body">
                                <div class="inner-content">
                                    <div class="field">
                                        <label>Account</label>
                                        <div class="control">
                                            <div class="field has-addons">
                                                <div class="control is-expanded">
                                                    <input class="input" type="text" value={this.state.account} onChange={this.handleChangeAccount} />
                                                </div>
                                                <div class="control">
                                                    <a class="button is-static">@</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="field">
                                        <label>Active?</label>
                                        <div class="control">
                                            <div class="control">
                                                <label class="form-switch is-primary">
                                                    <input type="checkbox" class="is-switch" checked={this.state.isActive} onChange={this.handleChangeIsActive} />
                                                    <i></i>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-card-foot is-end">
                                <button className="button h-button is-rounded h-modal-close" onClick={this.props.handleClose}>Cancel</button>
                                <button type={"submit"} className={"button h-button is-primary is-raised is-rounded " + (this.state.isLoading ? "is-loading" : "")}>Simpan</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EditSubscription);