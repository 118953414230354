import React from 'react';
import { Route } from 'react-router-dom';
import AuthUtil from '../../utils/auth-utils';
import NotAuthorizeModule from './NotAuthorizeModule';
import { AuthConsumer } from "../../providers/authProvider";

const ProtectedModuleRoute = ({ path, component, exact, moduleName, ...props }) => {
    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({ isAuthenticated }) => {
                if (!!Component && isAuthenticated() && AuthUtil.IsCurrentUserEditionCanAccessModule(moduleName)) {
                    return <Route exact={exact} path={path} component={component} {...props} />;
                } else {
                    return <Route exact={exact} path={path} component={NotAuthorizeModule} {...props} />;
                }
            }}
        </AuthConsumer>
    );

    return <Route {...props} exact={exact} path={path} render={renderFn(component)} />;
};

export default ProtectedModuleRoute;