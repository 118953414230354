import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AppConst from '../../../lib/appconst';
import ProductService from '../../../services/products/productService';

class ListProduct extends Component {
    constructor() {
        super();
        this.syncProducts = this.syncProducts.bind(this);
        this.handleNavigate = this.handleNavigate.bind(this);
        this.redirectToDetailPage = this.redirectToDetailPage.bind(this);

        this.state = {
            products: [],
            isLoading: true
        };
    }

    componentDidMount() {
        this.syncProducts();
    }

    handleNavigate(ev) {
        ev.preventDefault();
        var href = ev.currentTarget.getAttribute('href');
        this.props.history.push(href);
    }

    redirectToDetailPage(productId) {
        this.props.history.push('/products/' + productId);
    }

    syncProducts() {
        this.setState({ isLoading: true });
        ProductService.GetAllActive().then(res => {
            this.setState({ products: res.data.result, isLoading: false });
        });
    }

    render() {
        const { products, isLoading } = this.state;
        return (
            <>
                <div className="page-content-inner is-webapp">
                    <div className={"card-grid card-grid-v2 has-loader " + (isLoading ? "has-loader-active min-height" : "")}>
                        {
                            !isLoading ?
                                products.length > 0 ?
                                    <div className="columns is-multiline">
                                        {
                                            products.map((product, index) => (
                                                <div className="column is-3" key={product.id}>
                                                    <div className="card-grid-item">
                                                        <div className="card">
                                                            <header className="card-header">
                                                                <div className="card-header-title m-2">
                                                                    <div className="meta">
                                                                        <span className="dark-inverted" data-filter-match>{product.name}</span>
                                                                        <span>Diciptakan oleh {product.author}</span>
                                                                    </div>
                                                                </div>
                                                            </header>
                                                            <div className="card-image">
                                                                <figure className="image is-4by3">
                                                                    <img src={AppConst.RuangTrader_APIURL + "/products/image/" + product.id + "/" + product.images[0].id} alt="" />
                                                                </figure>
                                                            </div>
                                                            <div className="card-content">
                                                                <div className="card-content-flex">
                                                                    <div className="card-info">
                                                                        <h4 className="dark-inverted line-1">{product.description}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <footer className="card-footer">
                                                                <button className="card-footer-item button h-button is-primary is-medium is-raised is-elevated" onClick={() => this.redirectToDetailPage(product.id)}>
                                                                    <span className="icon mb-1">
                                                                        <i className="fas fa-cart-plus"></i>
                                                                    </span>
                                                                    <span>Dapatkan Sekarang</span>
                                                                </button>
                                                            </footer>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div className="page-placeholder custom-text-filter-placeholder">
                                        <div className="placeholder-content">
                                            <img className="light-image" src="images/illustrations/placeholders/search-3.svg" alt="" />
                                            <img className="dark-image" src="images/illustrations/placeholders/search-3-dark.svg" alt="" />
                                            <h3>Tidak ada produk yang ditemukan</h3>
                                            <p className="is-larger">Produk Ruang Trader segera diterbitkan.</p>
                                        </div>
                                    </div>
                                :
                                <div className="h-loader-wrapper">
                                    <div className="loader is-large is-loading"></div>
                                </div>
                        }
                    </div>
                </div>
            </>);
    }
};

export default withRouter(ListProduct);