import { Notyf } from "notyf";
import 'notyf/notyf.min.css';

class RTNotyf {
    NotifySuccess(message) {
        var notyf = new Notyf({ duration: 5000 });
        notyf.success(message);
    }

    NotifyError(message) {
        var notyf = new Notyf({ duration: 10000 });
        notyf.error(message);
    }
}
export default new RTNotyf();