import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import AppConst from '../../../lib/appconst';
import ProtectedModuleRoute from '../../Router/ProtectedModuleRouter';
import ListProduct from './List';
import AddProduct from './Add';
import EditProduct from './Edit';

class ProductPage extends Component {
    render() {
        return (
            <Switch>
                <ProtectedModuleRoute exact path="/admin/products" component={ListProduct} />
                <ProtectedModuleRoute exact path="/admin/products/add" component={AddProduct} />
                <ProtectedModuleRoute exact path="/admin/products/:id/edit" component={EditProduct} />
            </Switch>
        )
    }
};

export default ProductPage;