import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import ProductService from '../../../../services/products/productService';
import './listProductType.scss';

class ListProductType extends Component {
    constructor() {
        super();
        this.state = {
            productTypes: [],
            isLoading: true
        };
        this.syncProductTypes = this.syncProductTypes.bind(this);
        this.handleClickRow = this.handleClickRow.bind(this);
        this.redirectToAddType = this.redirectToAddType.bind(this);
    }

    componentDidMount() {
        this.syncProductTypes();
    }

    syncProductTypes() {
        this.setState({ isLoading: true });
        ProductService.GetTypes().then(res => {
            this.setState({
                productTypes: res.data.result
            });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    redirectToAddType() {
        this.props.history.push('/admin/producttypes/add');
    }

    handleClickRow(productType) {
        this.props.history.push("/admin/producttypes/edit/" + productType.id);
    }

    render() {
        const { isLoading, productTypes } = this.state;
        return (
            <div className="search-results-wrapper is-admin-productype-table banking-dashboard banking-dashboard-v3">
                <div className="search-results-body">
                    <div className="columns">
                        <div className="column">
                            <div className="tabs-wrapper">
                                <div className="tabs-inner">
                                    <div className="tabs-inner is-clearfix">
                                        <h3 className='title is-thin is-5 is-pulled-left p-2 is-hidden-mobile'>Tipe Produk</h3>
                                        <h3 className='title is-thin is-5 p-2 is-hidden-desktop'>Tipe Produk</h3>
                                        <div className="tabs is-toggle is-pulled-right is-right">
                                            <button className="button h-button is-primary is-raised" onClick={this.redirectToAddType}>
                                                <span className="icon">
                                                    <i className="fas fa-plus"></i>
                                                </span>
                                                <span>Tambah Tipe Produk</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className={"flex-table is-compact has-loader " + (isLoading ? "has-loader-active min-height" : "")}>
                                        <div className="flex-table-header">
                                            <span className="">Nama</span>
                                            <span className="flex-medium">Tanggal dibuat</span>
                                            <span className="flex-medium">Tanggal perubahan</span>
                                            <span className="flex-small"></span>
                                        </div>
                                        {
                                            !isLoading ?
                                                (
                                                    (productTypes.length > 0) ?
                                                        productTypes.map((productType) => (
                                                            <div className='flex-table-item' key={productType.id}>
                                                                <div className="flex-table-cell" data-th="Tipe Produk">
                                                                    <span className="light-text">
                                                                        <a href={"/admin/productypes/" + productType.id}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.handleClickRow(productType)
                                                                            }}>
                                                                            <span className="dark-text has-text-weight-medium">
                                                                                {productType.name}
                                                                            </span>
                                                                        </a>
                                                                    </span>
                                                                </div>
                                                                <div className="flex-table-cell flex-medium" data-th="Tanggal dibuat">
                                                                    <span className="light-text">{moment(productType.creationTime).local().format('LLL')}</span>
                                                                </div>
                                                                <div className="flex-table-cell flex-medium" data-th="Tanggal perubahan">
                                                                    <span className="light-text">{moment(productType.lastModificationTime || productType.creationTime).local().format('LLL')}</span>
                                                                </div>
                                                                <div className="flex-table-cell flex-small" data-th="">
                                                                    <a href={"/admin/producttypes/edit/" + productType.id} target="_blank" onClick={(e) => { e.preventDefault(); this.handleClickRow(productType) }} rel="noreferrer" className="button is-dark-outlined">Edit</a>
                                                                </div>
                                                            </div>
                                                        )
                                                        ) :
                                                        <div className="section-placeholder">
                                                            <div className="placeholder-content">
                                                                <img className="light-image" src="images/illustrations/placeholders/search-4.svg" alt="" />
                                                                <img className="dark-image" src="images/illustrations/placeholders/search-4-dark.svg" alt="" />
                                                                <h3 className="dark-inverted">Tidak ada Tipe Produk</h3>
                                                                <p>Saat ini belum ada Tipe Produk yang bisa ditampilkan.</p>
                                                            </div>
                                                        </div>
                                                )
                                                :
                                                <div className="h-loader-wrapper">
                                                    <div className="loader is-large is-loading"></div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ListProductType);