import React, { Component } from 'react';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import { generateShortString } from "../../utils/string-utils";
import AuthUtil from "../../utils/auth-utils";
import AppConsts from "../../lib/appconst";
import AuthService from "../../services/auth/authService";
import { setStorage, getStorage } from '../../utils/storage-utils';
import { Radio, Settings, LogOut, Users, MessageCircle, Box, Award, Book, Activity } from 'react-feather';
import ReactGA from 'react-ga';
import './layout.scss';

class Layout extends Component {
    constructor() {
        super();
        this.state = {
            isBurgerActive: false,
            showUserMenu: false,
            isDarkMode: false,
            title: 'Produk',
            showMobileSidebar: false,
            user: {
                name: '',
                userName: ''
            }
        };
        this.handleExpandBurger = this.handleExpandBurger.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleLogOut = this.handleLogOut.bind(this);
        this.showUserMenu = this.showUserMenu.bind(this);
        this.changeDarkMode = this.changeDarkMode.bind(this);
        this.toggleMobileSidebar = this.toggleMobileSidebar.bind(this);
        this.syncUser = this.syncUser.bind(this);

        this.urls = [
            { name: "Produk", url: "" },
            { name: "Subscription", url: "subscriptions" },
            { name: "Order", url: "orders" },
            { name: "Orders", url: "/admin/orders" },
            { name: "Subscriptions", url: "/admin/subscriptions" },
            { name: "Products", url: "/admin/products" },
            { name: "Clients", url: "clients" },
            { name: "Report", url: "/admin/reports" }
        ];
    }

    componentDidMount() {
        var currentLoggedInUser = AuthUtil.GetCurrentUser();
        if (currentLoggedInUser) {
            ReactGA.set({
                userId: currentLoggedInUser.userName + '-' + currentLoggedInUser.name
            })
        }

        var lastDarkMode = getStorage("isDarkMode");
        if (lastDarkMode) {
            this.setState({ isDarkMode: lastDarkMode === 'true' }, () => {
                if (lastDarkMode === 'true')
                    document.body.classList.add('is-dark');
                else
                    document.body.classList.remove('is-dark');
            });
        }

        if (this.props.history.location !== "/") {
            var currentHref = this.props.history.location.pathname.split('/')[1];
            if (currentHref.toLowerCase() === "admin")
                currentHref = this.props.history.location.pathname.split('/')[2];

            var element = $("*[href*='/" + currentHref + "'");
            var title = element.attr("data-content") || element.attr("data-title");
            if (!title) {
                var urlObj = this.urls.find(i => i.url.toLowerCase() === currentHref.toLowerCase());
                title = urlObj ? urlObj.name : this.state.title;
            }
            this.setState({ title: title });
        }
        this.syncUser();
    }

    syncUser() {
        var user = AuthUtil.GetCurrentUser();
        if (!user) {
            AuthService.GetUserByToken().then(res => {
                setStorage("user", JSON.stringify(res.data.result), (365 * 24 * 60 * 60)); //expired 7 days
                this.setState({
                    user: {
                        name: res.data.name,
                        userName: res.data.userName
                    }
                });
            });
        }
        else {
            this.setState({
                user: {
                    name: user.name,
                    userName: user.userName
                }
            });
        }
    }

    showUserMenu() {
        this.setState({ showUserMenu: !this.state.showUserMenu });
    }

    toggleMobileSidebar() {
        this.setState({ showMobileSidebar: !this.state.showMobileSidebar })
    }

    changeDarkMode() {
        this.setState({ isDarkMode: !this.state.isDarkMode }, () => {
            if (this.state.isDarkMode)
                document.body.classList.add('is-dark');
            else
                document.body.classList.remove('is-dark');
            setStorage("isDarkMode", this.state.isDarkMode, (365 * 24 * 60 * 60)); //expired 1 year
        });
    }

    handleExpandBurger(ev) {
        ev.preventDefault();
        this.setState({ isBurgerActive: !this.state.isBurgerActive });
    }

    handleChangePage(ev) {
        ev.preventDefault();

        if (this.state.showMobileSidebar) {
            this.toggleMobileSidebar();
        }

        var title = ev.currentTarget.getAttribute("data-content");
        if (!title) {
            title = ev.currentTarget.getAttribute("data-title");
        }
        var href = ev.currentTarget.getAttribute("href");
        this.setState({ title: title }, () => {
            this.props.history.push(href);
        });
    }

    handleLogOut() {
        this.props.history.push("/logout");
    }

    render() {
        if (!this.state.user || !this.state.user.userName)
            return <div className="pt-5">{this.props.children}</div>;

        var profileMenu =
            <div className="dropdown-menu" role="menu">
                <div className="dropdown-content">
                    <div className="dropdown-head">
                        <div className="h-avatar is-large">
                            <div className="h-avatar is-medium">
                                <span className="avatar is-fake is-primary">
                                    <span>{generateShortString(this.state.user ? this.state.user.name : '')}</span>
                                </span>
                            </div>
                        </div>
                        <div className="meta">
                            <span>{this.state.user.name}</span>
                            <span>{this.state.user.userName}</span>
                        </div>
                    </div>
                    <hr className="dropdown-divider" />
                    <a href={AppConsts.RuangTrader_MAINAPPURL + "/accountsettings/personal"} className="dropdown-item is-media" data-title="Account Setting">
                        <div className="icon">
                            <Settings />
                        </div>
                        <div className="meta">
                            <span>Settings</span>
                            <span>Account settings</span>
                        </div>
                    </a>
                    <div className="dropdown-item is-button">
                        <button className="button h-button is-primary is-raised is-fullwidth logout-button" onClick={this.handleLogOut}>
                            <span className="icon is-small">
                                <LogOut />
                            </span>
                            <span>Logout</span>
                        </button>
                    </div>
                </div>
            </div>;

        return (
            <div className="app-wrapper">
                <nav className="navbar mobile-navbar no-shadow is-hidden-desktop is-hidden-tablet" aria-label="main navigation">
                    <div className="container">
                        <div className="navbar-brand">
                            <div className="brand-start">
                                <div className="navbar-burger" onClick={this.toggleMobileSidebar}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>

                            <a className="navbar-item is-brand" href="/">
                                <img className="light-image" src="images/logos/logo/logo.png" alt="" />
                                <img className="dark-image" src="images/logos/logo/logo-light.png" alt="" />
                            </a>

                            <div className="brand-end">
                                <div className={"dropdown is-right is-spaced dropdown-trigger user-dropdown " + (this.state.showUserMenu ? "is-active" : "")} onClick={this.showUserMenu}>
                                    <div className="is-trigger" aria-haspopup="true">
                                        <div className="profile-avatar">
                                            <div className="h-avatar is-medium avatar-mobile">
                                                <span className="avatar is-fake is-primary">
                                                    <span>{generateShortString(this.state.user.name)}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {profileMenu}
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className={"mobile-main-sidebar has-labels " + (this.state.showMobileSidebar ? 'is-active' : '')} >
                    <div className="inner">
                        <ul className="icon-side-menu">
                            <li className={(this.state.title === "Produk" ? "is-active" : "")}>
                                <a href="/" data-content="Produk" onClick={this.handleChangePage}>
                                    <Box className="sidebar-svg" />
                                </a>
                            </li>
                            {
                                !AuthUtil.IsCurrentUserCanAccessClientManagement() ?
                                    <>
                                        <li className={(this.state.title === "Subscription" ? "is-active" : "")}>
                                            <a href="/subscriptions" data-content="Subscription" onClick={this.handleChangePage}>
                                                <Radio className="sidebar-svg" />
                                            </a>
                                        </li>
                                        <li className={(this.state.title === "Order" ? "is-active" : "")}>
                                            <a href="/orders" data-content="Order" onClick={this.handleChangePage}>
                                                <Radio className="sidebar-svg" />
                                            </a>
                                        </li>
                                    </>
                                    :
                                    <></>
                            }
                            {
                                AuthUtil.IsCurrentUserCanAccessClientManagement() ?
                                    <>
                                        <li className={(this.state.title === "Orders" ? "is-active" : "")}>
                                            <a href="/admin/orders" data-content="Orders" onClick={this.handleChangePage}>
                                                <Radio className="sidebar-svg" />
                                            </a>
                                        </li>
                                        <li className={(this.state.title === "Subscriptions" ? "is-active" : "")}>
                                            <a href="/admin/subscriptions" data-content="Subscriptions" onClick={this.handleChangePage}>
                                                <Award className="sidebar-svg" />
                                            </a>
                                        </li>
                                        <li className={(this.state.title === "Products" ? "is-active" : "")}>
                                            <a href="/admin/products" data-content="Products" onClick={this.handleChangePage}>
                                                <Radio className="sidebar-svg" />
                                            </a>
                                        </li>
                                        <li className={(this.state.title === "Clients" ? "is-active" : "")}>
                                            <a href="/clients" data-content="Clients" onClick={this.handleChangePage}>
                                                <Users className="sidebar-svg" />
                                            </a>
                                        </li>
                                        <li className={(this.state.title === "Report" ? "is-active" : "")}>
                                            <a href="/admin/reports" data-content="Report" onClick={this.handleChangePage}>
                                                <Activity className="sidebar-svg" />
                                            </a>
                                        </li>
                                    </>
                                    :
                                    <></>
                            }
                            <li>
                                <a href={AppConsts.RuangTrader_MAINAPPURL} data-content="RT App">
                                    <Activity className="sidebar-svg" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="webapp-navbar">
                    <div className="webapp-navbar-inner">
                        <div className="left">
                            <a href="/" className="brand">
                                <img className="light-image" src="/images/logos/logo/logo.png" alt="" />
                                <img className="dark-image" src="/images/logos/logo/logo-light.png" alt="" />
                            </a>
                            <div className="separator"></div>
                            <h1 id="webapp-page-title" className="title is-5">{this.state.title}</h1>
                        </div>
                        <div className="center">
                            <div id="webapp-navbar-menu" className="centered-links">
                                <a href="/" className={"centered-link centered-link-toggle " + (this.state.title === "Produk" ? "is-active" : "")} data-content="Produk" onClick={this.handleChangePage}>
                                    <Box className="sidebar-svg" />
                                    <span>Produk</span>
                                </a>
                                {
                                    !AuthUtil.IsCurrentUserCanAccessClientManagement() ?
                                        <>
                                            <a href="/subscriptions" className={"centered-link centered-link-toggle " + (this.state.title === "Subscription" ? "is-active" : "")} data-content="Subscription" onClick={this.handleChangePage}>
                                                <Award className="sidebar-svg" />
                                                <span>Subscription</span>
                                            </a>
                                            <a href="/orders" className={"centered-link centered-link-toggle " + (this.state.title === "Order" ? "is-active" : "")} data-content="Order" onClick={this.handleChangePage}>
                                                <Book className="sidebar-svg" />
                                                <span>Order</span>
                                            </a>
                                        </>
                                        :
                                        <></>
                                }
                                {
                                    AuthUtil.IsCurrentUserCanAccessClientManagement() ?
                                        <>
                                            <a href="/admin/orders" className={"centered-link centered-link-search " + (this.state.title === "Orders" ? "is-active" : "")} data-content="Orders" onClick={this.handleChangePage}>
                                                <Book className="sidebar-svg" />
                                                <span>Orders</span>
                                            </a>
                                            <a href="/admin/subscriptions" className={"centered-link centered-link-search " + (this.state.title === "Subscriptions" ? "is-active" : "")} data-content="Subscriptions" onClick={this.handleChangePage}>
                                                <Award className="sidebar-svg" />
                                                <span>Subscriptions</span>
                                            </a>
                                            <a href="/admin/products" className={"centered-link " + (this.state.title === "Products" ? "is-active" : "")} data-content="Products" onClick={this.handleChangePage}>
                                                <Box className="sidebar-svg" />
                                                <span>Products</span>
                                            </a>
                                            <a href="/clients" className={"centered-link " + (this.state.title === "Clients" ? "is-active" : "")} data-content="Clients" onClick={this.handleChangePage}>
                                                <Users className="sidebar-svg" />
                                                <span>Clients</span>
                                            </a>
                                            <a href="/admin/reports" className={"centered-link " + (this.state.title === "Report" ? "is-active" : "")} data-content="Report" onClick={this.handleChangePage}>
                                                <Users className="sidebar-svg" />
                                                <span>Report</span>
                                            </a>
                                        </>
                                        :
                                        <></>
                                }
                                <a href={AppConsts.RuangTrader_MAINAPPURL} className="centered-link centered-link-toggle" data-content="Ruang Trader App" >
                                    <Activity className="sidebar-svg" />
                                    <span>RT App</span>
                                </a>
                            </div>
                        </div>
                        <div className="right">
                            <div className="toolbar ml-auto">
                                <div className="toolbar-link">
                                    <label className="dark-mode ml-auto">
                                        <input type="checkbox" checked={this.state.isDarkMode} onChange={this.changeDarkMode} />
                                        <span></span>
                                    </label>
                                </div>

                                <div className="toolbar-notifications mr-3">
                                    <a href={AppConsts.UrlSupportTeam} target="_blank">
                                        <div className="dropdown is-spaced is-dots is-right dropdown-trigger">
                                            <div className="is-trigger" aria-haspopup="true">
                                                <MessageCircle />
                                                <span className="new-indicator pulsate"></span>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                            <div className={"dropdown profile-dropdown dropdown-trigger is-pointer is-spaced is-right " + (this.state.showUserMenu ? "is-active" : "")} onClick={this.showUserMenu}>
                                <div className="h-avatar is-medium">
                                    <span className="avatar is-fake is-primary">
                                        <span>{generateShortString(this.state.user ? this.state.user.name : '')}</span>
                                    </span>
                                </div>
                                <span className="status-indicator"></span>
                                {profileMenu}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="view-wrapper is-webapp" data-naver-offset="150">
                    <div className="page-content-wrapper">
                        <div className="page-content is-relative">
                            <div className="page-title has-text-centered is-webapp">
                                <div className="title-wrap">
                                    <h1 className="title is-4">{this.state.title}</h1>
                                </div>

                                <div className="toolbar ml-auto">
                                    <div className="toolbar-link">
                                        <label className="dark-mode ml-auto">
                                            <input type="checkbox" checked={this.state.isDarkMode} onChange={this.changeDarkMode} />
                                            <span></span>
                                        </label>
                                    </div>

                                    <div className="toolbar-notifications mr-3">
                                        <a href={AppConsts.UrlSupportTeam} target="_blank">
                                            <div className="dropdown is-spaced is-dots is-right dropdown-trigger">
                                                <div className="is-trigger" aria-haspopup="true">
                                                    <MessageCircle />
                                                    <span className="new-indicator pulsate"></span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="page-content-inner">
                                {this.props.children}
                            </div>

                        </div>
                    </div>

                </div>
            </div >
        );
    }
};

export default withRouter(Layout);