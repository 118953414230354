import React, { Component } from 'react';
import { withRouter } from 'react-router';
import NumberFormat from 'react-number-format';
import ReportService from '../../../../services/reports/reportService';

class MainReport extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            totalOrderToday: 0,
            totalOrderThisMonth: 0,
            totalOrderPrevMonth: 0,
            totalOrderThisYear: 0,
            totalOrder: 0,
            totalSalesToday: 0,
            totalSalesThisMonth: 0,
            totalSalesPrevMonth: 0,
            totalSalesThisYear: 0,
            totalSales: 0,
            totalPaymentToday: 0,
            totalPaymentThisMonth: 0,
            totalPaymentPrevMonth: 0,
            totalPaymentThisYear: 0,
            totalPayment: 0
        };

        this.handleSyncKPI = this.handleSyncKPI.bind(this);
    }

    componentDidMount() {
        this.handleSyncKPI();
    }

    handleSyncKPI() {
        this.setState({ isLoading: true });
        ReportService.GetKPI().then(res => {
            this.setState({
                totalOrderToday: res.data.result.totalOrderToday,
                totalOrderThisMonth: res.data.result.totalOrderThisMonth,
                totalOrderPrevMonth: res.data.result.totalOrderPrevMonth,
                totalOrderThisYear: res.data.result.totalOrderThisYear,
                totalOrder: res.data.result.totalOrder,
                totalSalesToday: res.data.result.totalSalesToday,
                totalSalesThisMonth: res.data.result.totalSalesThisMonth,
                totalSalesPrevMonth: res.data.result.totalSalesPrevMonth,
                totalSalesThisYear: res.data.result.totalSalesThisYear,
                totalSales: res.data.result.totalSales,
                totalPaymentToday: res.data.result.totalPaymentToday,
                totalPaymentThisMonth: res.data.result.totalPaymentThisMonth,
                totalPaymentPrevMonth: res.data.result.totalPaymentPrevMonth,
                totalPaymentThisYear: res.data.result.totalPaymentThisYear,
                totalPayment: res.data.result.totalPayment
            });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        if (this.state.isLoading)
            return <>Loading...</>;
        return (
            <div className="finance-dashboard analytics-dashboard">
                <div className="columns">
                    <div className="column is-9">
                        <div className="columns is-multiline">
                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Order Hari Ini</h3>
                                        <div className="h-icon is-orange is-rounded is-small">
                                            <i className="fas fa-gem"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalOrderToday} displayType={'text'} thousandSeparator={true} prefix={''} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Sales Hari Ini</h3>
                                        <div className="h-icon is-green is-rounded is-small">
                                            <i className="fas fa-bullhorn"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalSalesToday} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Payment Hari Ini</h3>
                                        <div className="h-icon is-green is-rounded is-small">
                                            <i className="fas fa-bullhorn"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalPaymentToday} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Order Bulan Ini</h3>
                                        <div className="h-icon is-orange is-rounded is-small">
                                            <i className="fas fa-gem"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalOrderThisMonth} displayType={'text'} thousandSeparator={true} prefix={''} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Sales Bulan Ini</h3>
                                        <div className="h-icon is-green is-rounded is-small">
                                            <i className="fas fa-bullhorn"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalSalesThisMonth} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Payment Bulan Ini</h3>
                                        <div className="h-icon is-green is-rounded is-small">
                                            <i className="fas fa-bullhorn"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalPaymentThisMonth} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Order Bulan Lalu</h3>
                                        <div className="h-icon is-orange is-rounded is-small">
                                            <i className="fas fa-gem"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalOrderPrevMonth} displayType={'text'} thousandSeparator={true} prefix={''} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Sales Bulan Lalu</h3>
                                        <div className="h-icon is-green is-rounded is-small">
                                            <i className="fas fa-bullhorn"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalSalesPrevMonth} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Payment Bulan Lalu</h3>
                                        <div className="h-icon is-green is-rounded is-small">
                                            <i className="fas fa-bullhorn"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalPaymentPrevMonth} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Order Tahun Ini</h3>
                                        <div className="h-icon is-orange is-rounded is-small">
                                            <i className="fas fa-gem"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalOrderThisYear} displayType={'text'} thousandSeparator={true} prefix={''} />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Sales Tahun Ini</h3>
                                        <div className="h-icon is-green is-rounded is-small">
                                            <i className="fas fa-bullhorn"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalSalesThisYear} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Payment Tahun Ini</h3>
                                        <div className="h-icon is-green is-rounded is-small">
                                            <i className="fas fa-bullhorn"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalPaymentThisYear} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Order</h3>
                                        <div className="h-icon is-orange is-rounded is-small">
                                            <i className="fas fa-gem"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalOrder} displayType={'text'} thousandSeparator={true} prefix={''} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Sales</h3>
                                        <div className="h-icon is-green is-rounded is-small">
                                            <i className="fas fa-bullhorn"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalSales} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-4">
                                <div className="dashboard-tile">
                                    <div className="tile-head">
                                        <h3 className="dark-inverted">Total Payment</h3>
                                        <div className="h-icon is-green is-rounded is-small">
                                            <i className="fas fa-bullhorn"></i>
                                        </div>
                                    </div>
                                    <div className="tile-body">
                                        <span className="dark-inverted">
                                            <NumberFormat value={this.state.totalPayment} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="column is-12">
                                <div className="dashboard-card">
                                    <div className="card-head">
                                        <h3 className="dark-inverted">Penjualan Harian</h3>
                                    </div>
                                    <div id="profit-chart">Under Construction</div>
                                </div>
                            </div>
                            <div className="column is-12">
                                <div className="dashboard-card">
                                    <div className="card-head">
                                        <h3 className="dark-inverted">Penjualan Bulanan</h3>
                                    </div>
                                    <div id="profit-chart">Under Construction</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="column is-3">
                        <div className="widget list-widget is-straight">
                            <div className="widget-toolbar">
                                <div className="left">
                                    <h3>Produk Terlaris Hari Ini</h3>
                                </div>
                            </div>
                            <div className="widget-content">
                                <ul>
                                    <li>
                                        <a>
                                            <span>Under Construction</span>
                                            <span className="tag is-rounded">4</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="widget list-widget is-straight">
                            <div className="widget-toolbar">
                                <div className="left">
                                    <h3>Produk Terlaris Bulan Ini</h3>
                                </div>
                            </div>
                            <div className="widget-content">
                                <ul>
                                    <li>
                                        <a>
                                            <span>Under Construction</span>
                                            <span className="tag is-rounded">4</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="widget list-widget is-straight">
                            <div className="widget-toolbar">
                                <div className="left">
                                    <h3>Produk Terlaris</h3>
                                </div>
                            </div>
                            <div className="widget-content">
                                <ul>
                                    <li>
                                        <a>
                                            <span>Under Construction</span>
                                            <span className="tag is-rounded">4</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MainReport);