import axios from 'axios';
import { getStorage } from '../utils/storage-utils';
import AppConsts from '../lib/appconst';
import RTNotyf from '../utils/notyf-utils';
import AuthService from '../services/auth/oidcAuthService';

const http = axios.create({
    baseURL: AppConsts.RuangTrader_APIURL,
    timeout: 30000
});

http.interceptors.request.use(
    async function (config) {
        var access_token = getStorage("access_token");
        if (access_token) {
            config.headers.Authorization = "Bearer " + access_token;
        }
        else {
            var oidcAuthService = new AuthService();
            if (oidcAuthService.isAuthenticated()) {
                var user = await oidcAuthService.getUser();
                if (user)
                    config.headers.Authorization = "Bearer " + user.access_token;
            }
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    response => response,
    errorResponseHandler
);

function errorResponseHandler(error) {
    if (!error.config)
        return Promise.reject(error);

    if (error.config.hasOwnProperty('customErrorHandler') && error.config.customErrorHandler === true) {
        return Promise.reject(error);
    }

    if (!error.response) {
        RTNotyf.NotifyError("Failed to connect server Ruang Trader");
    }
    else if ((error.response.status === 401 || error.response.status === 403) && !error.config._retry) {
        return unAuthrorizeHandler(error);
    }
    else {
        if (error.response.headers["token-expired"] !== "true") {
            var defaultErrorMessage = 'Terjadi sesuatu yang tidak terduga sehingga tidak bisa menyelesaikan permintaan Anda. Kami mohon maaf.';
            if (error.response) {
                var message = '';
                if (typeof error.response.data === "string") {
                    message = error.response.data;
                } else {
                    if (error.response.data.error && error.response.data.error.message) {
                        message = error.response.data.error.message;
                    } else if (error.response.data.message) {
                        message = error.response.data.message;
                    } else if (error.response.data.Message) {
                        message = error.response.data.Message;
                    } else if (typeof error.response.data.errors === "object") {
                        message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0];
                    } else {
                        message = defaultErrorMessage;
                    }
                }
                RTNotyf.NotifyError(message);
            } else {
                RTNotyf.NotifyError(defaultErrorMessage);
            }
        }
    }
    return Promise.reject(error);
}

async function unAuthrorizeHandler(error) {
    if (error.response.headers["token-expired"] === "true") {
        var authService = new AuthService();
        const originalRequest = error.config;

        await authService.signinSilent()
            .then((access_token) => {
                //store.dispatch({ type: AuthDuck.actionTypes.RefreshToken, payload: { authToken: accessToken, refreshToken: refreshToken } });
                originalRequest._retry = true;
                originalRequest.headers.Authorization = "Bearer " + access_token;
                return axios.request(originalRequest)
            })
            .catch(() => {
                window.location = "/logout";
                return Promise.reject(error);
            });
    }
    return Promise.reject(error);
}

export default http;