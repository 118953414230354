import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class MidtransFinished extends Component {
    render() {
        return (
            <div className="confirm-account-wrapper" >
                <div className="wrapper-inner">
                    <div className="action-box">
                        <div className="box-content">
                            <img className="light-image" src="images/illustrations/placeholders/cooking.svg" alt="" />
                            <img className="dark-image" src="images/illustrations/placeholders/cooking-dark.svg" alt="" />
                            <h3 className="dark-inverted">Sebentar lagi.</h3>
                            <p>Kami sedang proses transaksi Anda, sebentar lagi Anda sudah bisa akses tool yang Anda inginkan. Kami akan memberitahukan Anda melalui Email jika transaksi ini sudah selesai diproses.</p>
                            <br/>
                            <p>Jika Anda telah melakukan pembayaran, silahkan Masukan Account yang ingin diaktifkan melalui halaman Subscription (tombol Lihat Subscription).</p>
                            <div className="buttons">
                                <a href="/orders" className="button h-button is-primary is-raised">Lihat Order</a>
                                <a href="/subscriptions" className="button h-button is-primary is-raised">Lihat Subscription</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
    }
}

export default withRouter(MidtransFinished);