import { IDENTITY_CONFIG, METADATA_OIDC } from "../../lib/authConst";
import AppConst from '../../lib/appconst';
import AuthService from '../../services/auth/authService';
import { getStorage, setStorage } from '../../utils/storage-utils';
import { UserManager, WebStorageStateStore, Log } from "oidc-client";

export default class OidcAuthService {
    UserManager;

    constructor() {
        this.UserManager = new UserManager({
            ...IDENTITY_CONFIG,
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            metadata: {
                ...METADATA_OIDC
            }
        });
        // Logger
        //Log.logger = console;
        //Log.level = Log.DEBUG;
        this.UserManager.events.addUserLoaded((user) => {
            if (window.location.href.indexOf("signin-oidc") !== -1) {
                setStorage("access_token", user.access_token, (365 * 24 * 60 * 60)); //expired 7 days
                AuthService.GetUserByToken().then(res => {
                    setStorage("user", JSON.stringify(res.data.result), (365 * 24 * 60 * 60)); //expired 7 days
                    this.navigateToScreen();
                });
            }
        });
        this.UserManager.events.addSilentRenewError((e) => {
            console.log("silent renew error", e.message);
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            console.log("token expired");
            this.signinSilent();
        });
    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().then(function (user) {
        });
    };


    getUser = async () => {
        const user = await this.UserManager.getUser();
        if (!user) {
            return await this.UserManager.signinRedirectCallback();
        }

        var access_token = getStorage("access_token");
        if (!access_token) {
            setStorage("access_token", user.access_token, (365 * 24 * 60 * 60)); //expired 7 days
        }
        return user;
    };

    parseJwt = (token) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };


    signinRedirect = () => {
        localStorage.setItem("redirectUri", window.location.pathname + window.location.search);
        this.UserManager.signinRedirect({});
    };


    navigateToScreen = () => {
        var redirectUri = localStorage.getItem("redirectUri");
        window.location.replace(redirectUri);
    };


    isAuthenticated = () => {
        const oidcStorage = JSON.parse(localStorage.getItem(`oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`))

        return (!!oidcStorage && !!oidcStorage.access_token)
    };

    signinSilent = () => {
        this.UserManager.signinSilent()
            .then((user) => {
                setStorage("access_token", user.access_token, (365 * 24 * 60 * 60)); //expired 7 days
                return user.access_token;
            })
            .catch((err) => {
                console.log(err);
                window.location = "/logout";
                throw err;
            });
    };

    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    logout = () => {
        this.UserManager.signoutRedirect({
            id_token_hint: localStorage.getItem("id_token")
        });
        this.UserManager.clearStaleState();
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            window.location.replace(AppConst.RuangTrader_APPURL);
        });
        this.UserManager.clearStaleState();
    };
}