import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import ProductService from '../../../services/products/productService';
import OrderService from '../../../services/orders/orderService';
import InlineErrorMessage from '../../../components/InlineErrorMessage';
import { withRouter } from 'react-router';
import NotifyUtil from '../../../utils/notyf-utils';
import { User, Lock, Unlock } from 'react-feather';
import "./detail.scss";

class DetailProduct extends Component {
    constructor() {
        super();

        this.syncProduct = this.syncProduct.bind(this);
        this.handleChangePlan = this.handleChangePlan.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);

        this.state = {
            id: '',
            name: '',
            description: '',
            detailDescription: '',
            price: '',
            firstProductImage: null,
            isLoading: false,
            isNeedMetatrader4Account: false,
            isNeedMetatrader4HFAccount: false,
            isNeedMetatrader4HFPremiumAccount: false,
            isNeedMetatrader4HFCentAccount: false,
            isNeedTradingViewAccount: false,
            selectedPlan: 999,
            isEnableMonthlyPlan: false,
            priceMonthlyPlan: 0,
            isEnableYearlyPlan: false,
            priceYearlyPlan: 0,
            isEnableLifetimePlan: false,
            priceLifetimePlan: 0,
            author: ''
        };
    }

    componentDidMount() {
        this.setState({ id: this.props.match.params.id }, () => {
            this.syncProduct();
        });
    }

    handleChangePlan(plan) {
        var price = 0;
        switch (plan) {
            case 30: price = this.state.priceMonthlyPlan; break;
            case 365: price = this.state.priceYearlyPlan; break;
            case 999: price = this.state.priceLifetimePlan; break;
        }
        this.setState({ selectedPlan: plan, price: price });
    }

    handleSubmitForm(ev) {
        ev.preventDefault();

        this.setState({ isLoading: true });
        OrderService.Create(this.state.id, this.state.selectedPlan).then(res => {
            this.props.history.push('/orders/' + res.data.result.id);
        }).catch(res => {
            var errorMessage = res.response.data.error.message;
            NotifyUtil.NotifyError(errorMessage);
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    syncProduct() {
        ProductService.Get(this.state.id)
            .then(res => {
                this.setState({
                    name: res.data.result.name,
                    description: res.data.result.description,
                    detailDescription: res.data.result.detailDescription,
                    price: res.data.result.price,
                    isNeedMetatrader4Account: res.data.result.isNeedMetatrader4Account,
                    isNeedMetatrader4HFAccount: res.data.result.isNeedMetatrader4HFAccount,
                    isNeedMetatrader4HFPremiumAccount: res.data.result.isNeedMetatrader4HFPremiumAccount,
                    isNeedMetatrader4HFCentAccount: res.data.result.isNeedMetatrader4HFCentAccount,
                    isNeedTradingViewAccount: res.data.result.isNeedTradingViewAccount,
                    firstProductImage: res.data.result.firstProductImage,
                    isEnableMonthlyPlan: res.data.result.plans.find(i => i.type == 30).isActive,
                    priceMonthlyPlan: res.data.result.plans.find(i => i.type == 30).price,
                    isEnableYearlyPlan: res.data.result.plans.find(i => i.type == 365).isActive,
                    priceYearlyPlan: res.data.result.plans.find(i => i.type == 365).price,
                    isEnableLifetimePlan: res.data.result.plans.find(i => i.type == 999).isActive,
                    priceLifetimePlan: res.data.result.plans.find(i => i.type == 999).price,
                    author: res.data.result.author
                }, () => {
                    if (this.state.isEnableLifetimePlan)
                        this.setState({ selectedPlan: 999, price: this.state.priceLifetimePlan });
                    else if (this.state.isEnableYearlyPlan)
                        this.setState({ selectedPlan: 365, price: this.state.priceYearlyPlan });
                    else if (this.state.isEnableMonthlyPlan)
                        this.setState({ selectedPlan: 30, price: this.state.priceMonthlyPlan });
                });
                var imagesUrls = [];
                for (const image of res.data.result.images) {
                    imagesUrls.push({ priority: image.priority, url: image.url });
                }
                this.setState({
                    imagesUrls: imagesUrls
                });
            });
    }

    render() {
        if (!this.state.name)
            return <></>;

        const { name, description, detailDescription, author, price, firstProductImage, isNeedMetatrader4Account, isNeedMetatrader4HFAccount, isNeedMetatrader4HFPremiumAccount, isNeedMetatrader4HFCentAccount, isNeedTradingViewAccount } = this.state;
        return (
            <>
                <div className="promotion-page-wrapper detail-product">
                    <div className="wrapper-outer">
                        <div className="wrapper-inner">
                            <div className="action-box">
                                <div className="box-content">
                                    <img className="light-image is-larger" src={firstProductImage.url} alt="" />
                                    <img className="dark-image is-larger" src={firstProductImage.url} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="side-wrapper">
                            <div className="side-inner">
                                <div className="side-inner">
                                    <form onSubmit={this.handleSubmitForm}>
                                        <div className="side-title">
                                            <h3 className="dark-inverted">{name}</h3>
                                            <p>{description}</p>
                                            <p>Dikembangkan oleh {author}</p>
                                        </div>

                                        <div className="side-title mb-1 dark-inverted">
                                            <span style={{ fontSize: "0.9em" }}>Pilih Paket:</span>
                                        </div>
                                        <div className="field">
                                            <div className="control">
                                                <div className="radio-pills">
                                                    {
                                                        this.state.isEnableMonthlyPlan ?
                                                            <div className="radio-pill">
                                                                <input type="radio" name="plan" checked={this.state.selectedPlan === 30} onChange={() => this.handleChangePlan(30)} />
                                                                <div className="radio-pill-inner">
                                                                    <span>1 Bulan</span>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        this.state.isEnableYearlyPlan ?
                                                            <div className="radio-pill">
                                                                <input type="radio" name="plan" checked={this.state.selectedPlan === 365} onChange={() => this.handleChangePlan(365)} />
                                                                <div className="radio-pill-inner">
                                                                    <span>1 Tahun</span>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                    {
                                                        this.state.isEnableLifetimePlan ?
                                                            <div className="radio-pill">
                                                                <input type="radio" name="plan" checked={this.state.selectedPlan === 999} onChange={() => this.handleChangePlan(999)} />
                                                                <div className="radio-pill-inner">
                                                                    <span>Lifetime</span>
                                                                </div>
                                                            </div>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="side-title mt-5 mb-1 dark-inverted">
                                            <span style={{ fontSize: "1em" }}>Anda akan mendapatkan License: </span>
                                        </div>
                                        <div className="action-list">
                                            <div className="form-body">
                                                <div className="fieldset">
                                                    <div className="columns is-multiline" style={{ maxWidth: '400px' }}>
                                                        <div className="content">
                                                            <ul>
                                                                {
                                                                    isNeedMetatrader4Account ? <li>Metatrader 4</li> : <></>
                                                                }
                                                                {
                                                                    isNeedMetatrader4HFAccount ? <li>Metatrader 4 broker Hotforex</li> : <></>
                                                                }
                                                                {
                                                                    isNeedMetatrader4HFPremiumAccount ? <li>Metatrader 4 broker Hotforex Premium</li> : <></>
                                                                }
                                                                {
                                                                    isNeedMetatrader4HFCentAccount ? <li>Metatrader 4 broker Hotforex Cent</li> : <></>
                                                                }
                                                                {
                                                                    isNeedTradingViewAccount ? <li>Tradingview</li> : <></>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="side-title dark-inverted mt-5 mb-5" style={{ fontSize: "1em" }}>
                                            <span>Biaya: </span>
                                            {
                                                price === 0 ?
                                                    <span className="is-bold">FREE</span>
                                                    :
                                                    <NumberFormat style={{ fontSize: '1.2em' }} value={price} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />
                                            }
                                        </div>
                                        <button type="submit" className={"button h-button is-fullwidth is-primary is-big is-raised is-bold " + (this.state.isLoading ? 'is-loading' : '')}>Beli Sekarang</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {
                    detailDescription ?
                        <div className="promotion-page-wrapper description-product mt-3">
                            <div className="wrapper-outer">
                                <div className="side-wrapper">
                                    <div className="side-inner">
                                        <div className="side-inner">
                                            <div className="side-title">
                                                <h3 className="dark-inverted">Penjelasan detail {name}</h3>
                                            </div>

                                            <span dangerouslySetInnerHTML={{ __html: detailDescription }}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
            </>
        );
    }
}

export default withRouter(DetailProduct);