import React, { useEffect } from 'react';
import AppConsts from './appconst';

const Midtrans = () => {
    useEffect(() => {
        //change this to the script source you want to load, for example this is snap.js sandbox env
        const midtransScriptUrl = AppConsts.Midtrans.ScriptUrl;
        //change this according to your client-key
        const myMidtransClientKey = AppConsts.Midtrans.ClientKey;

        let scriptTag = document.createElement('script');
        scriptTag.src = midtransScriptUrl;
        // optional if you want to set script attribute
        // for example snap.js have data-client-key attribute
        scriptTag.setAttribute('data-client-key', myMidtransClientKey);

        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
    }, []);

    return (<></>);
}

export default Midtrans;