import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import ProtectedModuleRoute from '../../Router/ProtectedModuleRouter';
import ListProductType from './List';
import AddProductType from './Add';
import EditProductType from './Edit';

class ProductTypePage extends Component {
    render() {
        return (
            <Switch>
                <ProtectedModuleRoute exact path="/admin/producttypes" component={ListProductType} />
                <ProtectedModuleRoute exact path="/admin/producttypes/add" component={AddProductType} />
                <ProtectedModuleRoute exact path="/admin/producttypes/edit/:id" component={EditProductType} />
            </Switch>
        )
    }
};

export default ProductTypePage;