import React from "react";
import { Route } from "react-router-dom";
import { AuthConsumer } from "../../providers/authProvider";

const PrivateRoute = ({ component, ...rest }) => {
    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect }) => {
                if (!!Component && isAuthenticated()) {
                    return <Component {...props} />;
                } else {
                    signinRedirect();
                    return <span>loading</span>;
                }
            }}
        </AuthConsumer>
    );

    return <Route {...rest} render={renderFn(component)} />;
};

export default PrivateRoute;

////import React from 'react';
////import { Route } from 'react-router-dom';
////import AuthUtil from '../../utils/auth-utils';
////import NotAuthorize from './NotAuthorize';

////const AuthenticatedRouter = ({ path, component, exact, ...props }) => {
////    if (AuthUtil.IsAuthenticated()) {
////        return <Route exact={exact} path={path} component={component} {...props} />;
////    } else {
////        return <Route exact={exact} path={path} component={NotAuthorize} {...props} />;
////    }
////};

////AuthenticatedRouter.defaultProps = {
////    exact: false
////};

////export default AuthenticatedRouter;