import React from "react";
import { AuthConsumer } from "../../providers/authProvider";

export const LogoutCallback = () => (
    <AuthConsumer>
        {({ signoutRedirectCallback }) => {
            signoutRedirectCallback();
            return (
                <div className="has-loader-active has-loader min-height">
                    <div className="h-loader-wrapper">
                        <div className="loader is-large is-loading"></div>
                    </div>
                </div>);
        }}
    </AuthConsumer>
);