import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SubscriptionService from '../../../services/subscription/subscriptionService';
import RTNotyf from "../../../utils/notyf-utils";

class EditSubscription extends Component {
    constructor() {
        super();
        this.state = {
            id: '',
            account: '',
            accountErrorMessage: '',
            subscription: null,
            isLoading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeAccount = this.handleChangeAccount.bind(this);
    }

    componentDidMount() {
        this.setState({
            id: this.props.subscription.id,
            account: this.props.subscription.account,
            subscription: this.props.subscription
        })
    }

    handleChangeAccount(ev) {
        this.setState({ account: ev.currentTarget.value, accountErrorMessage: '' });
    }

    handleSubmit(ev) {
        ev.preventDefault();
        this.setState({ isLoading: true });
        SubscriptionService.UpdateAccount(this.state.id, this.state.account, this.state.isActive).then(res => {
            this.setState({ isLoading: false });
            this.props.handleClose();
            this.props.handleRefreshData();
            RTNotyf.NotifySuccess("Perubahan Akun berhasil. Dalam waktu 15 menit akan kami lakukan Aktivasi dan akan kami informasikan melalui Email Anda.");
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        const { id, subscription } = this.state;
        if (!id)
            return (<></>);
        return (
            <div className="modal h-modal is-small is-active">
                <div className="modal-background h-modal-close"></div>
                <div className="modal-content">
                    <div className="modal-card">
                        <form className="modal-form" onSubmit={this.handleSubmit}>
                            <header className="modal-card-head">
                                <h3>{!subscription.account ? "Aktivasi" : "Perbaharui"} Akun</h3>
                                <button className="h-modal-close ml-auto" aria-label="close">
                                    <i data-feather="x"></i>
                                </button>
                            </header>
                            <div className="modal-card-body">
                                <div className="inner-content">
                                    <div className="field">
                                        <label>{subscription.platform === 20 ? "Username Trading View" : "Nomor Akun Metatrader" + (subscription.isMustAccountHotForex ? "Hotforex" : "") }</label>
                                        <div className="control">
                                            <div className="field has-addons">
                                                <div className="control is-expanded">
                                                    <input className="input" type="text" value={this.state.account} onChange={this.handleChangeAccount} />
                                                </div>
                                                <div className="control">
                                                    <a className="button is-static">@</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-card-foot is-end">
                                <button className="button h-button is-rounded h-modal-close" onClick={this.props.handleClose}>Cancel</button>
                                <button type={"submit"} className={"button h-button is-primary is-raised is-rounded " + (this.state.isLoading ? "is-loading" : "")}>Simpan</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EditSubscription);