import React, { Component } from 'react';
import { withRouter } from 'react-router';
import InlineErrorMessage from '../../../../components/InlineErrorMessage';
import { User } from 'react-feather';
import ProductService from '../../../../services/products/productService';

class AddProductType extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            nameErrorMessage: '',
            isLoading: false,
            pipelineId: '',
            pipelineIdErrorMessage: '',
            pipelines: []
        };

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangePipeline = this.handleChangePipeline.bind(this);
        this.syncPipelines = this.syncPipelines.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount() {
        this.syncPipelines();
    }

    syncPipelines() {
        ProductService.GetPipelines().then(res => {
            this.setState({ pipelines: res.data.result });
        });
    }

    handleChangeName(ev) {
        this.setState({ name: ev.currentTarget.value, nameErrorMessage: '' });
    }

    handleChangePipeline(ev) {
        this.setState({ pipelineId: ev.currentTarget.value, pipelineIdErrorMessage: '' });
    }

    handleSubmit(ev) {
        ev.preventDefault();
        var isError = false;
        if (!this.state.name)
            this.setState({ nameErrorMessage: 'Nama Tipe Produk harus diisi.' })

        if (!isError) {
            this.setState({ isLoading: true });
            ProductService.AddProductType(this.state.name, this.state.pipelineId).then(res => {
                this.props.history.push('/admin/producttypes')
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        }
    }

    render() {
        return (
            <div className="form-layout is-separate" style={{ maxWidth: '600px' }}>
                <div className="form-outer">
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-body">
                            <div className="form-section">
                                <div className="form-section-inner pb-8">
                                    <h3 className="has-text-centered">Tambah Tipe Produk</h3>
                                    <div className="columns is-multiline">
                                        <div className="column is-12">
                                            <div className="field">
                                                <label>Nama tipe produk</label>
                                                <div className="control has-icon">
                                                    <input type="text" className="input" placeholder="" onChange={this.handleChangeName} />
                                                    <div className="form-icon">
                                                        <User />
                                                    </div>
                                                    <InlineErrorMessage errorMessages={this.state.nameErrorMessage} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="column is-12">
                                            <div className="field">
                                                <label>Pipeline UMAI</label>
                                                <div className="control has-icon">
                                                    <div className="select is-fullwidth">
                                                        <select onChange={this.handleChangePipeline}>
                                                            <option disabled selected value>Pilih pipeline UMAI</option>
                                                            {
                                                                this.state.pipelines.length > 0 ?
                                                                    this.state.pipelines.map(pipeline =>
                                                                        <option value={pipeline.id} key={pipeline.id}>{pipeline.name}</option>
                                                                    )
                                                                    :
                                                                    <option value='0'>No Data</option>
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="form-section-outer mt-5">
                                    <div className="button-wrap">
                                        <button type="submit" className={"button h-button is-primary is-bold is-raised is-fullwidth " + (this.state.isLoading ? 'is-loading' : '')}>Buat Tipe Produk Baru</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default withRouter(AddProductType);