import React, { Component } from 'react';
import { Router } from './scenes/Router';
import { Router as ReactRouter } from "react-router-dom";
import Layout from './components/Layout';
import ImageFunctions from './utils/functions/image-functions';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import AppConst from './lib/appconst';
import { AuthProvider } from './providers/authProvider';
import './custom.css';

export default class App extends Component {
    static displayName = App.name;

    componentDidMount() {
        ImageFunctions.Initialize();
    }

    render() {
        const history = createBrowserHistory();
        if (AppConst.GoogleAnalyticTrackingCode) {
            ReactGA.initialize(AppConst.GoogleAnalyticTrackingCode);
            history.listen(location => {
                ReactGA.set({ page: location.pathname });
                ReactGA.pageview(location.pathname);
            });
        }

        const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
        return (
            <AuthProvider>
                <ReactRouter basename={baseUrl} history={history}>
                    <Router Layout={Layout} />
                </ReactRouter>
            </AuthProvider>
        );
    }
}
