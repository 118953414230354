import { OIDC_AUTH_URL,  OIDC_CLIENT_ID, OIDC_REDIRECT_URL, OIDC_SILENT_REDIRECT_URL, OIDC_LOGOFF_REDIRECT_URL} from '../configuration';

export const IDENTITY_CONFIG = {
    authority: OIDC_AUTH_URL, //(string): The URL of the OIDC provider.
    client_id: OIDC_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
    redirect_uri: OIDC_REDIRECT_URL, //The URI of your client application to receive a response from the OIDC provider.
    login: OIDC_AUTH_URL + "/login",
    automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    silent_redirect_uri: OIDC_SILENT_REDIRECT_URL, //(string): The URL for the page containing the code handling the silent renew.
    post_logout_redirect_uri: OIDC_LOGOFF_REDIRECT_URL, // (string): The OIDC post-logout redirect URI.
    audience: "", //is there a way to specific the audience when making the jwt
    response_type: "code", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
    grantType: "code",
    scope: "openid profile offline_access store-api", //(string, default: 'openid'): The scope being requested from the OIDC provider.
    //webAuthResponseType: "id_token token"
};

export const METADATA_OIDC = {
    issuer: OIDC_AUTH_URL,
    jwks_uri: OIDC_AUTH_URL + "/.well-known/openid-configuration/jwks",
    authorization_endpoint: OIDC_AUTH_URL + "/connect/authorize",
    token_endpoint: OIDC_AUTH_URL + "/connect/token",
    userinfo_endpoint: OIDC_AUTH_URL + "/connect/userinfo",
    end_session_endpoint: OIDC_AUTH_URL + "/connect/endsession",
    check_session_iframe: OIDC_AUTH_URL + "/connect/checksession",
    revocation_endpoint: OIDC_AUTH_URL + "/connect/revocation",
    introspection_endpoint: OIDC_AUTH_URL + "/connect/introspect",
    frontchannel_logout_supported: true,
    frontchannel_logout_session_supported: true,
    backchannel_logout_supported: true,
    backchannel_logout_session_supported: true
};