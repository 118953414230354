import http from "../httpService";
import AppConsts from '../../lib/appconst';

class AuthService {
    Login(userNameOrEmailAddress, password) {
        return http.post("auth/token", { userNameOrEmailAddress, password }, { customErrorHandler: true });
    }

    CheckAvailableEmail(emailAddress) {
        return http.post(AppConsts.RuangTrader_MAINAPIURL + "/Accounts/checkavailableemail", { emailAddress });
    }

    async RefreshToken(accessToken, refreshToken) {
        return await http.post("auth/refreshtoken", { accessToken, refreshToken });
    }

    ChangePassword(currentPassword, newPassword, confirmPassword) {
        return http.post("Accounts/ChangePassword", { currentPassword: currentPassword, newPassword: newPassword, confirmPassword: confirmPassword }, { customErrorHandler: true });
    }

    SubscribeTelegramBot(userName) {
        return http.put("Accounts/SubscribeTelegramBot", { telegramUserName: userName });
    }

    GetUserByToken() {
        let result = http.get("auth/me");
        return result;
    }

    Register(firstName, surname, email, phoneNumber, street, city, password, confirmPassword) {
        var data = {
            firstName: firstName,
            surname: surname,
            email: email,
            phoneNumber: phoneNumber,
            street: street,
            city: city,
            password: password,
            confirmPassword: confirmPassword
        };
        let result = http.post(AppConsts.RuangTrader_MAINAPIURL + '/accounts/Register', data, { customErrorHandler: true });
        return result;
    }

    RequestEmailConfirmation(emailAddress) {
        return http.post("Accounts/RequestEmailConfirmationEmail", { emailAddress }, { customErrorHandler: true });
    }

    ConfirmEmail(userId, createdByAdmin, token) {
        let result = http.get("Accounts/ConfirmEmail?userId=" + userId + "&createdByAdmin=" + createdByAdmin + "&token=" + token, { customErrorHandler: true });
        return result;
    }

    RequestResetPassword(emailAddress) {
        return http.post("Accounts/RequestResetPassword", { emailAddress }, { customErrorHandler: true });
    }

    ResetPassword(userId, token, password, confirmPassword) {
        return http.post("Accounts/ResetPassword", { userId, token, password, confirmPassword }, { customErrorHandler: true });
    }
}

export default new AuthService();