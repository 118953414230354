import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReportService from '../../../../services/reports/reportService';

class DownloadReport extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            status: 0,
            statusName: '',
            yearMonth: ''
        };
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
        this.handleChangeYearMonth = this.handleChangeYearMonth.bind(this);
        this.handleDownloadReport = this.handleDownloadReport.bind(this);
    }

    componentDidMount() {
        var statusName = 'Belum Bayar';
        switch (this.props.status) {
            case 10: statusName = "Belum Bayar"; break;
            case 20: statusName = "Belum Lunas"; break;
            case 30: statusName = "Lunas"; break;
            case 40: statusName = "Batal"; break;
            case 0: statusName = "All"; break;
        }

        var today = new Date();
        var yearMonth = today.getFullYear() + "" + ('0' + (today.getMonth() + 1)).slice(-2);
        this.setState({
            status: this.props.status,
            statusName: statusName,
            yearMonth: yearMonth,
        })
    }

    handleChangeStatus(ev) {
        this.setState({ status: ev.currentTarget.value });
    }

    handleChangeYearMonth(ev) {
        this.setState({ yearMonth: ev.currentTarget.value });
    }

    handleDownloadReport(ev) {
        ev.preventDefault();
        this.setState({ isLoading: true });
        ReportService.DownloadExcelOrders(this.state.status, this.state.yearMonth).then(res => {
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', 'Report ' + this.state.yearMonth + '.xlsx'); //any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    render() {
        return (
            <div class="modal h-modal is-small is-active">
                <div class="modal-background h-modal-close"></div>
                <div class="modal-content">
                    <div class="modal-card">
                        <form class="modal-form" onSubmit={this.handleDownloadReport}>
                            <header class="modal-card-head">
                                <h3>Download Report</h3>
                                <button class="h-modal-close ml-auto" aria-label="close">
                                    <i data-feather="x"></i>
                                </button>
                            </header>
                            <div class="modal-card-body">
                                <div class="inner-content">
                                    <div class="field">
                                        <label>Status</label>
                                        <div class="control">
                                            <div class="field has-addons">
                                                <div class="control is-expanded">
                                                    <input class="input" type="text" value={this.state.statusName} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label>Year Month (yyyymm)</label>
                                        <div class="control">
                                            <div class="field has-addons">
                                                <div class="control is-expanded">
                                                    <input className="input" type="text" defaultValue={this.state.yearMonth} onChange={this.handleChangeYearMonth} />
                                                </div>
                                                <div class="control">
                                                    <a class="button is-static">yyyymm</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-card-foot is-end">
                                <button className="button h-button is-rounded h-modal-close" onClick={this.props.handleClose}>Cancel</button>
                                <button type={"submit"} className={"button h-button is-primary is-raised is-rounded " + (this.state.isLoading ? "is-loading" : "")}>Download</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(DownloadReport);